import { all, call } from "redux-saga/effects";

import { investmentSagas } from "./user/investment/sagas";
import { dashboardSagas } from "./user/dashboard/sagas";
import { projectSagas } from "./project/sagas";
import { adequacySagas } from "./user/adequacy/sagas";
import { accountSagas } from "./user/account/sagas";
import { adminSagas } from "./admin/sagas";
import { messageSagas } from "./user/message/sagas";
import { appSagas } from "./app/sagas";
import { issuerManagerSagas } from "./issuer-manager/sagas";

export default function* rootSaga() {
    yield all([
        call(investmentSagas),
        call(dashboardSagas),
        call(projectSagas),
        call(adequacySagas),
        call(accountSagas),
        call(adminSagas),
        call(messageSagas),
        call(appSagas),
        call(issuerManagerSagas),
    ]);
}
