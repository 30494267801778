import { useContext, useCallback, useEffect, useMemo, useRef } from "react";

import {
    CurrentInvestmentRefContext,
    BibleFormRefContext,
    IntlContext,
    PartnerProgramFormRefContext,
    ClubdealFormRefContext,
    RequestInformationFormRefContext,
    ContactWebsiteRefContext,
    BrokerProvisionCustomerTableRefContext,
    CampaignFormRefContext,
    UtilContext,
} from "contexts";
import { getGenders } from "utils";
import { formatMobileNumber, ACCOUNT_PROFILE_KYC_SHOW, investment1to8MLimit } from "constants/index";
import { extractUserAdditionalData, extractUserMobilePhone } from "store/helpers";
import { useTranslation } from "react-i18next";

export function useCurrentInvestmentRef() {
    return useContext(CurrentInvestmentRefContext);
}

export function useScrollToCurrentInvestment(cb) {
    const currentInvestmentRef = useCurrentInvestmentRef();

    return useCallback(() => {
        if (typeof cb === "function") cb();
        if (currentInvestmentRef.current) {
            currentInvestmentRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, []);
}

export function useBibleFormRef() {
    return useContext(BibleFormRefContext);
}

export function useScrollToBibleForm(cb) {
    const bibleFormRef = useBibleFormRef();

    return useCallback(() => {
        if (typeof cb === "function") cb();
        if (bibleFormRef.current) {
            bibleFormRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, []);
}

export function usePartnerProgramFormRef() {
    return useContext(PartnerProgramFormRefContext);
}

export function useScrollToPartnerProgramForm(cb) {
    const PartnerProgramFormRef = usePartnerProgramFormRef();

    return useCallback(() => {
        if (typeof cb === "function") cb();
        if (PartnerProgramFormRef.current) {
            PartnerProgramFormRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, []);
}

export function useClubdealFormRef() {
    return useContext(ClubdealFormRefContext);
}

export function useScrollToClubdealForm(cb) {
    const ClubdealFormRef = useClubdealFormRef();

    return useCallback(() => {
        if (typeof cb === "function") cb();
        if (ClubdealFormRef.current) {
            ClubdealFormRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, []);
}

export function useCampaignRef() {
    return useContext(CampaignFormRefContext);
}

export function useScrollToCampaignForm(cb) {
    const CampaignFormRef = useCampaignRef();
    return useCallback(() => {
        if (typeof cb === "function") cb();
        if (CampaignFormRef.current) {
            CampaignFormRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, []);
}

export function useRequestInformationFormRef() {
    return useContext(RequestInformationFormRefContext);
}

export function useScrollToRequestInformationForm(cb) {
    const RequestInformationFormRef = useRequestInformationFormRef();

    return useCallback(() => {
        if (typeof cb === "function") cb();
        if (RequestInformationFormRef.current) {
            RequestInformationFormRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, []);
}

export function useBrokerProvisionCustomerTableRef() {
    return useContext(BrokerProvisionCustomerTableRefContext);
}

export function useScrollToBrokerProvisionCustomerTable(cb) {
    const BrokerProvisionCustomerTableRef = useBrokerProvisionCustomerTableRef();

    return useCallback(() => {
        if (typeof cb === "function") cb();
        if (BrokerProvisionCustomerTableRef.current) {
            BrokerProvisionCustomerTableRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, []);
}

export function useContactWebsiteRef() {
    return useContext(ContactWebsiteRefContext);
}

export function useScrollToContactWebsite(cb) {
    const ContactWebsiteRef = useContactWebsiteRef();

    return useCallback(() => {
        if (typeof cb === "function") cb();
        if (ContactWebsiteRef.current) {
            ContactWebsiteRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, []);
}

export function useScrollToTop(shouldScrollToTopOnMount) {
    return useEffect(() => {
        if (shouldScrollToTopOnMount) window.scrollTo(0, 0);
    }, []);
}

export function useSideModalScrollToTop() {
    return useEffect(() => {
        const spaceClass = window.outerWidth < 992 ? "ml-8" : "ml-6";

        jQuery(".side-modal").scrollTop(0);

        if (jQuery(".side-modal.opened .prev").length > 0) {
            jQuery(".side-modal.opened .modal-title").addClass(spaceClass);
        } else {
            jQuery(".side-modal.opened .modal-title").removeClass(spaceClass);
        }
    }, []);
}

export function useDocumentTitle(title) {
    return useEffect(() => {
        if (title !== undefined) document.title = title;
    });
}

// the format message hook
export const useIntl = () => useContext(IntlContext);

export const useUtils = () => useContext(UtilContext);

export const useFormattedMessage = () => {
    const { t } = useTranslation();
    const currentTenant = useUtils().tenant;
    const formatMessage = useCallback((id, rest) => {
        return t(id, {
            tenant: currentTenant["Name"],
            host: currentTenant.host,
            tenant_smtp_user: currentTenant?.SenderEmail,
            tenant_legal_name: currentTenant?.LegalName,
            ...rest,
        });
    }, []);

    return { formatMessage };
};

// detect click outside component
export function useOutsideClick(ref, actionToPerform, params = []) {
    return useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                params.length ? actionToPerform(...params) : actionToPerform();
            }
        }

        window.addEventListener("mousedown", handleClickOutside);

        return () => window.removeEventListener("mousedown", handleClickOutside);
    }, [ref, params]);
}

export const useExtractUser = (user) => {
    const { formatMessage } = useFormattedMessage();
    return useMemo(() => {
        if (!user) return null;

        const genders = getGenders(formatMessage);
        const kycShow = Boolean(ACCOUNT_PROFILE_KYC_SHOW.find((status) => status === user?.StatusKYC));

        let userPhoneData = extractUserMobilePhone(user?.Phone);
        const verifiedMobileNumber = formatMobileNumber(userPhoneData.mobilePhone);
        const nonVerifiedMobileNumber = formatMobileNumber(userPhoneData.nonVerifiedMobilePhone);
        userPhoneData = { verifiedMobileNumber, nonVerifiedMobileNumber };

        const userAdditionalData = extractUserAdditionalData(user);
        const userData = {
            ...userAdditionalData.identificationData,
            ...userAdditionalData.personalData,
            ...userAdditionalData.tileData,
            TypeGender: genders.find((item) => userAdditionalData?.personalData?.TypeGender === item.value) || {},
        };

        return { userData, userPhoneData, kycShow };
    }, [user]);
};

export const calculationsFor1to8M = (totalAmount, values = {}, maxInvestAmount) => {
    const { AMOUNT_LIMIT_FRIST, AMOUNT_LIMIT_SECOND } = investment1to8MLimit;
    const isMoreThan1k = totalAmount > AMOUNT_LIMIT_FRIST && totalAmount <= AMOUNT_LIMIT_SECOND;
    const isMoreThan10k = totalAmount > AMOUNT_LIMIT_SECOND && totalAmount <= maxInvestAmount;
    const isMoreThan25k = totalAmount > maxInvestAmount;
    const is1KConditionIncomeplete =
        isMoreThan1k && !values?.moreThan100kFreelyDisposable && !values?.notMoreThanDoubleMonthlyIncome;
    const is10kConditionIncomeplete = isMoreThan10k && !values?.notMoreThanDoubleMonthlyIncome;

    return { isMoreThan1k, isMoreThan10k, isMoreThan25k, is1KConditionIncomeplete, is10kConditionIncomeplete };
};

export const useAcceptOrderCalculation = (alreadyInvestedAmount, order, maxInvestAmount) => {
    let canBuy = false;
    const { AvailableShares, SharePrice, IsSplitAllowed } = order;

    if (IsSplitAllowed == "true") {
        const cantPurchaseMinvalue = 500 * parseFloat(SharePrice) + parseFloat(alreadyInvestedAmount) > maxInvestAmount; // FLAG: Can user purchse minimum 500 shares.
        const cantPurchaseIndividually = parseFloat(AvailableShares) < 1000; //FLAG: User can't purchse shares by his choise as available shares are less than 1000.
        const cantPurchaseAll =
            parseFloat(AvailableShares) * parseFloat(SharePrice) + parseFloat(alreadyInvestedAmount) > maxInvestAmount; // FLAG: User cant purchse all shares if he has

        if (cantPurchaseMinvalue) {
            return false;
        }
        if (cantPurchaseIndividually && cantPurchaseAll) {
            return false;
        } else if (!cantPurchaseIndividually) {
            return true;
        }
        canBuy = parseFloat(AvailableShares) * parseFloat(SharePrice) + parseFloat(alreadyInvestedAmount) <= maxInvestAmount;
        return canBuy;
    } else {
        canBuy = parseFloat(AvailableShares) * parseFloat(SharePrice) + parseFloat(alreadyInvestedAmount) <= maxInvestAmount;
        return canBuy;
    }
};

//detect scroll on side modals
let canCheckScrollValue = true;
export function useSideModalScrollListener(props, isApp) {
    return useEffect(() => {
        if (isApp) {
            const allDiv = document.querySelectorAll(".side-modal.opened");
            allDiv?.forEach((item) => {
                if (item && item.getAttribute("listener") !== "true") {
                    item.setAttribute("listener", "true");
                    item.addEventListener("scroll", handleScroll);
                    return () => item.removeEventListener("scroll", handleScroll);
                }
            });
        }
    }, [isApp, props.open]);
}

const handleScroll = (event) => {
    const scrollToTop = event.target?.scrollTop;
    if (scrollToTop === 0) {
        window?.ReactNativeWebView?.postMessage?.(JSON.stringify({ refreshEnabled: true }));
        canCheckScrollValue = true;
    } else if (canCheckScrollValue && scrollToTop > 10) {
        window?.ReactNativeWebView?.postMessage?.(JSON.stringify({ refreshEnabled: false }));
        canCheckScrollValue = false;
    }
};

export function useDelayedCallback(dependencies, delayTime = 500, callback) {
    const timerRef = useRef(null);

    useEffect(() => {
        if (timerRef.current !== null) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            callback();
        }, delayTime);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [...dependencies, delayTime]);
}
