import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Input from "components/Input";
import SideModal from "components/SideModal";

import { fetchUserDetailsStart } from "store/user/account/actions";
import { selectEmail } from "store/user/account/selectors";
import { selectIsCurrentTenantFinexity, selectLocale, selectTenant } from "store/app/selectors";

import cn from "classnames";
import * as yup from "yup";
import { withFormik } from "formik";
import { compose } from "recompose";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { adminReserveShares, setContactMethod } from "api";
import { numberRegex } from "constants/validationRegex";
import { sendFormError } from "i18n/messages/errors";
import { TENANTS_LIST, reserveSharesError } from "constants/index";
import { formatLocaleNumber } from "utils";
import NewLoader from "./NewLoader";

const messages = {
    sendFormError,
    requiredField: "required_field",
    formDigitalSharesRequired: "minimum_shares",
    investmentSumFormDigitalSharesInvalid: "form_digital_shares_invalid",
    investmentSumMorethanNumberOfShares: "form_digital_shares_grater",
    heading: "more_information",
    byMail: "by_mail",
    byMailText: "send_me_info_about_project",
    byMailButtonText: "information_by_mail",
    byPhone: "by_phone",
    byPhoneText: "clarify_question",
    byPhoneButtonText: "make_appointment_phone",
    digitalSharesTitle: "reserve_digital_shares",
    digitalSharesText: "reserve_digital_shares_for_project",
    digitalSharesPerPrice: "one_digital_share_cost",
    digitalSharesSTK: "no",
    digitalSharesButtonText: "reserve_digital_shares",
    mailContent: "further_background_information",
    telephoneContent: "happy_to_contact_you",
    reserveContent: "recieved_your_reservation",
    thankYou: "thank_you_heading",
    minAmountUndercut: "reserveation_fails",
    notInStepRange: "reservation_doesnot_correlate",
    maxAmountExceeded: "reservation_exceeeds",
    minInvestmentSumPlaceholder1: "invest_from",
    minInvestmentSumPlaceholder2: "invest_digital_shares",
};

export function PropertyInterestSuccess({ type, text = "", title = "", backSlideEffect = "", isTenantFinexity }) {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const image = {
        mail: "/images/mail-success.png",
        telephone: "/images/telephone-success.png",
        reserve: isTenantFinexity ? "/images/reserve-success.png" : "/images/nf-reserve-success.png",
    };

    const content = {
        mail: formatMessage(messages.mailContent),
        telephone: formatMessage(messages.telephoneContent),
        reserve: formatMessage(messages.reserveContent),
    };

    return (
        <div className={`property-interest-success ${backSlideEffect}`}>
            <img src={image[type]} alt="" />
            <h4 className="mt-0">{text.length > 0 ? (title.length > 0 ? title : "") : formatMessage(messages.thankYou)}</h4>
            <p className="body-medium">{text.length > 0 ? text : content[type]}</p>
        </div>
    );
}

const PropertyInterestModal = ({
    projectID,
    open,
    setFieldTouched,
    onCloseButtonClick,
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
    validateForm,
    hideReserveSection,
    fetchUserDetailsStart,
    numberOfShares,
    userEmail,
    isTenantFinexity,
    currentTenant,
    locale,
    resetForm,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const [backSlideEffect, setBackSlideEffect] = useState("");

    const [step, setStep] = useState(1);
    const [reserveSharesLoading, setReserveSharesLoading] = useState(false);
    const [reserveSharesErrorMsg, setReserveSharesErrorMsg] = useState("");

    useEffect(() => {
        resetForm({});
    }, [open]);

    useEffect(() => {
        validateForm();
    }, [values, touched, errors]);

    const submitReserveShare = () => {
        setReserveSharesLoading(true);
        adminReserveShares(userEmail, projectID, values.digitalShares).then((res) => {
            const response = res?.data?.data?.reserveShares;
            if (response) {
                setStep(4);
                setBackSlideEffect("step-slide-left");
            } else {
                let error;
                if (
                    res?.data?.errors?.[0].message === reserveSharesError.LOWER_THAN_500 ||
                    res?.data?.errors?.[0].message === reserveSharesError.LOWER_THAN_25000
                ) {
                    error = "minAmountUndercut";
                } else if (res?.data?.errors?.[0].message === reserveSharesError.MORE_THAN_25000) {
                    error = "maxAmountExceeded";
                } else if (res?.data?.errors?.[0].message === reserveSharesError.INVESTMENT_SUM_STEPPER_RANGE_REQUIREMENT_ERROR) {
                    error = "notInStepRange";
                } else {
                    error = "send_form_error";
                }
                setReserveSharesErrorMsg(error);
            }
            setReserveSharesLoading(false);
        });
    };

    const contactRequestOnClick = (type) => {
        setContactMethod(type, projectID).then(() => {
            setBackSlideEffect("step-slide-left");
            if (type === "mail") {
                setStep(2);
            } else {
                setStep(3);
                // window.open(
                //     "https://app.prosperworks.com/public/meeting-scheduler/FINEXITY%20AG/support/324836:edfe23a8-6537-4f50-9c07-4f881ff8dc86",
                // );
            }
        });
    };

    const interestTypes = (
        <div className={`property-interest-container ${backSlideEffect}`}>
            {!hideReserveSection && (
                <div className="property-interest-block text-center shadow border-radius">
                    <img
                        className="action-image"
                        src={isTenantFinexity ? "/images/interestReserve.png" : "/images/nf-interestReserve.png"}
                        alt=""
                    />
                    <h4 className="mt-0">{formatMessage(messages.digitalSharesTitle)}</h4>
                    <p className="body-medium mb-0">{formatMessage(messages.digitalSharesText)}</p>
                    <p>{formatMessage(messages.digitalSharesPerPrice)}</p>
                    <form method="post" className="" onSubmit={handleSubmit}>
                        <div className="form-field">
                            <Input
                                inputClassName="text-input form-item"
                                type="number"
                                name="digitalShares"
                                placeholder={`${formatMessage(messages.minInvestmentSumPlaceholder1)}${
                                    currentTenant === TENANTS_LIST.GOLDENCIRCLE ? formatLocaleNumber(locale, 5000, 0) : 500
                                }${formatMessage(messages.minInvestmentSumPlaceholder2)}`}
                                inputSymbol={formatMessage(messages.digitalSharesSTK)}
                                value={values.digitalShares}
                                onChange={(e) => {
                                    setFieldTouched("digitalShares", true);
                                    handleChange(e);
                                    setReserveSharesErrorMsg("");
                                }}
                                onBlur={handleBlur}
                                errorMessage={
                                    errors.digitalShares
                                        ? `${formatMessage(messages[errors.digitalShares])} ${
                                              errors.digitalShares === "investmentSumMorethanNumberOfShares" ? numberOfShares : ""
                                          }`
                                        : reserveSharesErrorMsg
                                        ? formatMessage(messages[reserveSharesErrorMsg])
                                        : null
                                }
                                error={(touched.digitalShares && errors.digitalShares) || reserveSharesErrorMsg}
                            />
                        </div>
                        <button
                            disabled={reserveSharesLoading}
                            className={cn({
                                "mb-0 mw-100": true,
                                "disable-grey": Object.keys(errors).length || reserveSharesLoading,
                            })}
                            type="button"
                            onClick={submitReserveShare}
                        >
                            {reserveSharesLoading ? (
                                <>
                                    <NewLoader /> {formatMessage(messages.digitalSharesButtonText)}
                                </>
                            ) : (
                                formatMessage(messages.digitalSharesButtonText)
                            )}
                        </button>
                    </form>
                </div>
            )}
            {isTenantFinexity && (
                <>
                    <div className="property-interest-block text-center shadow border-radius">
                        <img
                            className="action-image"
                            src={isTenantFinexity ? "/images/interestMail.png" : "/images/nf-interestMail.png"}
                            alt=""
                        />
                        <h4 className="mt-0">{formatMessage(messages.byMail)}</h4>
                        <p className="body-medium">{formatMessage(messages.byMailText)}</p>
                        <button className="mb-0" onClick={() => contactRequestOnClick("mail")}>
                            {formatMessage(messages.byMailButtonText)}
                        </button>
                    </div>
                </>
            )}
        </div>
    );

    function renderPropertyInterestStep(step) {
        switch (step) {
            case 1:
                return interestTypes;
            case 2:
                return <PropertyInterestSuccess type="mail" isTenantFinexity={isTenantFinexity} />;
            case 3:
                return <PropertyInterestSuccess type="telephone" isTenantFinexity={isTenantFinexity} />;
            case 4:
                return <PropertyInterestSuccess type="reserve" isTenantFinexity={isTenantFinexity} />;
            default:
                return interestTypes;
        }
    }

    const close = () => {
        onCloseButtonClick();
        if (step !== 1) {
            setStep(1);
            setBackSlideEffect("step-slide-right");
        }
    };

    return (
        <SideModal
            className="property-interest-modal"
            heading={formatMessage(messages.heading)}
            open={open}
            onCloseButtonClick={() => {
                fetchUserDetailsStart();
                close();
            }}
        >
            {renderPropertyInterestStep(step)}
        </SideModal>
    );
};

const mapStateToProps = createStructuredSelector({
    userEmail: selectEmail,
    isTenantFinexity: selectIsCurrentTenantFinexity,
    currentTenant: selectTenant,
    locale: selectLocale,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserDetailsStart: () => dispatch(fetchUserDetailsStart()),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: () => {
            return {
                digitalShares: "",
            };
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: (props) =>
            yup.object().shape({
                digitalShares: yup
                    .string()
                    .required("requiredField")
                    .matches(numberRegex, "investmentSumFormDigitalSharesInvalid")
                    .test("digitalShares", "formDigitalSharesRequired", (value) => {
                        return !!(value ? value >= 500 : true);
                    })
                    .test("digitalShares", "investmentSumMorethanNumberOfShares", (value) => {
                        return !!(value && value <= props.numberOfShares);
                    })
                    .nullable(true),
            }),
        handleSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            // return reserveShares(values.digitalShares, props.projectID)
            // props.onSubmit && props.onSubmit(values);
        },
        displayName: "PropertyInterestShareForm",
    })
)(PropertyInterestModal);
