import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import cn from "classnames";

import { deleteDocument } from "api";

import { truncateString, saveFile } from "utils";

import { selectPlatformSourceFromApp } from "store/app/selectors";

import NewLoader from "./NewLoader";

const RenderIcon = ({ isLoading, isSuccess, isDeleteIcon = false }) => {
    return isLoading ? (
        <NewLoader type="primary" />
    ) : isSuccess ? (
        <i className={`fas fa-${isDeleteIcon ? "trash" : "download"}`} />
    ) : (
        <i className="fas fa-redo" />
    );
};

const DownloadButton = ({
    className = "",
    title,
    description,
    fileURL,
    fileName,
    fileIcon,
    type = "application/octet-stream",
    isApp,
    isToken = false,
    onDownloadSuccess,
    onDownloadFailed,
    onCustomDownloadCall,
    isDeletable = false,
    documentID,
    onDeleteSuccess,
    onDeleteFailed,
    onCustomDeleteCall,
    showTextLength = 34,
    descriptionClassName = "text-14",
    icon,
    onDelete,
    isFileOpen = false,
}) => {
    const [isDownloading, setDownloading] = useState(false);
    const [isDownloadSuccess, setDownloadSuccess] = useState(true);
    const [isDeleting, setDeleting] = useState(false);
    const [isDeleteSuccess, setDeleteSuccess] = useState(true);

    const onDownloadClick = (_fileURL, _fileName, _type, _isToken = false) => {
        if (onCustomDownloadCall) {
            onCustomDownloadCall();
        } else if (isApp) {
            window?.ReactNativeWebView?.postMessage(_fileURL);
        } else {
            setDownloading(true);
            saveFile(_fileURL, _fileName, _type, _isToken, isFileOpen)
                .then(() => {
                    setDownloading(false);
                    setDownloadSuccess(true);
                    onDownloadSuccess && onDownloadSuccess();
                })
                .catch(() => {
                    setDownloading(false);
                    setDownloadSuccess(false);
                    onDownloadFailed && onDownloadFailed();
                });
        }
    };

    const onDeleteClick = async (documentID) => {
        if (onCustomDeleteCall) {
            onCustomDeleteCall();
        } else {
            setDeleting(true);
            deleteDocument({ documentID })
                .then((res) => {
                    const error = res?.data?.errors && res?.data?.errors[0]?.message;
                    if (error) {
                        setDeleting(false);
                        setDeleteSuccess(false);
                        onDeleteFailed && onDeleteFailed(documentID);
                    } else {
                        onDeleteSuccess && onDeleteSuccess(documentID);
                        setDeleting(false);
                        setDeleteSuccess(true);
                    }
                })
                .catch(() => {
                    setDeleting(false);
                    setDeleteSuccess(false);
                    onDeleteFailed && onDeleteFailed(documentID);
                });
        }
    };

    return (
        <div className={`property-document mb-3 ${className}`}>
            <div className="flex justify-between align-center">
                <div className="d-flex">
                    {fileIcon && <div className="file-icon pl-4 d-flex align-center">{fileIcon}</div>}
                    <div className="document-details">
                        <h6 title={title} className="document-title my-0 word-break-auto" style={{ lineHeight: "18px" }}>
                            {truncateString(title, showTextLength)}
                        </h6>
                        {description && (
                            <span className={`document-info fw-500 d-block mb-0 text-left ${descriptionClassName}`}>
                                {description}
                            </span>
                        )}
                    </div>
                </div>
                <div className="d-flex">
                    <div
                        className={cn({
                            "document-icon": true,
                            "pointer-none": isDownloading || isDeleting,
                        })}
                        onClick={() => onDownloadClick(fileURL, fileName, type, isToken)}
                    >
                        <RenderIcon isLoading={isDownloading} isSuccess={isDownloadSuccess} />
                    </div>
                    {((isDeletable && documentID) || onDelete) && (
                        <div
                            className={cn({
                                "document-icon": true,
                                "alert-red-color": true,
                                "pointer-none": isDownloading || isDeleting,
                            })}
                            onClick={() => (onDelete ? onDelete() : onDeleteClick(documentID))}
                        >
                            {icon || <RenderIcon isDeleteIcon isLoading={isDeleting} isSuccess={isDeleteSuccess} />}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps)(DownloadButton);
