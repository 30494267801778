/* eslint-disable no-use-before-define */
import Cookies from "js-cookie";

import api from "api/SingletonApi";
import { nativeUserActivityData } from "constants/apiResults";

import { getAccessToken, getUserEmail } from "utils/index";
import { getDocumentsHost } from "common/helper";

const FINX_COOKIE = require("common/constants");
const DOCUMENT_HOST = getDocumentsHost();

export const downloadHeaders = () => {
    const finexityTenant = Cookies.get(FINX_COOKIE.FINEXITY_TENANT);
    const accessToken = getAccessToken();

    return {
        responseType: "arraybuffer",
        Authorization: `Bearer ${accessToken}`,
        "finexity-tenant": finexityTenant,
    };
};

const DOCUMENT = `
    Author
    Caption
    CreatedDate
    DataType
    Description
    ExpiryDate
    Filename
    Filesize
    Language
    Path
    ResourceID
    Tenant
    Title
    TypeDocument
    EffectiveDate
    StandDate
    uid
`;

const BANK_ACCOUNT = `
    uid
    TypeBankAccount
    StatusBankAccount
    IBAN
    BIC
    Owner
    BankAccountIDMangopay
    BankAccountStatusMangopay
`;

const BANKING_ALIAS_FUNDING_WALLET = `
    BankAccountIDMangopay
    BankAccountStatusMangopay
    BIC
    IBAN
    Owner
    StatusBankAccount
    TypeBankAccount
    uid
`;

const DOCUMENT_DATA = `
    uid
    Path
    Title
    Filename
    CreatedDate
    TypeDocument
`;

const ADDRESS = `
    uid
    TypeAddress
    Street
    Streetnumber
    StreetAddition
    City
    Country
    Zipcode
    Region
`;

const IDENTIFICATION = `
    Identification {
        uid
        Birthplace
        BirthCountry
        Birthname
        Nationality
        MaritalStatus
    }
`;

const KYC_POSTIDENT = `
    Postident {
        uid
        ID
        Email
        Lastname
        Firstname
        StreetAndNo
        Zipcode
        City
        IDDocument
        IDNumber
        DateOfBirth
        PlaceOfBirth
        Nationality
        IssuingAuthority
        IssuingDate
        ProcessingTimestamp
    }
`;

const USER_KYC = `
    KYC {
        uid
        IDnowSnapshot
        ${KYC_POSTIDENT}
        ID
        Gender
        Title
        Firstname
        Lastname
        Street
        StreetNumber
        Zipcode
        City
        Country
        BirthDay
        BirthPlace
        BirthName
        Nationality
        IDDocumentType
        IDDocumentNumber
        IdentificationTime
        IDDocumentIssuingAuthority
        IDDocumentIssuingDate
        IDDocumentExpirationDate
        Transactionnumber
        IdentificationSummaryPDFPath
        IdentificationSummaryPDFFilename
        StatusKYC
        StatusReKYC
        IDnowRedirectURL
        XpectoCustomerDataSent
        XpectoCustomerCleared
        IDnowID
        PostidentID
        IssuingParty
        IssuingCountry
        IDnowTransactionnumber
    }
`;

const ADEQUACY = `
    Adequacy {
        uid
        IsNotWantedConfirmed
        IsNotWantedConfirmedDate
        IsInvestFundsXPConfirmed
        IsInvestFundsXPConfirmedDate
        IsBondsXPConfirmed
        IsBondsXPConfirmedDate
        IsSharesXPConfirmed
        IsSharesXPConfirmedDate
        IsProfitPartXPConfirmed
        IsProfitPartXPConfirmedDate
        IsSubordLoanXpConfirmed
        IsSubordLoanXpConfirmedDate
        BondsXPIndependent
        BondsXPConsulted
        BondsXPAssisted
        BondsTransactions
        BondsAmount
        TypeJob
        TypeEducation
        StatusAdequacy
        DateTime
    }
`;

const USER_DASHBOARD_DATA = `
    StatusAdequacy
    StatusKYC
    StatusTipster
    StatusVIP
    StatusEmail
    StatusMobilePhone
    StatusTwoFactor
    KYC {
        StatusReKYC
    }
`;

const PHONE = `
    uid
    TypePhone
    CountryCode
    DialCode
    Number
    StatusConfirmed
    IsPreferredPrimaryContact
`;

const COMPANY = `
    uid
    LinkAddress {
        ${ADDRESS}
    }
    LinkPhone {
        ${PHONE}
    }
    Website
    LinkBankAccount {
        ${BANK_ACCOUNT}
    }
    TaxID
    CompanyName
    CompanyEmail
    CompanyNumber
    CompanyType
    LegalForm
`;

const PROVISION = `
    uid
    TypeProvisioning
    InitialInvestmentPercentage
    FollowupPercentage
    ProtectionPeriodInYears
`;

const PROVISIONS = `
    LinkProvision {
        ${PROVISION}
    }
`;

const LEGAL_USER = `
    uid
    LegalRepresentativeFirstName
    LegalRepresentativeLastName
    LegalRepresentativeEmail
    TypeLegalPerson
    LegalRepresentativeBirthday
    LegalRepresentativeCountryOfResidence
    LegalRepresentativeNationality
`;

const BROKER = `
    Broker {
        uid
        StatusBrokerAccount
        LinkCompany {
            ${COMPANY}
        }
        LinkAddress {
            ${ADDRESS}
        }
        Website
        LinkBankAccount {
            ${BANK_ACCOUNT}
        }
        LinkPhone {
            ${PHONE}
        }
        StatusBrokerOnboarding
        IsTipsterConfirmed
        IsTipsterConfirmedDate
        BrokerAccountTipsterOwnID
        LinkBrokerDocumentIdentification {
            ${DOCUMENT_DATA}
        }
        LinkBrokerDocumentCooperationAgreement {
            ${DOCUMENT_DATA}
        }
        LinkBrokerDocumentBusinessLicense {
            ${DOCUMENT_DATA}
        }
        LinkBrokerDocumentCommercialRegisterExtract {
            ${DOCUMENT_DATA}
        }
        TaxID
        TypeLicense
        ${PROVISIONS}
        BrokerConfirmationDate
        IsLegalRepresentative
        LegalUser {
            ${LEGAL_USER}
        }
    }
`;

const BLOCKCHAIN_DATA = `
    uid
    WalletID
`;

const BLOCKCHAIN = `
    Blockchain {
        ${BLOCKCHAIN_DATA}
    }
`;

const COOPER = `
    Copper {
        uid
        CopperLeadID
        CopperPersonID
    }
`;

const TIPSTER = `
    Tipster {
        uid
        StatusTipster
        WasReferredByTheTipster
        WasReferredByTheTipsterDate
        UserTipsterOwnID
        LinkProvision {
            uid
            TypeProvisioning
            InitialInvestmentPercentage
            FollowupPercentage
            ProtectionPeriodInYears
        }
    }
`;

const USER_SOCIAL = `
    Social {
        uid
        TypeSocial
        Url
    }
`;

const MANGO_PAY = `
    Mangopay {
        uid
        UserIDMangopay
        RegistrationDateMangopay
        ProofOfIdentity
        ProofOfAddress
        PersonType
        KYCLevel
        KYCDocumentSentDate
        KYCDocumentID
        KYCDocumentFile
        EWallets {
            uid
            PayInWalletID
            PayOutWalletID
            BlockedWalletID
            EscrowWalletID
            FundingWalletID
        }
        BankAccountIDMangopay
        BankAccountStatusMangopay
        LinkUserSelectedBankAccountPayout
        IsMangopayTermsConditionsConfirmed
        IsMangopayTermsConditionsConfirmedDate
        IsBlockedInFlowsMangopay
        IsBlockedOutFlowsMangopay
        BankingAlias {
            ${BANK_ACCOUNT}
        }
        BankingAliasForFundingWallet {
            ${BANKING_ALIAS_FUNDING_WALLET}
        }
        StatusMangopay
    }
`;

const USER_CLUB = `
    Club {
        uid
        TypeJob
        TypeJobOther
        SourceOfWealth
        SourceOfWealthOther
        ScopeOfPortfolio
        ScopeOfCash
        ShareOfAlternativeInvestments
        FutureAmountOfAlternativeInvestments
        InterestInAlternativeInvestments
        InterestInAlternativeInvestmentsOther
        ClubDealConfirmationDate
    }
`;

const PORTFOLIO_DATA = `
    uid
    LinkToken
    LinkProject
    AvailableAmount
    BlockedAmount
    Investments
    Orders
`;

const PROJECT_TYPE = `
    type {
        type
    }
`;

const PROJECT_STATUS = `
    status {
        status
    }
`;

const PROJECT_HIGHLIGHTS = `
    highlights {
        highlight
    }
`;

const ASSET = `
    title
    description
    caption
    credit
    path
    sourceUrl
    filename
    mimeType
`;

const TEASER_IMAGE = `
    teaserImage{
        ${ASSET}
    }
`;

const ASSET_LIST = `
    list {
        ${ASSET}
    }
`;

const SLIDER = `
    slider {
        ${ASSET_LIST}
    }
`;

const PROJECT_IMAGES = `
    images {
        ${TEASER_IMAGE}
        ${SLIDER}
    }
`;

const PROJECT_ADDRESS = `
    address{
        locationHeader
        city
        country
        street
        zipCode
        latitude
        longitude
    }
`;

const PROJECT_SECTIONS = `
    sections {
        privateTab
        sectionTitle
        sectionContent
        subSections {
            sectionTitle
            sectionContent
        }
    }
`;

const DOCUMENTS = `
    documents{
        ${ASSET_LIST}
    }
`;

const PROJECT_DETAILS = `
    details{
        detailsTitle
        ${PROJECT_SECTIONS}
        ${DOCUMENTS}
    }
`;

const PROJECT_VALUATION = `
    valuation {
        date
        assetValue
    }
`;

const PROJECT_COSTS_AND_BENEFITS = `
    costsAndBenefits {
        monetaryBenefitsToBroker
        monetaryBenefitsToInstitute
        oneTimeCostsFi
        oneTimeGrants
        oneTimePlacementFee
        runningCostsFiPa
        runningGrantsPa
        runningPlacementFeePa
        runningTimeInYears
    }
`;

const PROJECT_BANK_ACCOUNT = `
    bankAccount{
        accountOwner
        IBAN
        BIC
        creditInstitution
    }
`;

const PROJECT_FINANCIALS = `
    financials {
        numberOfShares
        totalReturnEstimate
        fiveYearsReturnEstimate
        endOfFunding
        ${PROJECT_VALUATION}
        finalizationDate
        objectType
        fundingNews
        issuerName
        issuerWebsite
        ${PROJECT_COSTS_AND_BENEFITS}
        ${PROJECT_BANK_ACCOUNT}
        perAnnoReturnEstimate
        perAnnoMortgageRepaymentEstimate
        perAnnoPropertyValueGrowthEstimate
        minimumFundingPercentage
        issuerStreet
        issuerCity
        issuerZipcode
        issuerEmail
    }
`;

const PROJECT_NEWS = `
    news {
        newsTitle
        newsDate
        newsContentHtml
    }
`;

const LINE = `
    line {
        left
        right
        explanation
    }
`;

const PROJECT_ACTION_BOX = `
    actionBox {
        ${LINE}
        legalDisclaimer
    }
`;

const PROJECT_INFO_BOX = `
    infoBox {
        infoBoxTitleHtml
        infoBoxContentHtml
        infoBoxUrlHtml
    }
`;

const PROJECT_DATA = `
    live
    title
    projectID
    sorting
    ${PROJECT_TYPE}
    ${PROJECT_STATUS}
    ${PROJECT_HIGHLIGHTS}
    ${PROJECT_IMAGES}
    ${PROJECT_ADDRESS}
    ${PROJECT_DETAILS}
    ${PROJECT_FINANCIALS}
    xid
    fundingPercentage
    ${PROJECT_NEWS}
    ${PROJECT_ACTION_BOX}
    disclaimerHtml
    ${PROJECT_INFO_BOX}
    isSubscribed
    typeInvestmentProduct
`;

const PROJECT_DATA_LIMITED = `
    live
    title
    projectID
    sorting
    ${PROJECT_TYPE}
    ${PROJECT_STATUS}
    ${PROJECT_IMAGES}
    ${PROJECT_ADDRESS}
    ${PROJECT_ACTION_BOX}
    financials {
        numberOfShares
        totalReturnEstimate
        fiveYearsReturnEstimate
    }
    xid
    fundingPercentage
    isSubscribed
    typeInvestmentProduct
`;

const PROJECTS = `
    projects {
        ${PROJECT_DATA}
    }
`;

const CALL_DATA = `
    uid
    StartTime
    EndTime
    Duration
    LinkCallRecordingDocument {
        ${DOCUMENT_DATA}
    }
    LinkUser
    LinkPhoneFromNumber {
        ${PHONE}
    }
    LinkPhoneToNumber {
        ${PHONE}
    }
    LinkSalesRep
    CallSid
    ParentCallSid
    Direction
    StatusCall
`;

const EMAIL_DATA = `
    uid 
    From 
    To 
    Subject 
    CreatedDate 
    Cc
    Bcc
    Preview 
    Title 
    Body 
`;

const SMS_DATA = `
    uid
    Message
    LinkPhoneNumber {
        ${PHONE}
    }
    CreatedDate
`;

const ACTIVITY_DATA = `
    uid
    TypeActivity
    StatusActivity
    ActivityContent
    CreatedDate
    UpdatedDate
    LinkUsers
    LinkCall {
        ${CALL_DATA}
    }
    LinkEmail {
        ${EMAIL_DATA}
    }
    LinkSMS {
        ${SMS_DATA}
    }
`;

const ACTIVITY = `
    activity {
        ${ACTIVITY_DATA}
    }
`;

const PAYMENT_DATA = `
    uid
    ResourceID
    TypePayment
    StatusPayment
    CreatedDate
    UserIDPayment
    LinkBankAccount {
        uid
        TypeBankAccount
        StatusBankAccount
        Owner
        IBAN
        BIC
    }
    Fee
    Creditor
    Beneficiary
    Channel
    Amount
    ReferenceNumber
    ReferenceProjectID
    ReferenceInvestmentID
`;

const PAYMENT = `
    payment {
        ${PAYMENT_DATA}
    }
`;

const PAYMENTS = `
    payments {
        ${PAYMENT_DATA}
    }
`;

const TRANSACTION_DATA = `
    uid
    StatusTransaction
    CreatedDate
    FromUserEmail
    ToUserEmail
    Amount
    TokenAddress
    TransactionID
    LinkToken
    Fullhash
    Recipient
`;

const TRANSACTIONS = `
    transactions {
        ${TRANSACTION_DATA}
    }
`;

const ORDER = `
    uid
    StatusOrder
    CreatedDate
    ExpirationDate
    TypeOrder
    IsSplitAllowed
    ProjectID
    LinkSeller
    LinkBuyer
    Amount
    Shares
    SharePrice
    Fee
    IsXPConfirmed
    IsMissingXPConfirmed
    IsBuyerPersonalInformationConfirmed
    IsSellerPersonalInformationConfirmed
    IsRisksConfirmed
    IsBIBConfirmed
    IsBondTermsConfirmed
    IsBrokerageTermsConfirmed
    IsCostConfirmed
    Document {
        uid
        Path
        Title
        Filename
        CreatedDate
        TypeDocument
    }
    InvestorIP
    ReferenceNumber
    Tx
    IsParentOrder
    ParentOrderID
    SplitOrderID
    IsEmoneyTransferConfirmedDate
    IsTokenTransferConfirmedDate
    IsXpectoOrderDataSent
    IsXpectoOrderDataSentDate
    Payments
    OrderTimeout
    IsPurchaseDate
    AvailableShares
    SharesInProgress
    SoldShares
    UserCountry
    LinkTransaction
    IsTimeoutExtended
    LinkPayment
    IsDeactivationReminderMailSentDate
    IsConsumerInformationConfirmed
    Tenant
    LinkProduct {
        ProductID
    }
`;

const TOKEN_DATA = `
    uid
    Name
    Symbol
    ContractAddress
    CreatedDate
    ContractAddress
    Decimals
    InitialSupply
    LinkOwner
    LinkIssuer
    ContractDeployedDate
    LinkProject`;

const PERFORMANCE_DATA = `
    uid
    LinkProject
    LinkToken
    LiquidityReserves
    AssetValue
    TokenPrice
    Percentage
    ValuationDate
    CreatedDate
    TypePerformance
`;

const PRODUCT_ADDRESS = `
    uid
    City
    Country
    Street
    StreetAddition
    Streetnumber
    TypeAddress
    Zipcode
`;

const TENANT_DATA = `
    uid
    URLImprint
    URLTransparency
    LegalName
    SMTPUser
    SMTPPort
    Name
    SMTPPassword
    SMTPServer
    Domain
    TechnicalName
    URLDataPrivacy
    URLSCCGDPR_EN
    URLSCCGDPR_DE
    MarketplaceTermsAndConditions {
        ${DOCUMENT}
    }
    ConsentManagerHead
    ConsentManagerBody
    HowItWorkTileContent{
        DescriptionDE
        DescriptionEN
        ReadMoreEnable
        ReadMoreURL
        TitleDE
        TitleEN
        uid
    }
    RootDomainURLEnabled
    MailTemplate{
        ${DOCUMENT}
    }
    LinkProductCatalog {
        uid
        ProductCatalogName
        StatusProductCatalog
    }
    SenderEmail
    BCCs
    XpectoAffiliate
    OGTitle_EN
    OGDescription_EN
    OGTitle_DE
    OGDescription_DE
    OGImage {
        ${DOCUMENT}
    }
    FCMAuthKey
    AppConfig {
        AndroidVersion
        ForceUpdate
        IOSVersion
        MaintenanceEnabled
        Maintenances {
            Description
            Title
            TypeLanguage
            uid
        }
        NotificationInterval
        uid
        VersionAlerts {
            Deutsch
            DeviceType
            English
            uid
        }
    }
    WPALink
    CodeOfConduct_DE
    CodeOfConduct_EN
    CodeOfConductUrl
`;

// const ISSUERS_DATA = `
//     uid
//     IssuerEmail
//     Name
// `;

const CALL_TO_ACTION_DATA = `uid
        typeMessage
        title
        preview
        body
        sendDate
        createdDate
        readDates
        documents {
            ${DOCUMENT_DATA}
        }`;

const GET_INBOX_DATA = `uid
    typeMessage
    title
    tenant
    subject
    sender
    sendDate
    readDate
    preview
    documents {
        ${DOCUMENT_DATA}
    }
    body
    createdDate
`;

const LINK_TOKEN = `
    ContractAddress
    ContractDeployedDate
    CreatedDate
    Decimals
    InitialSupply
    LinkIssuer
    LinkOwner
    LinkProject
    Name
    Symbol
    uid
`;

const PRODUCT_CONTENT = `
    ActionBox {
        ${LINE}
        legalDisclaimer
    }
    Details {
        detailsTitle
        ${PROJECT_SECTIONS}
        ${DOCUMENTS}
    }
    DisclaimerHtml
    Highlights{
        highlight
    }
    InfoBox{
        infoBoxTitleHtml
        infoBoxContentHtml
        infoBoxUrlHtml
    }
    Slider {${ASSET_LIST}}
    TeaserImage {${ASSET}}
`;
const ISSUER = `
    uid
    LegalName
    MainTenant
    IssuerEmail
    CreatedDate
    StatusIssuerAccount
    IssuerConfirmationDate
    ApprovalNumberBZST
    ApprovalIdentifierFSAK
    IssuerKey
    IssuerSlug
    CompanyGoal
    Description
    FooterNote
    IssuerLogo {${DOCUMENT}}
    Background {${DOCUMENT}}
    Document {${DOCUMENT}}
    LinkCompany {${COMPANY}}
    MetaDescription
    MetaTitle
    Managers {
        uid
        FirstName
        LastName
        Email
        Roles
    }
    LinkProducts {
        uid
        ProductID
        Title
        StatusProduct
        Document {${DOCUMENT}}
    }
    LinkLegalRepresentatives {
        LegalRepresentativeAddress {
            ${ADDRESS}
        }
        LegalRepresentativeBirthday
        LegalRepresentativeEmail
        LegalRepresentativeCountryOfResidence
        LegalRepresentativeFirstName
        LegalRepresentativeLastName
        LegalRepresentativeNationality
        TypeLegalPerson
        uid
    }
    VirtualIssuer
`;

const PRODUCT_WITHOUT_CONTENT = `
    uid
    Title
    ProductID
    CreatedDate
    CategoryProduct
    FinancingAmount
    IsLive
    Issuer {${ISSUER}}
    Document {${DOCUMENT}}
    LinkToken {${LINK_TOKEN}}
    LinkAddress {${PRODUCT_ADDRESS}}
    ProductContent { DisclaimerHtml Highlights { highlight } TeaserImage {title description caption credit path sourceUrl filename mimeType xid} Slider {list {title description caption credit path sourceUrl filename mimeType xid}} ActionBox {line {left right explanation} legalDisclaimer} InfoBox {infoBoxContentHtml infoBoxTitleHtml infoBoxUrlHtml} Details { detailsTitle sections { privateTab sectionTitle sectionContent subSections { sectionTitle sectionContent}} documents {list {title description caption credit path sourceUrl filename mimeType xid}} contractDocument {title description caption credit path sourceUrl filename mimeType xid}}}
    MinimumFundingPercentage
    MonetaryBenefitsToBroker
    MonetaryBenefitsToInstitute
    NumberOfShares
    OGDescription
    OGImagePath
    OGTitle
    OGURL
    OneTimeCostsFi
    OneTimeGrants
    OneTimePlacementFee
    ProductKey
    RunningCostsFiPa
    RunningGrantsPa
    RunningPlacementFeePa
    RunningTimeInYears
    Slug
    Sorting
    StatusProduct
    StatusTestInvestmentCompliance
    TenantIDs
    TestInvestmentSentDate
    TitleProductTile
    TotalReturnEstimated
    TypeProduct
    Marketplaces
    IsAvailableForEwalletFunding
`;

const PRODUCT = `
    uid
    Title
    ProductID
    CreatedDate
    CategoryProduct
    FinancingAmount
    FundingPercentage
    IsLive
    Issuer {${ISSUER}}
    Document {${DOCUMENT}}
    LinkToken {${LINK_TOKEN}}
    LinkAddress {${PRODUCT_ADDRESS}}
    ProductContent {${PRODUCT_CONTENT}}
    MinimumFundingPercentage
    MonetaryBenefitsToBroker
    MonetaryBenefitsToInstitute
    NumberOfShares
    OGDescription
    OGImagePath
    OGTitle
    OGURL
    OneTimeCostsFi
    OneTimeGrants
    OneTimePlacementFee
    ProductKey
    RunningCostsFiPa
    RunningGrantsPa
    RunningPlacementFeePa
    RunningTimeInYears
    Slug
    Sorting
    StatusProduct
    StatusTestInvestmentCompliance
    TenantIDs
    TestInvestmentSentDate
    TitleProductTile
    TotalReturnEstimated
    TypeProduct
    Marketplaces
    AutomaticIssuerClearanceUpToPercent
    Roles
    Users
    ProductPayOut {
        uid
        Type
        TimestampCsvFileTaxIdAndKiStamRequest
        TimestampCsvFileKiStamRequest
        State
        EnteredPayoutAmount
        DatePayoutCompleted
        CalculatedPayoutAmount
        CreatedDate
        RequestBy
    }
    IsAvailableForEwalletFunding
`;

const PRODUCT_WITH_TAKESHAPE = `
    ${PRODUCT}
`;

const ISSUER_WITHOUT_PRODUCT_CONTENT = `
    LinkProducts {${PRODUCT_WITHOUT_CONTENT}}
    uid
    LegalName
    MainTenant
    IssuerEmail
    CreatedDate
    StatusIssuerAccount
    IssuerConfirmationDate
    ApprovalNumberBZST
    ApprovalIdentifierFSAK
    IssuerKey
    IssuerSlug
    CompanyGoal
    Description
    FooterNote
    MetaTitle
    MetaDescription
`;

const SINGLE_INVESTMENT_DATA = `
    uid
    Tenant
    StatusInvestment
    CreatedDate
    ExpirationDate
    ProjectID
    LinkUser
    LinkProduct {
        ProductID
    }
    User {
        uid
        CreatedDate
        TypePerson
        FirstName
        LastName
        TypeGender
        Email
        BankAccount {
            Owner
            IBAN
            BIC
            uid
        }
        Phone {
            ${PHONE}
        }
        Broker {
            uid
            BrokerAccountTipsterOwnID
            StatusBrokerAccount
        }
        Tipster {
            uid
            WasReferredByTheTipster
            UserTipsterOwnID
        }
    }
    Amount
    Shares
    SharePrice
    IsXPConfirmed
    IsMissingXPConfirmed
    CouponCode
    LinkTipster
    IsRisksConfirmed
    IsBondTermsConfirmed
    IsPersonalInformationConfirmed
    IsBIBConfirmed
    IsBrokerageTermsConfirmed
    IsConsumerInformationConfirmed
    IsCostConfirmed
    Document {
        uid
        Path
        Title
        Filename
        CreatedDate
        TypeDocument
    }
    InvestorIP
    ReferenceNumber
    Tx
    Token
    IsIssuerAcceptedDate
    IsPaymentReceivedDate
    FirstTimeInvestment
    StatusInvestmentLastModifiedOn
    StatusProvisioning
    ProvisioningAmount
    LinkTransaction
    ProvisionPercentage
    IsCustodyDataForwardingConfirmed
    CopperOpportunityId
    IsProvisioningPendingDate
    IsProvisioningClearedDate
    IsProvisioningPayedDate
    UserEmail
    Payments
    CapitalGain
    TokenRemaining
    SalesRepresentative{
        uid
        FirstName
        LastName
        Email
    }
    TipsterFullName
    StatusUmbrellaClearance
    LinkPayments{
        uid
        TypePayment
    }
`;

const SINGLE_UMBRELLA_INVESTMENT_DATA = `
    uid
    StatusInvestment
    CreatedDate
    ProjectID
    LinkProduct {
        ProductID
    }
    User {
        uid
        CreatedDate
        TypePerson
        FirstName
        LastName
        TypeGender
        Email
        ${USER_KYC}
        ${ADEQUACY}
        ${IDENTIFICATION}
        StatusUmbrellaClearance
    }
    Amount
    Shares
    SharePrice
    IsXPConfirmed
    IsMissingXPConfirmed
    StatusUmbrellaClearance
    StatusInvestmentLastModifiedOn
    Document {
        uid
        Path
        Title
        Filename
        CreatedDate
        TypeDocument
    }
`;

const INVESTMENT = `
    investment {
        ${SINGLE_INVESTMENT_DATA}
    }
`;

const UMBRELLA_INVESTMENT_DATA = `
    investment {
        ${SINGLE_UMBRELLA_INVESTMENT_DATA}
    }
`;

const ISSUER_PUBLIC = `
    IssuerEmail
    MainTenant
    IssuerSlug
    Document {
        Path
        Title
        Description
        Filename
    }
    LegalName
    IssuerLogo {
        Path
        Filename
    }
    Background {
        Path
        Filename
    }
    LinkProducts {
        uid
        ProductID
        Document {
            Path
            Title
            Description
            Filename
        }
    }
    CompanyGoal
    Description
    FooterNote
    MetaTitle
    MetaDescription
`;

const USER_DATA = `
    uid
    Tenant
    Email
    CreatedDate
    UpdatedDate
    LeadSource
    TypeGender
    TypeTitle
    TypePerson
    FirstName
    LastName
    Phone {${PHONE}}
    Birthday
    Address {${ADDRESS}}
    CountryCode
    ${BROKER}
    BankAccount {${BANK_ACCOUNT}}
    ${IDENTIFICATION}
    ${USER_KYC}
    IsEffectaCustomerInformationConfirmed
    IsEffectaCustomerInformationConfirmedDate
    IsFinexityTermsConditionsConfirmed
    IsFinexityTermsConditionsConfirmedDate
    IsUserCallRecordingsConfirmed
    IsUserCallRecordingsConfirmedDate
    ${ADEQUACY}
    ${COOPER}
    Auth0UUID
    ${BLOCKCHAIN}
    ${TIPSTER}
    Investments
    Orders
    Roles
    Transactions
    StatusUserAccount
    IsRegistered
    PreferredLanguage
    StatusKYC
    StatusAdequacy
    StatusTipster
    StatusMobilePhone
    StatusTwoFactor
    StatusMangopay
    StatusSecondaryMarket
    StatusVIP
    StatusEmail
    StatusReferral
    Website
    IsUserAlreadyInvested
    TaxID
    IsUSTaxation
    IsPEP
    ${USER_SOCIAL}
    IsTwoFactorExpired
    IsTwoFactorExpiredDate
    StatusClubDeal
    StatusBroker
    LastChangeUnixTime
    ${MANGO_PAY}
    Company {${COMPANY}}
    ${USER_CLUB}
    Payments
    IsEmailConfirmationDate
    IsTestTemplate
    IsReRegister
    IsBzstBlockingNoticeSet
    UserRegistrationOnboardingStatus
    UserStateChangeDate
    TypeProgramApplication
    IsUserSecondaryMarketPassDataConfirmed
    IsUserSecondaryMarketPassDataConfirmedDate
    Portfolio {${PORTFOLIO_DATA}}
    FederalState
    Religion
    StatusTax
    Documents {${DOCUMENT}}
    Issuer {${ISSUER_WITHOUT_PRODUCT_CONTENT}}
    DateOfFirstInvestment
    LinkParentUserEmail
    StatusUmbrellaClearance
`;

const UMBRELLA_SINGLE_USER_DATA = `
    uid
    Email
    FirstName
    LastName
    TypeTitle
    TypeGender
    Phone {${PHONE}}
    Birthday
    Address {${ADDRESS}}
    ${USER_KYC}
    ${IDENTIFICATION}
    StatusUmbrellaClearance
`;

const USER = `
    user {${USER_DATA}}
`;

const REQUESTING_USER_INPUT = `
    user {${USER_DATA}}
    IsFinexityTermsConditionsExpired
    IsEffectaCustomerTermsConditionsExpired
    IsTenantMarketplaceTermsConditionsExpired
`;

export function generateTwilioAccessToken() {
    return api.post("/query", {
        query: `
            query {
                GenerateTwilioAccessToken {
                    token
                }
            }
        `,
    });
}

export function recreateInvestmentDocuments(InvestmentID) {
    return api.post("/query", {
        query: `
            mutation {
                recreateInvestmentDocuments(input: {InvestmentID: "${InvestmentID}"}) {
                    empty
                }
            }
        `,
    });
}

export function sendMessage(variables) {
    return api.post("/query", {
        query: `
            mutation($phone: PhoneIn!, $message: String!, $UserID: String!) {
                sendMessage(input: {phone: $phone, message: $message, UserID: $UserID} ) {
                    ${ACTIVITY}
                }
            }
        `,
        variables,
    });
}

export function userMessage(messageId, result) {
    return api.post("/query", {
        query: `
            query {
                userMessage(input: {messageId: "${messageId}"}) {
                    message {
                        ${result}
                    }
                }
            }
        `,
    });
}

export function userMessages(variables, result = "") {
    return api.post("/query", {
        query: `
            query ($email: String!, $page: Int, $limit: Int){
                userMessages(input: {email: $email, page: $page, limit:$limit}) {
                    messages {
                        ${result}
                    }
                }
            }
        `,
        variables,
    });
}

export function getTemplateTestUsers(variables) {
    return api.post("/query", {
        query: `
            query($offset: Int, $limit: Int) {
                getTemplateTestUsers(input: {page: $offset, limit: $limit}) {
                    templatetestusers {
                        ${USER_DATA}
                    }
                    totalUsers
                }
            }
        `,
        variables,
    });
}

export function fetchUsersCustomResult(variables, result) {
    return api.post("/query", {
        query: `
            query ($offset: Int, $limit: Int, $role: String, $phoneNumber: String) {
                users(input: {offset: $offset, limit: $limit, role: $role, phoneNumber: $phoneNumber}) {
                    ${result}
                }
            }
        `,
        variables,
    });
}

export function fetchTransactions() {
    return api.post("query", {
        query: `
            query {
                transactions {
                    ${TRANSACTIONS}
                }
            }
        `,
    });
}

export function deleteTransaction(ID) {
    return api.post("/query", {
        query: `
        mutation {
            deleteTransaction(input: {ID: "${ID}"}) {
                empty
            }
        }`,
    });
}

export function setTransaction(transaction) {
    return api.post("/query", {
        query: `
        mutation($transaction: TransactionIn) {
            setTransaction(input:{transaction: $transaction}) {
                transaction {
                    ${TRANSACTION_DATA}
                }
            }
          }`,
        variables: { transaction },
    });
}

export function mangopayTransfer(variables) {
    return api.post("query", {
        query: `
            mutation ( $CreditedUserEmail: String!, $TransferAmount: String!, $TAN: String) {
                mangopayTransfer(input: {CreditedUserEmail: $CreditedUserEmail, TransferAmount: $TransferAmount, TAN: $TAN}) {
                    ${PAYMENT}
                }
            }
        `,
        variables,
    });
}

export function mangopayPayIn(paymentAmount, paymentType) {
    return api.post("query", {
        query: `
            mutation {
                mangopayPayIn(input: {paymentAmount: "${paymentAmount}", paymentType: "${paymentType}"}) {
                    ${PAYMENT}
                }
            }
        `,
    });
}

export function mangopayPayOut(variables) {
    return api.post("query", {
        query: `
            mutation ( $bankAccountID: String!, $paymentAmount: String!, $TAN: String) {
                mangopayPayOut(input: {bankAccountID: $bankAccountID, paymentAmount: $paymentAmount, TAN: $TAN}) {
                    ${PAYMENT}
                }
            }
        `,
        variables,
    });
}

export function mangopayOnboarding(variables) {
    return api.post("query", {
        query: `
            mutation ( $IsFinexityTermsConditionsConfirmed: String, $IsMangopayTermsConditionsConfirmed: String!, $IsUserSecondaryMarketPassDataConfirmed: String!) {
                mangopayOnboarding(input: {
                    IsFinexityTermsConditionsConfirmed: $IsFinexityTermsConditionsConfirmed,
                    IsMangopayTermsConditionsConfirmed: $IsMangopayTermsConditionsConfirmed,
                    IsUserSecondaryMarketPassDataConfirmed: $IsUserSecondaryMarketPassDataConfirmed
                }) {
                    ${USER}
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function clubDealOnboarding() {
    return api.post("query", {
        query: `
            mutation {
                clubDealOnboarding {
                    empty
                }
            }
        `,
    });
}

export function clubDealOnboardingAlternativeInvestments(inputs) {
    return api.post("query", {
        query: `
            mutation ($inputs: clubDealOnboardingAlternativeInvestmentsInput) {
                clubDealOnboardingAlternativeInvestments(input: $inputs) {
                    empty
                }
            }
        `,
        variables: { inputs },
    });
}

export function clubDealOnboardingWealthPortfolio(inputs) {
    return api.post("query", {
        query: `
            mutation($inputs: clubDealOnboardingWealthPortfolioInput) {
                clubDealOnboardingWealthPortfolio(input: $inputs) {
                    empty
                }
            }
        `,
        variables: { inputs },
    });
}

export function clubDealOnboardingPersonalInformation(jobType, jobOther, type, companyName) {
    return api.post("query", {
        query: `
            mutation {
                clubDealOnboardingPersonalInformation(input: {
                    jobType: "${jobType}", jobOther: "${jobOther}", type: "${type}", companyName: "${companyName}"
                }) {
                    empty
                }
            }
        `,
    });
}

export function setClubDealOnboarding(email, status) {
    return api.post("query", {
        query: `
            mutation {
                setClubDealOnboarding(input: {email: "${email}", status: "${status}"}) {
                    empty
                }
            }
        `,
    });
}

export function addCallNote({ UserID = "", CallNote = "", CallSid = "" }, result = nativeUserActivityData) {
    return api.post("query", {
        query: `
            mutation {
                addCallNote(input: {userID: "${UserID}", callNote: "${CallNote}", callSid: "${CallSid}"}) {
                    activity {
                        ${result}
                    }
                }
            }
        `,
    });
}

export function activities(variables, result = ACTIVITY_DATA) {
    return api.post("query", {
        query: `
            query($UserID: String, $TypeActivity: String, $limit: Int, $offset: Int){
                activities(input: {UserID: $UserID, TypeActivity: $TypeActivity, limit: $limit, offset: $offset}) {
                    activities {
                        ${result}
                    }
                    count
                }
            }
        `,
        variables,
    });
}

export function activity({ uid = "", CallSid = "", ParentCallSid = "", CallID = "" }) {
    return api.post("query", {
        query: `
            query {
                activity(input: {uid: "${uid}", CallSid: "${CallSid}", ParentCallSid: "${ParentCallSid}", CallID: "${CallID}"}) {
                    ${ACTIVITY}
                }
            }
        `,
    });
}

export function createLead(variables) {
    return api.post("/query", {
        query: `
            mutation($CreateLeadInput: CreateLeadInput) {
                createLead(input : $CreateLeadInput) {
                    ${USER}
                    ${ACTIVITY}
                }
            }
        `,
        variables: { CreateLeadInput: variables },
    });
}

export function calls(variables = {}, result = CALL_DATA) {
    return api.post("/query", {
        query: `
            query($UserID: String, $SalesRepID: String, $offset: Int, $limit: Int){
                calls(input : {UserID: $UserID, SalesRepID: $SalesRepID, offset: $offset, limit: $limit}) {
                    calls {
                        ${result}
                    }
                    count
                }
            }
        `,
        variables,
    });
}

export function brokerOnboardingDeleteDocument(tile, name) {
    return api.post("/query", {
        query: `
            mutation {
                brokerOnboardingDeleteDocument(input: {documentTile: "${tile}", documentName: "${name}"}) {
                    empty
                }
            }
        `,
    });
}

export function setOnboarding(email, status) {
    // change broker onboarding status ( admin )
    return api.post("/query", {
        query: `
            mutation {
                setOnboarding(input: {email: "${email}", status: "${status}"}) {
                    empty
                }
            }
        `,
    });
}

export function setBrokerAccount(email, status) {
    // change broker account status ( admin )
    return api.post("/query", {
        query: `
            mutation {
                setBrokerAccount(input: {email: "${email}", status: "${status}"}) {
                    empty
                }
            }
        `,
    });
}

export function contactRequest(variables) {
    return api.post("/query", {
        operationName: null,
        query: `
            mutation ($name: String, $email: String, $message: String!, $phone: String, $source: String!) {
                contact(name: $name, email: $email, message: $message, phone: $phone, source: $source)
            }
        `,
        variables,
    });
}

export function setContactMethod(contactVia, projectID) {
    return api.post("/query", {
        query: `
            query {
                contactRequest(input: {contactVia: "${contactVia}", project: {projectID: "${projectID}"}}) {
                    empty
                }
            }
        `,
    });
}

export function partnerProgramSignupRequest(variables) {
    return api.post("/query", {
        operationName: null,
        query: `
            mutation (
                $companyName: String!, $title: String!, $gender: String!, $firstName: String!,
                $lastName: String!, $email: String!, $mobilePhoneNumber: String!, $mobilePhoneDialCode: String!
                $mobilePhoneCountryCode: String!, $license: String!,
                $tipsterID: String!, $utmSource: String!, $utmCampaign: String!,
            ) {
                partnerProgramSignup(
                    companyName: $companyName, title: $title, gender: $gender,
                    firstName: $firstName, lastName: $lastName, email: $email,
                    mobilePhoneNumber: $mobilePhoneNumber, mobilePhoneDialCode: $mobilePhoneDialCode,
                    mobilePhoneCountryCode: $mobilePhoneCountryCode, license: $license, tipsterID: $tipsterID,
                    utmSource: $utmSource, utmCampaign: $utmCampaign
                )
            }
        `,
        variables,
    });
}

export function confirmNewLetterRequest(variables) {
    return api.post("/query", {
        operationName: null,
        query: `
            mutation ($uuid: String!) {
                confirmNewsletter(uuid: $uuid)
            }
        `,
        variables,
    });
}

export function setTipsterId(variables) {
    variables.email = variables.email || getUserEmail();
    return api.post("/query", {
        query: `
            mutation ($email: String, $tipster: String!) {
                tipsterTile(input: {user: {Email: $email, Tipster: {WasReferredByTheTipster: $tipster}}}) {
                    user {
                        Email
                    }
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function setUserAdditionalStatus(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: SetDashboardInput ) {
                setDashboard(input: $variables) {
                    user {
                        Email
                    }
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

export function createInitialInvestmentData(variables) {
    return api.post("/query", {
        query: `
            mutation initInvestment($variables: InitInvestmentInput) {
                initInvestment(input: $variables) {
                    investment {
                        uid
                        Document {
                            Path
                            Title
                            Filename
                        }
                        Token
                    }
                }
            }
        `,
        variables: { variables: variables },
        operationName: null,
    });
}

export function createInvestmentData(variables) {
    variables.email = variables.email || getUserEmail();
    return api.post("/query", {
        query: `
            mutation (
                $IsRisksConfirmed: Boolean!, $IsCostConfirmed: Boolean!, $ID: String!,
                $amount: String!, $IsBIBConfirmed: Boolean!,
                $IsCustodyDataForwardingConfirmed: Boolean!, $IsBondTermsConfirmed: Boolean!, $projectID: String!,
                $IsBrokerageTermsConfirmed: Boolean!, $IsConsumerInformationConfirmed: Boolean!, $IsPersonalInformationConfirmed: Boolean!, 
                $IsXPConfirmed: Boolean!, $IsMissingXPConfirmed: Boolean!, $couponCode: String, $email: String
            ) {
                createInvestment(input: {investment: {
                    IsXPConfirmed: $IsXPConfirmed,
                    IsMissingXPConfirmed: $IsMissingXPConfirmed,
                    IsBrokerageTermsConfirmed: $IsBrokerageTermsConfirmed,
                    IsConsumerInformationConfirmed: $IsConsumerInformationConfirmed,
                    IsCostConfirmed: $IsCostConfirmed,
                    IsBIBConfirmed: $IsBIBConfirmed,
                    IsCustodyDataForwardingConfirmed: $IsCustodyDataForwardingConfirmed,
                    IsPersonalInformationConfirmed: $IsPersonalInformationConfirmed,
                    IsBondTermsConfirmed: $IsBondTermsConfirmed,
                    IsRisksConfirmed: $IsRisksConfirmed,
                    UserEmail: $email, ProjectID: $projectID,
                    uid: $ID, Amount: $amount, CouponCode: $couponCode
                }
            }) {
                    investment {
                        ${SINGLE_INVESTMENT_DATA}
                    }
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function setAddequacyNotWantedData(variables) {
    variables.Email = variables.Email || getUserEmail();
    return api.post("/query", {
        query: `
            mutation ($IsNotWantedConfirmed: Boolean!, $Email: String) {
                adequacyTile(input: { user: { Email: $Email, Adequacy: { IsNotWantedConfirmed: $IsNotWantedConfirmed } } }) {
                    user {
                        Email
                    }
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function editUserDetailAdmin(variables) {
    return api.post("/query", {
        query: `
            mutation ( $user: UserIn){
                setUser(input: {user: $user}){
                    user {
                        Email
                    }
                }
            }
        `,
        variables,
    });
}

export function setMangoPayOnboarding(variables) {
    return api.post("query", {
        query: `
            mutation ($Email: String!, $StatusMangopay: String, $KYCLevel: String, $BankAccountStatusMangopay: Boolean) {
                setMangoPayOnboarding(input: {
                    email: $Email, status: $StatusMangopay, kYCLevel: $KYCLevel,
                    bankAccountStatus: $BankAccountStatusMangopay
                }) {
                    user {
                        Email
                    }
                }
            }
        `,
        variables,
    });
}

export function setUserTaxData({ TaxID = "", Religion = "", FederalState = "", Country = "" }) {
    return api.post("/query", {
        query: `
            mutation {
                setUserTaxData(input: { TaxID: "${TaxID}", Religion: "${Religion}",
                FederalState: "${FederalState}", Country: "${Country}"
            }) {
                    ${USER}
                }
            }
        `,
    });
}

export function setUserDetails(variables) {
    return api.post("/query", {
        query: `
            mutation (
                $Email: String!, $TypeGender: String!, $TypeTitle: String, $FirstName: String!, $LastName: String!, $Number: String!,
                $DialCode: String!, $TypePhone: String!, $CountryCode: String!, $TypeBankAccount: String!, $IBAN: String!,
                $BIC: String!, $Owner: String!, $TypeAddress: String!, $Street: String!, $Streetnumber: String!, $Zipcode: String!,
                $StreetAddition: String!, $City: String!, $Country: String!, $TaxID: String!, $IsUSTaxation: String!, $IsPEP: String!,
                $Birthday: String!, $Birthplace: String!, $BirthCountry: String!, $Nationality: String!, $MaritalStatus: String!,
                $IsEffectaCustomerInformationConfirmed: String!,
            ) {
                kycTile(input: { user: {
                    Email: $Email, TypeGender: $TypeGender, TypeTitle: $TypeTitle, FirstName: $FirstName, LastName: $LastName,
                    Birthday: $Birthday, IsEffectaCustomerInformationConfirmed: $IsEffectaCustomerInformationConfirmed,
                    Phone: { TypePhone: $TypePhone, Number: $Number, DialCode: $DialCode, CountryCode: $CountryCode },
                    BankAccount: { TypeBankAccount: $TypeBankAccount, IBAN: $IBAN, BIC: $BIC, Owner: $Owner },
                    Address: {
                        Street: $Street, Streetnumber: $Streetnumber, Zipcode: $Zipcode,
                        StreetAddition: $StreetAddition, City: $City, Country: $Country,
                        TypeAddress: $TypeAddress
                    },
                    TaxID: $TaxID, IsUSTaxation: $IsUSTaxation, IsPEP: $IsPEP,
                    Identification: {
                        Birthplace: $Birthplace, BirthCountry: $BirthCountry,
                        Nationality: $Nationality, MaritalStatus: $MaritalStatus
                    }
                } }) {
                    user {
                        Email
                        KYC {
                            IDnowRedirectURL
                            StatusKYC
                        }
                    }
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function setMySettings(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: SetMySettingsInput ) {
                setMySettings(input: $variables) {
                    user {
                        KYC {
                            IDnowRedirectURL
                        }
                    }
                }
            }
        `,
        variables: { variables: variables },
        operationName: null,
    });
}

export function setAdequacyData(variables) {
    variables.email = variables.email || getUserEmail();
    return api.post("/query", {
        query: `
        mutation (
            $TypeJob: String!, $TypeEducation: String!, $IsBondsXPConfirmed: Boolean!, $IsBondsXPConfirmedDate: String!, $IsSharesXPConfirmed: Boolean!,
            $IsSharesXPConfirmedDate: String!, $IsInvestFundsXPConfirmed: Boolean!, $IsInvestFundsXPConfirmedDate: String!, $IsProfitPartXPConfirmed: Boolean!,
            $IsProfitPartXPConfirmedDate: String!, $IsSubordLoanXpConfirmed: Boolean!, $IsSubordLoanXpConfirmedDate: String!, $BondsXPAssisted: String!,
            $BondsTransactions: String!, $BondsAmount: String!, $BondsXPIndependent: String!, $BondsXPConsulted: String!,
            $email: String
        ) {
            adequacyTile(input: { user: {
                Adequacy: {
                    TypeJob: $TypeJob, TypeEducation: $TypeEducation, IsBondsXPConfirmed: $IsBondsXPConfirmed, IsBondsXPConfirmedDate: $IsBondsXPConfirmedDate, IsSharesXPConfirmed: $IsSharesXPConfirmed,
                    IsSharesXPConfirmedDate: $IsSharesXPConfirmedDate, IsInvestFundsXPConfirmed: $IsInvestFundsXPConfirmed, IsInvestFundsXPConfirmedDate: $IsInvestFundsXPConfirmedDate,
                    IsProfitPartXPConfirmed: $IsProfitPartXPConfirmed, IsProfitPartXPConfirmedDate: $IsProfitPartXPConfirmedDate, IsSubordLoanXpConfirmed: $IsSubordLoanXpConfirmed,
                    IsSubordLoanXpConfirmedDate: $IsSubordLoanXpConfirmedDate, BondsXPAssisted: $BondsXPAssisted, BondsTransactions: $BondsTransactions,
                    BondsAmount: $BondsAmount, BondsXPIndependent: $BondsXPIndependent, BondsXPConsulted: $BondsXPConsulted
                },
                Email: $email
            }}) {
              user {
                Email
                Adequacy {
                    StatusAdequacy
                }
              }
            }
          }
        `,
        variables,
        operationName: null,
    });
}

export function subscribeTag(variables) {
    return api.post("/query", {
        query: `
            mutation ( $tags: [SubscriptionIn]){
                subscribeTag(input: {tags: $tags}) {
                    empty
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function fetchUserByID(ID, result) {
    return api.post("/query", {
        query: `
            query {
                user(input: {
                    ID: "${ID}"
                }) {
                    user {
                        ${result || USER_DATA}
                    }
                }
            }
        `,
    });
}

export function fetchUserByEmail(email, result) {
    return api.post("/query", {
        query: `
            query {
                user(input: {
                    email: "${email}"
                }) {
                    user {
                        ${result || USER_DATA}
                    }
                }
            }
        `,
    });
}

export function fetchUmbrellaUserByID(ID) {
    return api.post("/query", {
        query: `
            query {
                user(input: {
                    ID: "${ID}"
                }) {
                    user {
                        ${UMBRELLA_SINGLE_USER_DATA}
                    }
                }
            }
        `,
    });
}

export function fetchUserDetails() {
    return api.post("/query", {
        query: `
            query {
                user {
                    ${REQUESTING_USER_INPUT}
                }
            }
        `,
    });
}

export function fetchAdequacy() {
    return api.post("/query", {
        query: `
            query {
                user {
                    user {
                        ${ADEQUACY}
                    }
                }
            }
        `,
    });
}

//FETCH DASHBOARD_DATA

export function dashboardDetails() {
    return api.post("/query", {
        query: `
            query {
                user {
                    user {
                        LinkInvestments{${SINGLE_INVESTMENT_DATA}}
                        LinkOrders{${ORDER}}
                    }
                }
            }
        `,
    });
}

export function estimatedWaiting() {
    return api.post("/query", {
        query: `
            query {
                estimatedWaiting{
                    time
                }
            }
        `,
    });
}

export function fetchProject(count = 0, projectID = "") {
    var email = getUserEmail() || "";
    return api.post("/query", {
        query: `
            query {
                projects(input: {
                    count: ${count},
                    projectID: "${projectID}",
                    email: "${email}"
                }) {
                    ${PROJECTS}
                }
            }
        `,
    });
}
export function fetchProductCatalogProjectsLimited(count = 0, projectID = "", userEmail = "") {
    const email = getUserEmail() || userEmail;
    return api.post("/query", {
        query: `
            query {
                productCatalogProjects(input: {
                    count: ${count},
                    projectID: "${projectID}",
					email: "${email}"
                }) {
                    projects {
                        ${PROJECT_DATA_LIMITED}
                    }
                }
            }
        `,
    });
}

export function fetchProductCatalogProjects(count = 0, projectID = "", userEmail = "") {
    const email = getUserEmail() || userEmail;
    return api.post("/query", {
        query: `
            query {
                productCatalogProjects(input: {
                    count: ${count},
                    projectID: "${projectID}",
					email: "${email}"
                }) {
                    ${PROJECTS}
                }
            }
        `,
    });
}

export function fetchProductCatalog() {
    return api.post("/query", {
        query: `
            query {
                productCatalog{
                    productCatalog{
                      uid
                      ProductCatalogName
                      LinkProducts{
                        ${PRODUCT}
                      }
                    }
                  }
            }
        `,
    });
}

export function createProductCatalog(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: CreateProductInput ){
                createProductCatalog(input: $variables) {
                    product{
                        ${PRODUCT}
                    }
                }  
            }`,
        variables: { variables: variables },
    });
}

export function setProductCatalog(variables) {
    return api.post("/query", {
        query: `
        mutation setProductCatalog($variables: SetProductCatalogInput) {
            setProductCatalog(input: $variables) {
              productCatalog {
                LinkProducts {
                  ${PRODUCT}
                }
                ProductCatalogName
              }
            }
          }`,
        variables: { variables: variables },
    });
}

export function deleteProductCatalogProduct(variables) {
    return api.post("/query", {
        query: `
        mutation deleteProductCatalogProduct($variables: DeleteProductCatalogProductInput) {
            deleteProductCatalogProduct(input: $variables) {
                productCatalog {
                    LinkProducts {
                      ${PRODUCT}
                    }
                    ProductCatalogName
                }
            }
          }`,
        variables: { variables: variables },
    });
}

export function userOnboardingRegistration(variables) {
    return api.post("/query", {
        query: `
            mutation ($variables: UserOnboardingRegistrationInput) {
                userOnboardingRegistration(input: $variables) {
                    empty
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function setBrokerOnboardingStepApplication() {
    return api.post("query", {
        query: `
            mutation{
                brokerOnboardingStepApplication(){
                    empty
                }
            }
        `,
    });
}

export function setClubDealOnboardingStepApplication() {
    return api.post("query", {
        query: `
            mutation {
                ClubDealOnboardingStepApplication(){
                    empty
                }
            }
        `,
    });
}

export function reserveShares(amount, projectID, encodedEmail = "") {
    return api.post("query", {
        query: `
            mutation{
                reserveShares(input: {amount: "${amount}", projectID: "${projectID}", encodedEmail: "${encodedEmail}" }){
                    investment {
                        uid
                    }
                }
            }
        `,
    });
}

export function adminReserveShares(email, projectID, amount) {
    return api.post("query", {
        query: `
            mutation{
                reserveShares(input: {email: "${email}", projectID: "${projectID}", amount: "${amount}"}){
                    investment {
                        Shares
                        ExpirationDate
                        LinkProduct {
                            ProductID
                        }
                        ProjectID
                        UserEmail
                        ProjectID
                    }
                }
            }
        `,
    });
}

export function resendMail(email, isUserOnboarding) {
    return api.post("query", {
        query: `
            query {
                resendEmailConfirmationMail(input: {
                    email: "${email}",
                    ${isUserOnboarding ? 'isUserRegistrationOnboarding: "true"' : ""}
                }) {
                    empty
                }
            }
        `,
    });
}

export function recreateCopperUser(uid) {
    return api.post("query", {
        query: `
        mutation {
            recreateCopperUser(input: {UserID: "${uid}"}) {
                empty
            }
        }
        `,
    });
}

export function enableReKYC(uid) {
    return api.post("query", {
        query: `
        mutation {
            enableReKYC(input: {userID: "${uid}"}) {
                empty
            }
        }
        `,
    });
}

export function userDashboardDetails() {
    const email = getUserEmail();
    return api.post("/query", {
        query: `
            query {
                user (input: {
                    email: "${email}"
                }) {
                    user {
                        Email
                        ${USER_DASHBOARD_DATA}
                    }
                }
            }
        `,
    });
}

export async function getTwilioRecordingFromDocumentID(documentID) {
    return fetch(`${DOCUMENT_HOST}/download/twilio/${documentID}`, {
        headers: downloadHeaders(),
    });
}

export function getPostidentCoupon(link) {
    return fetch(link, {
        method: "GET",
        headers: downloadHeaders(),
    });
}

export function exportUsers(path) {
    return fetch(`${DOCUMENT_HOST}/export/users${path}`, {
        headers: downloadHeaders(),
    });
}

export function exportInvestment(path = "") {
    const isAdmin = window?.location?.pathname?.includes?.("admin") || window?.location?.href?.includes?.("admin");
    return fetch(`${DOCUMENT_HOST}${isAdmin ? "/export/investments/admin" : "/export/investments"}${path}`, {
        headers: downloadHeaders(),
    });
}

export function getBrokerDocument(path, documentName) {
    return fetch(`${DOCUMENT_HOST}/download${path}${documentName}`, {
        headers: downloadHeaders(),
    });
}

export function brokerOnboardDirectly(userEmail) {
    return fetch(`${DOCUMENT_HOST}/api/broker/onboarded/directly`, {
        headers: downloadHeaders(),
        method: "POST",
        body: JSON.stringify({
            email: userEmail,
        }),
    });
}

export function setUserMobileDetails(variables) {
    return api.post("/query", {
        query: `
            mutation ($Number: String!, $TAN: String, $DialCode: String!, $CountryCode: String!, $TypePhone: String!) {
                mobileTile(input: {phone: {
                    TypePhone: $TypePhone, Number: $Number, DialCode: $DialCode, CountryCode: $CountryCode}, TAN: $TAN
                }) {
                    user {
                        ${USER_DATA}
                    }
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function sendTwoFactorMail() {
    return api.post("/query", {
        query: `
            mutation {
                sendTwoFactorMail {
                    empty
                }
            }
        `,
    });
}

export function createTestContent(emails) {
    return api.post("/query", {
        query: `
            mutation($emails: [String!]) {
                createTestContent(input: {emails: $emails}) {
                    empty
                }
            }
        `,
        variables: { emails },
    });
}

export function resetTestUserTemplate(emails) {
    return api.post("/query", {
        query: `
            mutation($emails: [String!]) {
                resetTestUserTemplate(input: {emails: $emails}) {
                    empty
                }
            }
        `,
        variables: { emails },
    });
}

export function clearOffer(variables) {
    return api.post("/query", {
        query: `
            mutation ($investmentIDs: [String!]!) {
                clearOffer(input: {investmentIDs: $investmentIDs}) {
                    failedToClearOffersForInvestments
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function clearPayment(variables) {
    return api.post("/query", {
        query: `
            mutation ($investmentIDs: [String!]!) {
                clearPayment(input: {investmentIDs: $investmentIDs}) {
                    failedToClearPaymentsForInvestments
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function pdfLinkToken() {
    return api.post("query", {
        query: `
            query {
                pdfLinkToken {
                    Token
                }
            }
        `,
    });
}

export function brokerPerformance(email) {
    return api.post("query", {
        query: `
            query($email: String) {
                brokerPerformance(input: {email: $email}) {
                    totalProvisions
                    totalProvisionsLastMonth
                    totalInvestments
                    pendingProvisions
                    clearedProvisions
                    payedProvisions
                    canceledProvisions
                    referralCustomers
                    referralCustomersinvestments
                    investments {
                        ${SINGLE_INVESTMENT_DATA}
                    }
                }
            }
        `,
        variables: { email },
    });
}

export function fetchUsers(variables, result) {
    return api.post("/query", {
        query: `
            query ($offset: Int, $limit: Int, $role: String, $phoneNumber: String, $search: String) {
                users(input: {offset: $offset, limit: $limit, role: $role, phoneNumber: $phoneNumber, search: $search}) {
                    totalUsers
                    phone {
                        countryCode
                        dialCode
                        phoneNumber
                    }
                    users {
                        ${result || USER_DATA}
                    }
                }
            }
        `,
        variables,
    });
}

export function fetchProjects(result) {
    return api.post("/query", {
        query: `
            query {
                projects {
                    projects {
                        ${result}
                    }
                }
            }
        `,
    });
}

export function fetchProjectsForSelect() {
    return api.post("/query", {
        query: `
            query {
                projects {
                    projects {
                        projectID
                        title
                    }
                }
            }
        `,
    });
}

export function fetchInvestments(variables, result) {
    return api.post("/query", {
        query: `
            query ($offset: Int!, $limit: Int!) {
                investments(input: {offset: $offset, limit: $limit}) {
                    ${result}
                    totalInvestments
                }
            }
        `,
        variables,
    });
}

export function checkTransactionReceiver() {
    const email = getUserEmail();
    return api.post("/query", {
        query: `
            query {
                checkTransactionReceiver(input: {email: "${email}"}) {
                    empty
                }
            }
        `,
    });
}

export function fetchTotalInvestmentShares(projectID) {
    const email = getUserEmail();
    return api.post("/query", {
        query: `
            query {
                investments(input:{email: "${email}", projectID: "${projectID}"}){
                    totalInvestmentsShare
                }
            }
        `,
    });
}

export function fetchInvestmentByProjectId(projectID) {
    return api.post("/query", {
        query: `
            query {
                investments(input:{projectID: "${projectID}"}){
                    investments {
                        ${SINGLE_INVESTMENT_DATA}
                    }
                }
            }
        `,
    });
}

export function fetchUserInvestmentsByProjectID(projectID = "", email = "") {
    email = email || getUserEmail();
    return api.post("/query", {
        query: `
            query {
                investments(input: {
                    projectID: "${projectID}",
                    email: "${email}"
                }) {
                    investments {
                        ${SINGLE_INVESTMENT_DATA}
                    }
                }
            }
        `,
    });
}

export function fetchUserInvestments(email = "", result) {
    email = email || getUserEmail();
    return api.post("/query", {
        query: `
            query {
                user(input: {
                    email: "${email}"
                }) {
                    user {
                        LinkInvestments{
                            ${result || SINGLE_INVESTMENT_DATA}
                        }
                    }
                }
            }
        `,
    });
}

export function fetchInvestmentStatus(ID) {
    return api.post("/query", {
        query: `
            query {
                investment(input: {investmentID: "${ID}"}) {
                    investment{
                        StatusInvestment
                    }
                }
            }
        `,
    });
}

export function fetchCompleteInvestment(ID) {
    return api.post("/query", {
        query: `
            query{
                investment(input: {investmentID: "${ID}"}){
                    ${INVESTMENT}
                }
            }
        `,
    });
}

export function fetchCompleteInvestmentForUmbrella(ID) {
    return api.post("/query", {
        query: `
            query{
                investment(input: {investmentID: "${ID}"}){
                    ${UMBRELLA_INVESTMENT_DATA}
                }
            }
        `,
    });
}

export function ibanCheck(iban = "") {
    return api.post("/query", {
        query: `
            query {
                ibanCheck(input: {iban: "${iban}"}) {
                    message
                    bic
                    bank
                }
            }
        `,
    });
}

export function setInterestInProject(variables) {
    return api.post("/query", {
        query: `
            mutation ($email: String!, $projectID: String!) {
                setInterestInProject(input: {email: $email, projectID: $projectID}) {
                    empty
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function changeUserActiveState(email) {
    return api.post("/query", {
        query: `
            mutation ($email: String!) {
                userAccount(input: {email: $email}) {
                    empty
                }
            }
        `,
        variables: { email },
        operationName: null,
    });
}

export function setInvestment(variables) {
    return api.post("/query", {
        query: `
            mutation($investment: InvestmentIn) {
                setInvestment(input: {investment: $investment}) {
                    investment {
                        uid
                    }
                }
            }
        `,
        variables: { investment: variables },
        operationName: null,
    });
}

export function setPayment(variables) {
    return api.post("/query", {
        query: `
            mutation($payment: PaymentIn) {
                setPayment(input: {payment: $payment}) {
                    payment {
                        uid
                    }
                }
            }
        `,
        variables: { payment: variables },
        operationName: null,
    });
}

export function deleteInvestment(ID) {
    return api.post("/query", {
        query: `
            mutation {
                deleteInvestment(input: {ID: "${ID}"}) {
                    empty
                }
            }
        `,
    });
}

export function getInvestmentPayment(userID, investmentID, paymentType) {
    return api.post("/query", {
        query: `query{
                payments(input: {userID: "${userID}" investmentID: "${investmentID}" paymentType: "${paymentType}"}){
                ${PAYMENTS}
              }
        }`,
    });
}

export function fetchPayments(payload) {
    return api.post("/query", {
        query: `
            query ($limit: Int, $offset: Int, $search: String) {
                payments(input: {search: $search, offset: $offset, limit: $limit}) {
                    ${payload.result}
                    totalPayments
                }
            }
        `,
        variables: payload.reqData,
    });
}
export function fetchPaymentsForAUser(userID) {
    return api.post("/query", {
        query: `
        query {
            user(input: {
                ID: "${userID}"
            }) {
                user {
                    LinkPayments{${PAYMENT_DATA}}
                }
            }
        }
        `,
    });
}

export function fetchCompletePayment(ID) {
    return api.post("/query", {
        query: `
        query{
            payment(input: {paymentID: "${ID}"}){
                ${PAYMENT}
            }
        }
        `,
    });
}

export function deletePayment(ID) {
    return api.post("/query", {
        query: `
            mutation {
                deletePayment(input: {ID: "${ID}"}) {
                    empty
                }
            }
        `,
    });
}

export function deletePerformance(ID) {
    return api.post("/query", {
        query: `
            mutation {
                deletePerformance(input: {ID: "${ID}"}) {
                    empty
                }
            }
        `,
    });
}

export function deleteUser(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: DeleteUserInput ) {
                deleteUser(input: $variables) {
                    responseMessage
                }
            }
        `,
        variables: { variables: variables },
        operationName: null,
    });
}

export function brokerCustomerInvestment(page, limit, type = "", status = "") {
    return api.post("/query", {
        query: `
            query {
                brokerCustomerInvestment(input: { page: ${page}, limit: ${limit}, type: "${type}", status: "${status}" }) {
                    investments {
                        ${SINGLE_INVESTMENT_DATA}
                    }
                    totalInvestments
                }
            }
        `,
    });
}

export function checkEmail(email, isAttemptRegister = false) {
    return api.post("query", {
        query: `
            query{
                checkEmail(input: {email: "${email}", isAttemptRegister: ${isAttemptRegister}}) {
                    emailResponse,
                    userTenants
                }
            }
        `,
    });
}

export function setTipsterConfirmed(variables) {
    return api.post("query", {
        query: `
            mutation ($tipsterConfirmed: Boolean) {
                setMySettings(input: { referral: $tipsterConfirmed }) {
                    user {
                        Email
                    }
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function cancelInvestment(investmentID) {
    return api.post("/query", {
        query: `
            mutation {
                cancelReservation(input: {investmentID: "${investmentID}"}) {
                    empty
                }
            }
        `,
    });
}

export function changePassword(variables) {
    return api.post("/query", {
        query: `
            mutation ($password: String!, $oldPassword: String!) {
                changePassword(input: {password: $password, oldPassword: $oldPassword}) {
                    empty
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function resendUserMail({ email, investmentID, mailType }) {
    return api.post("/query", {
        query: `
            query {
                resendUserMail(input: {email: "${email}", mailType: "${mailType}", investmentID: "${investmentID}"}) {
                    empty
                }
            }
        `,
    });
}

export function setUserRole(variables) {
    return api.post("/query", {
        query: `
            mutation ($email: String!, $roles: [String!]!) {
                setRole(input: {roles: $roles, email: $email}) {
                    empty
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function brokerOnboarding() {
    return api.post("/query", {
        query: `
            mutation {
                brokerOnboarding {
                    empty
                }
            }
        `,
    });
}

export function brokerOnboardingDetails(variables) {
    return api.post("/query", {
        query: `
            mutation( $companyName: String!, $license: String!, $address: AddressIn!, $bankAccount: BankAccountIn!, $taxID: String! ){
                brokerOnboardingDetails(input: {
                    license: $license,
                    companyName: $companyName,
                    address: $address,
                    bankAccount: $bankAccount,
                    taxID: $taxID
                }) {
                    empty
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function brokerOnboardingTipsterConfirm() {
    return api.post("/query", {
        query: `
            mutation{
                brokerOnboardingTipsterConfirm(input: { tipsterConfirmed: ${true} }) {
                    empty
                }
            }
        `,
    });
}

export function tipsterUsers(page, limit, tipsterID) {
    return api.post("/query", {
        query: `
            query {
                tipsterUsers(input: { page: ${page}, limit: ${limit}, tipsterID: "${tipsterID}" }) {
                    users {
                        uid
                        FirstName
                        LastName
                        Email
                        CreatedDate
                        Tipster{
                            WasReferredByTheTipsterDate
                        }
                    }
                    totalUsers
                    tipsterUser {
                        uid
                        Email
                        FirstName
                        LastName
                    }
                }
            }
        `,
    });
}

export function setBrokerProvision(
    email,
    provisioningType,
    initialInvestmentPercentage,
    followupPercentage,
    protectionPeriodInYears
) {
    return api.post("/query", {
        query: `
            mutation {
                setBrokerProvision(input: {
                    email: "${email}",
                    provisioningType: "${provisioningType}",
                    initialInvestmentPercentage: ${initialInvestmentPercentage},
                    followupPercentage: ${followupPercentage},
                    protectionPeriodInYears: ${protectionPeriodInYears}
                }) {
                    user {
                        Email
                    }
                }
            }
        `,
    });
}

export function brokerEmailTemplates() {
    return api.post("/query", {
        query: `
            query {
                brokerEmailTemplates {
                    brokerEmailTemplates {
                        title
                        eMailTemplates {
                            title
                            section {
                                activeForBroker
                                sectionTemplateTitle
                                sectionTemplateContentHtml
                            }
                        }
                        blockCanvasHtml
                    }
                }
            }
        `,
    });
}

export function checkMangoPayBalance(type, refreshBalance = false) {
    return api.post("/query", {
        query: `
            query {
                checkMangoPayBalance(input: {type: "${type}", refreshBalance: ${refreshBalance}}) {
                    Amount
                }
            }
        `,
    });
}

export function checkMinPayInAmount() {
    return api.post("/query", {
        query: `
            query {
                payInAmount {
                    status
                    minPayment
                }
            }
        `,
    });
}

export function slackMessage(email) {
    return api.post("query", {
        query: `
            query {
                slackMessage(input: {
                    email: "${email}"
                }) {
                    empty
                }
            }
        `,
    });
}

export function createTemplateUser(variables) {
    return api.post("/query", {
        query: `
            mutation ( $userEmail: String!, $email: String!) {
                createTemplateUser(input: {userEmail: $email, templateEmail: $userEmail}) {
                    empty
                }
            }
        `,
        variables,
    });
}

export function createTemplateTestUser(variables) {
    return api.post("/query", {
        query: `mutation($email: String!, $firstName: String, $lastName: String, $mobilePhoneNumber: String,,  $mobilePhoneCountryCode: String, $mobilePhoneDialCode: String, $IsReRegistered: Boolean, $emailConfirmed: Boolean, $testTemplate: Boolean,  ) {
            createTemplateTestUser(input: {email: $email, firstName: $firstName, lastName: $lastName, mobilePhoneNumber: $mobilePhoneNumber, mobilePhoneCountryCode: $mobilePhoneCountryCode, mobilePhoneDialCode: $mobilePhoneDialCode, IsReRegistered: $IsReRegistered, emailConfirmed: $emailConfirmed, testTemplate: $testTemplate}) {
              empty
            }
          }`,
        variables,
    });
}
export function createPayment(variables) {
    return api.post("/query", {
        query: `
            mutation($payment: PaymentIn) {
                createPayment(input: {payment: $payment}) {
                    ${PAYMENT}
                }
            }
         `,
        variables: { payment: variables },
        operationName: null,
    });
}

export function inheritUser(email) {
    return api.post("query", {
        query: `
            query {
                inheritUser(input: {
                    email: "${email}"
                }) {
                    token
                }
            }
        `,
    });
}

export function userOnboarding(action) {
    return api.post("query", {
        query: `
            mutation {
                userOnboarding(input:{
                    action:"${action}"
                }){
                    UserRegistrationOnboardingStatus
                }
            }
        `,
    });
}

export function userOnboardingUserDetails(variables) {
    return api.post("/query", {
        query: `
            mutation ( $title: String, $gender: String, $firstName: String, $lastName: String) {
                userOnboardingUserDetails(input: {title: $title, gender: $gender, firstName: $firstName, lastName: $lastName}) {
                    user {
                        ${USER_DATA}
                    }
                }
            }
        `,
        variables,
    });
}

export function userOnboardingTerms(variables) {
    return api.post("/query", {
        query: `
            mutation ($variables: UserOnboardingTermsInput) {
                userOnboardingTerms(input: $variables) {
                    user {
                        ${USER_DATA}
                    }
                }
            }
        `,
        variables: { variables },
    });
}

export function userOnboardingTermsAndConditionsAcceptance(variables) {
    return api.post("/query", {
        query: `
            mutation ($variables: UserOnboardingTermsAndConditionsAcceptanceInput) {
                userOnboardingTermsAndConditionsAcceptance(input: $variables) {
                    user {
                        ${USER_DATA}
                    }
                }
            }
        `,
        variables: { variables },
    });
}

export function userOnboardingInterests(variables) {
    return api.post("/query", {
        query: `
            mutation ($tags: [String!]!) {
                userOnboardingInterests(input: {tags: $tags}) {
                    user {
                        ${USER_DATA}
                    }
                }
            }
        `,
        variables,
    });
}

export function checkMobile(mobilePhone, dialCode) {
    return api.post("query", {
        query: `
            query{
                checkMobile(input: {mobilePhone: "${mobilePhone}" dialCode: "${dialCode}"}) {
                    mobileResponse
                }
            }
        `,
    });
}

// Get orders
export function getOrders(variables, result) {
    return api.post("/query", {
        query: `
            query ( $variables: OrdersInput ) {
                orders(input: $variables) {
                  ${
                      result ||
                      `orders {
                            ${ORDER}
                        }`
                  }
                  totalOrders
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

// Get order by orderId
export function getOrderByID(orderId, result) {
    return api.post("/query", {
        query: `
            query ($orderId: String!) {
                order(input: {orderID: $orderId}) {
                  ${result}
                }
            }`,
        variables: { orderId },
    });
}

// To init an order
export function initOrder(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables:  InitOrderInput ) {
                initOrder(input: $variables) {
                  order {
                      ${ORDER}
                  }
                }
            }`,
        variables: { variables: { order: variables } },
        operationName: null,
    });
}

// For TAN query
export function orderTan(orderId) {
    return api.post("query", {
        query: `
            query {
                orderTan(input: {orderId: "${orderId}"}) {
                  empty
                }
            }
        `,
    });
}

// Create an order
export function createOrder(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: CreateOrderInput ) {
                createOrder(input: $variables) {
                  order {
                      ${ORDER}
                  }
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

// update an order
export function setOrder(order) {
    return api.post("/query", {
        query: `
            mutation ($variables: SetOrderInput) {
                setOrder(input: $variables) {
                  order {
                      ${ORDER}
                  }
                }
            }`,
        variables: { variables: { order } },
        operationName: null,
    });
}

// To accept an order
export function acceptOrder(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: AcceptOrderInput ) {
                acceptOrder(input: $variables) {
                  order {
                      ${ORDER}
                  }
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

// To settle / accept an order
export function settlement(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: SettlementInput ) {
                settlement(input: $variables) {
                    order {
                        ${ORDER}
                    }
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

// To update an order
export function setMyOrder(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: SetMyOrderInput ) {
                setMyOrder(input: $variables) {
                  order {
                      ${ORDER}
                  }
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

// To cancel an order
export function cancelOrder(orderID) {
    return api.post("/query", {
        query: `
            mutation {
                cancelOrder(input: {orderID: "${orderID}"}) {
                    empty
                }
            }
        `,
    });
}

// To deactivate an order
export function deactivateOrder(orderID) {
    return api.post("/query", {
        query: `
            mutation {
                deactivateOrder(input: {ID: "${orderID}"}) {
                    empty
                }
            }
        `,
    });
}

export function getToken(variables) {
    return api.post("/query", {
        query: `
            query($variables: TokensInput) {
                tokens(input: $variables){
                    tokens{
                        ${TOKEN_DATA}
                    }
                  totalTokens
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

export function getPerformances(variables) {
    return api.post("/query", {
        query: `
            query ($variables: PerformancesInput){
                performances(input: $variables){
                    performances{
                        ${PERFORMANCE_DATA}
                    }
                    totalPerformances
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

export function setPerformance(performance) {
    return api.post("/query", {
        query: `
        mutation($performance: PerformanceIn) {
            setPerformance(input:{performance:$performance})
            {performance{
                ${PERFORMANCE_DATA}
            }}
          }`,
        variables: { performance },
    });
}

export function createPerformance(performance) {
    return api.post("/query", {
        query: `
        mutation($performance: PerformanceIn) {
            createPerformance(input:{performance:$performance})
            {performance{
                ${PERFORMANCE_DATA}
            }}
          }`,
        variables: { performance },
    });
}

export function setToken(token) {
    return api.post("/query", {
        query: `
        mutation($token: TokenIn) {
            setToken(input:{token:$token})
            {token{
                ${TOKEN_DATA}
            }}
          }`,
        variables: { token },
    });
}

export function createToken(token) {
    return api.post("/query", {
        query: `
        mutation($token: TokenIn) {
            createToken(input:{token:$token})
            {token{
                ${TOKEN_DATA}
            }}
          }`,
        variables: { token },
    });
}

export function deleteToken(ID) {
    return api.post("/query", {
        query: `
        mutation {
            deleteToken(input: {ID: "${ID}"}) {
                empty
            }
        }`,
    });
}

export function getProjectTokens(variables) {
    return api.post("/query", {
        query: `
        query ( $variables: ProjectTokensInput ){
            projectTokens(input: $variables) {
                projectTokens {
                    title
                    projectID
                    status
                    shares
                    indicativeTokenValue
                    tokenMarketPrice
                    valuationDateItv
                }
            }
        }`,
        variables: { variables: variables },
        operationName: null,
    });
}

export function getAndroidAppTestTags() {
    return api.post("/query", {
        query: `
        query {
            getAndroidAppTestTags(input:{}){
                tags
            }
        }`,
    });
}

export function getWebsiteTestTags() {
    return api.post("/query", {
        query: `
        query {
            getWebsiteTestTags(input:{}){
                tags
            }
        }`,
    });
}

export function getIOSAppTestTags() {
    return api.post("/query", {
        query: `
        query {
            getIOSAppTestTags(input:{}){
                tags
            }
        }`,
    });
}

export function invokeWebsiteTest(variables) {
    return api.post("/query", {
        query: `
            query ( $variables: InvokeWebsiteTestInput ) {
                invokeWebsiteTest(input: $variables) {
                  empty
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

export function invokeAndroidAppTest(variables) {
    return api.post("/query", {
        query: `
            query ( $variables: InvokeAndroidAppTestInput ) {
                invokeAndroidAppTest(input: $variables) {
                  empty
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

export function invokeIOSAppTest(variables) {
    return api.post("/query", {
        query: `
            query ( $variables: InvokeIOSAppTestInput ) {
                invokeIOSAppTest(input: $variables) {
                  empty
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

export function getTenant() {
    return api.post("/query", {
        query: `
            query {
                tenant {
                    tenant {
                      ${TENANT_DATA}
                    }
                }
            }`,
    });
}

export function getTenants(variables, result) {
    return api.post("/query", {
        query: `
            query($variables: TenantsInput){
                tenants(input: $variables) {
                    tenants{
                      ${result}
                    } 
                    totalTenants
                }  
            }`,
        variables: { variables },
    });
}

export function createTenant(variables) {
    return api.post("/query", {
        query: `
            mutation($tenant: TenantIn) {
                createTenant(input:{tenant: $tenant}) {
                    tenant{
                        ${TENANT_DATA}
                    }
                }
            }`,
        variables,
    });
}

export function setTenant(variables) {
    return api.post("/query", {
        query: `
            mutation ($variables: SetTenantInput) {
                setTenant(input: $variables) {
                    tenant {
                        ${TENANT_DATA}
                    }
                }
            }`,
        variables,
    });
}

export function setAppConfig(variables) {
    return api.post("/query", {
        query: `
            mutation ($variables: SetAppConfigInput) {
                setAppConfig(input: $variables) {
                    empty
                }
            }`,
        variables,
    });
}

export function deleteTenant(ID) {
    return api.post("/query", {
        query: `
        mutation {
            deleteTenant(input:{ID: "${ID}"})
            {empty}
          }`,
    });
}

export function setTenantSettings(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: SetTenantSettingsInput ){
                setTenantSettings(input: $variables) {
                    tenant {
                        ${TENANT_DATA}
                    }
                }  
            }`,
        variables: { variables },
    });
}

export function sendCustomMail(input) {
    console.log("variables -- ", input);
    return api.post("/query", {
        query: `mutation($input: SendCustomMailInput)
            {
            sendCustomMail(input: $input) {
                ${ACTIVITY}           
            }
        }`,
        variables: { input },
    });
}

export function fetchProjectTokens(variables) {
    return api.post("/query", {
        query: `query ( $variables: ProjectTokensInput ){
            projectTokens(input: $variables) {
                projectTokens {
                    title
                    projectID
                    status
                    shares
                    indicativeTokenValue
                    tokenMarketPrice
                    valuationDateItv
                }
            }
        }`,
        variables: { variables: variables },
        operationName: null,
    });
}

export function addBankAccount({ variables }) {
    return api.post("query", {
        query: `
            mutation ( $Iban: String!, $Bic: String!, $Owner: String!, $preferredBankAccount: Boolean) {
                addBankAccount(input: {Iban: $Iban, Bic: $Bic, Owner: $Owner, preferredBankAccount: $preferredBankAccount}) {
                    ${USER}
                }
            }
        `,
        variables,
        operationName: null,
    });
}
export function addBankAccountAdmin(variables) {
    return api.post("query", {
        query: `
        mutation ($variables: AddBankAccountInput){
            addBankAccount(input: $variables) {
                ${USER}
            }
        }
        `,
        variables,
        operationName: null,
    });
}
export function setPreferredBankAccount(variables) {
    return api.post("query", {
        query: `
            mutation ( $variables: SetPreferredBankAccountInput ) {
                setPreferredBankAccount(input: $variables) {
                    empty
                }
            }
        `,
        variables: { variables: variables },
        operationName: null,
    });
}

export function sendOfferReceivedWaitingForKYC(investmentID) {
    return api.post("query", {
        query: `
            query {
                sendOfferReceivedWaitingForKYC(input:{investmentID: "${investmentID}"}){
                  empty
                }
              }
        `,
    });
}

export function createWallet(email) {
    return api.post("query", {
        query: `
              mutation {
                createWallet(input: {email: "${email}"}) {
                    empty
                }
            }
        `,
    });
}

export function deployContract(tokenId) {
    return api.post("query", {
        query: `
              mutation {
                deployContract(input: {tokenID: "${tokenId}"}) {
                    empty
                }
            }
        `,
    });
}

export function userMangopayOnboarding(variables) {
    return api.post("query", {
        query: `
              mutation($email: String!) {
                userMangopayOnboarding(input: {email: $email}) {
                    empty
                }
            }
        `,
        variables: { email: variables },
    });
}

export function recreateMangoPayKYCDocument(variables) {
    return api.post("query", {
        query: `
              mutation($email: String!) {
                recreateMangoPayKYCDocument(input: {email: $email}) {
                    empty
                }
            }
        `,
        variables: { email: variables },
    });
}

export function queryTransactionStatus(transactionID) {
    return api.post("query", {
        query: `
              mutation {
                queryTransactionStatus(input: {transactionID: "${transactionID}"}) {
                    transaction {
                        ${TRANSACTION_DATA}
                    }
                }
            }
        `,
    });
}

export function enableUserKYC(userID, disableKYC) {
    return api.post("query", {
        query: `
              mutation {
                enableUserKYC(input: {userID: "${userID}", disableKYC:${disableKYC}}) {
                    empty
                }
            }
        `,
    });
}

export function sendOfferAcceptanceMail(variables) {
    return api.post("query", {
        query: `
        mutation ($variables: SendOfferAcceptanceMailInput){
            sendOfferAcceptanceMail(input: $variables) {
                ${INVESTMENT}
            }
        }`,
        variables: { variables },
    });
}

export function sendInvestRefusedByIssuer(investmentID) {
    return api.post("query", {
        query: `
            mutation {
                sendInvestRefusedByIssuer(input: {investmentID: "${investmentID}"}) {
                    ${INVESTMENT}
                }
            }
        `,
    });
}

export function setPartialInvestmentAcceptance({ Amount, InvestmentID }) {
    return api.post("query", {
        query: `
            mutation {
                setPartialInvestmentAcceptance(input: {InvestmentID: "${InvestmentID}", Amount: "${Amount}"}) {
                    ${INVESTMENT}
                }
            }
        `,
    });
}

export function sendPaymentReceivedMail(investmentID) {
    return api.post("query", {
        query: `
            query {
                sendPaymentReceivedMail(input: {investmentID: "${investmentID}"}) {
                    ${INVESTMENT}
                }
            }
        `,
    });
}

export function sendPaymentWaitingMail(investmentID) {
    return api.post("query", {
        query: `
            query {
                sendPaymentWaitingMail(input: {investmentID: "${investmentID}"}) {
                    empty
                }
            }
        `,
    });
}
export function issueInvestmentToken(investmentID) {
    return api.post("query", {
        query: `
            mutation {
                issueInvestmentToken(input: {InvestmentID: "${investmentID}"}) {
                    empty
                }
            }
        `,
    });
}

export function call(callID) {
    return api.post("query", {
        query: `
            query {
                call(input: {uid: "${callID}"}) {
                    call {
                        ${CALL_DATA}
                    }
                }
            }
        `,
    });
}

export function confirmProjectFunding(projectID) {
    return api.post("query", {
        query: `
            mutation {
                confirmProjectFunding(input: {projectID: "${projectID}"}) {
                    empty
                }
            }
        `,
    });
}

export function issueProjectToken(projectID, issueTokens = false) {
    return api.post("query", {
        query: `
            mutation {
                issueProjectToken(input: {projectID: "${projectID}", issueTokens: ${issueTokens}}) {
                    tokenInitialSupply
                    totalInvestmentsAmount
                    usersWithoutWallet {
                        uid
                        Email
                    }
                    usersWithWallet {
                        uid
                        Email
                    }
                }
            }
        `,
    });
}

export function updateActivity(variables) {
    return api.post("query", {
        query: `
            mutation($activity: ActivityIn!, $dontUpdateEmptyValues: Boolean!){
                setActivity(input: {activity: $activity, dontUpdateEmptyValues: $dontUpdateEmptyValues}) {
                    ${ACTIVITY}
                }
            }
        `,
        variables,
    });
}

export function getProjectOffers(projectID = "") {
    return api.post("/query", {
        query: `
            query {
                projectOffers(input: {projectID: "${projectID}" }){
                    projectOffers {
                        buyPrice,
                        highestBuyPrice,
                        projectID,
                        sellPrice,
                        status,
                        title
                    }
                }
            }`,
    });
}

export function getUserTokens(email) {
    return api.post("/query", {
        query: `
            query ($email: String!){
                userTokens(input: {email: $email }){
                    tokens {
                        AvailableAmount,
                        Name,
                        Symbol
                    }
                }
            }`,
        variables: { email },
    });
}

export function checkEthereumBalance(projectID) {
    return api.post("/query", {
        query: `
            query ($projectID: String!){
                checkEthereumBalance(input: {projectID: $projectID }){
                    Amount,
                    BlockedAmount
                }  
            }`,
        variables: { projectID },
    });
}

export function getUserWallets({ email = "", type = "" }) {
    return api.post("/query", {
        query: `
            query {
                userWallets(input: {email: "${email}", type: "${type}" }){
                    wallets{
                        availableAmount,
                        walletType
                    }
                }
            }`,
    });
}

export function createUserInvestment(variables) {
    return api.post("query", {
        query: `
            mutation ( $amount: String!, $email: String!, $projectID: String!, $Tenant: String!) {
                createUserInvestment(input: {amount: $amount, email: $email, projectID: $projectID, Tenant: $Tenant}) {
                    ${INVESTMENT}
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function createUserAsset(variables) {
    return api.post("query", {
        query: `
            mutation ($email: String!, $projectID: String!,) {
                createUserAsset(input: {email: $email, projectID: $projectID}) {
                    ${USER}
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function deleteUserAsset(variables) {
    return api.post("query", {
        query: `
            mutation ($email: String!, $assetID: String!,) {
                deleteUserAsset(input: {email: $email, assetID: $assetID}) {
                    empty
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function userSecondaryMarketAccount(email) {
    return api.post("/query", {
        query: `
            mutation ($email: String!) {
                userSecondaryMarketAccount(input: {email: $email}) {
                    empty
                }
            }
        `,
        variables: { email },
        operationName: null,
    });
}

export function getInbox(variables) {
    return api.post("/query", {
        query: `
            query($variables: GetInboxInput){
                getInbox(input: $variables) {
                    messages{
                        ${GET_INBOX_DATA}
                    }
                    totalMessages
                }
            }`,
        variables: { variables: variables },
    });
}

// broker onboarding action back
export function brokerOnboardingActionBack(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: BrokerOnboardingActionBackInput ) {
                brokerOnboardingActionBack(input: $variables) {
                    StatusBrokerOnboarding
                }  
            }`,
        variables: { variables: variables },
    });
}

// Club deal onboarding action back
export function clubDealOnboardingActionBack(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: ClubDealOnboardingActionBackInput ) {
                clubDealOnboardingActionBack(input: $variables) {
                    StatusClubDeal
                }  
            }`,
        variables: { variables: variables },
    });
}

export function messageDismiss(ID) {
    return api.post("/query", {
        query: `
        mutation ($ID: [String!]!){messageDismiss(input:{ID: $ID}){
              empty
            }} 
            `,
        variables: { ID: ID },
    });
}

export function markAsReadMessage(ID) {
    return api.post("/query", {
        query: `
        mutation ($ID: [String!]!) {
            markAsReadMessage(input: {ID: $ID}) {
              empty
            }
          }`,
        variables: { ID: ID },
    });
}

export function getMessage(ID) {
    return api.post("/query", {
        query: `query{
            getMessage(input: {ID: "${ID}"}) {
                message {
                    ${CALL_TO_ACTION_DATA}
                }
            }
        }`,
    });
}
export function deleteMail(ActivityID) {
    return api.post("/query", {
        query: `
            mutation {
                deleteMail(input: {ActivityID: "${ActivityID}"}) {
                    empty
                }  
            }`,
    });
}

export function subscribeProjectNewsletter(variables) {
    return api.post("/query", {
        query: `mutation($email: String!, $projectID: String!, $tags: [SubscriptionIn]! ) {
            subscribeProjectNewsletter(input: {email: $email, tags: $tags, projectID: $projectID}) {
              empty
            }
          }`,
        variables,
    });
}
export function createProduct(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: CreateProductInput ){
                createProduct(input: $variables) {
                    product{
                        ${PRODUCT}
                    }
                }  
            }`,
        variables: { variables: variables },
    });
}

export function getProducts(variables, result) {
    return api.post("/query", {
        query: `
            query($variables: ProductsInput) {
                products(input: $variables) {
                    products {
                        ${result || PRODUCT_WITH_TAKESHAPE}
                    }
                    totalProducts
                }
            }
        `,
        variables: { variables: variables },
    });
}

export function deleteProduct(variables) {
    return api.post("query", {
        query: `
            mutation ($ID: String!,) {
                deleteProduct(input: {ID: $ID}) {
                    empty
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function setProduct(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: SetProductInput ){
                setProduct(input: $variables) {
                    product{
                        ${PRODUCT}
                    }
                }  
            }`,
        variables: { variables: variables },
    });
}

export function getDocuments(variables, result) {
    return api.post("/query", {
        query: `
            query($variables: GetDocumentsInput) {
                getDocuments(input: $variables) {
                    documents {
                        ${result}
                    }
                    totalDocuments
                }
            }
        `,
        variables: { variables },
    });
}

export function setDocument(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: SetDocumentInput ){
                setDocument(input: $variables) {
                    document{
                        ${DOCUMENT}
                    }
                }  
            }`,
        variables: { variables: variables },
    });
}

export function deleteDocument(variables) {
    return api.post("query", {
        query: `
            mutation ($documentID: String!) {
                deleteDocument(input: {documentID: $documentID}) {
                    empty
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function deleteInvestmentDocument(variables) {
    return api.post("query", {
        query: `
            mutation ($investmentID: String!, $documentID: String!) {
                deleteInvestmentDocument(input: {investmentID: $investmentID, documentID: $documentID}) {
                    empty
                }
            }
        `,
        variables,
        operationName: null,
    });
}

export function updateIdentificationFiles(email) {
    return api.post("/query", {
        query: `
            mutation ($email: String!) {
                updateIdentificationFiles(input: {email: $email}) {
                    empty
                }
            }
        `,
        variables: { email },
        operationName: null,
    });
}

export function getIssuer(issuerID) {
    return api.post("/query", {
        query: `
            query($issuerID: String!) {
                issuer(input: {issuerID: $issuerID}) {
                    issuer{
                        ${ISSUER}
                    }
                }
            }
        `,
        variables: { issuerID },
    });
}

export function getIssuerBySlug(issuerSlug) {
    return api.post("/query", {
        query: `
            query($issuerSlug: String!) {
                issuer(input: {issuerSlug: $issuerSlug}) {
                    issuer{
                        ${ISSUER_PUBLIC}
                    }
                }
            }
        `,
        variables: { issuerSlug },
    });
}

export function getIssuers(variables, result) {
    return api.post("/query", {
        query: `
            query($offset: Int, $limit: Int, $managers: [String!], $query: String, $products: [String!]) {
                issuers(input: {offset: $offset, limit: $limit, managers: $managers, query: $query, products: $products}) {
                    issuers{
                        ${result || ISSUER}
                    }
                    totalIssuers
                }
            }
        `,
        variables,
    });
}

export function createIssuer(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: CreateIssuerInput ){
                createIssuer(input: $variables) {
                    issuer {
                        ${ISSUER}
                    }
                }  
            }`,
        variables: { variables: variables },
    });
}

export function setIssuer(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: SetIssuerInput ){
                setIssuer(input: $variables) {
                    issuer{
                        ${ISSUER}
                    }
                }  
            }`,
        variables: { variables: variables },
    });
}

export function deleteIssuer(variables) {
    return api.post("/query", {
        query: `
            mutation ($ID: String!){
                deleteIssuer(input: {ID: $ID}) {
                    empty
                }  
            }`,
        variables,
        operationName: null,
    });
}

export function checkTenantSmtpConfig(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: TestSMTPCredentialsInput ){
                testSMTPCredentials(input: $variables) {
                    empty
                }  
            }`,
        variables: { variables },
    });
}

export function forgetPasswordAPI(email) {
    return api.post("/query", {
        query: `
            query {
                forgetPassword(input: {email: "${email}"}) {
                    empty
                }  
            }`,
    });
}

export function getNotificationsAPI(offset, limit, search, result) {
    return api.post("/query", {
        query: `
            query ($search: String, $offset: Int, $limit: Int) {
				getNotifications(input: { search: $search, offset: $offset, limit: $limit }) {
                    ${result}
				}
			}`,
        variables: { offset, limit, search },
    });
}

export function getNotificationAPI(ID) {
    return api.post("/query", {
        query: `
            query ($ID: String!) {
				getNotification(input: {ID: $ID}) {
					notification {
						uid
						TypeStatus
						Title
						Body
						Link
						CreatedDate
						ScheduledDate
						SendDate
						FilterAll
						FilterProject
						FilterInvestmentStatus
						FilterRecipients
						SentToUsers
					}
				}
			}`,
        variables: { ID },
    });
}

export function createNotificationAPI(notification) {
    return api.post("/query", {
        query: `mutation($notification: CreateNotificationInput){
				createNotification(input: $notification) {
					notification{
						uid  
						TypeStatus 
						Title 
						Body 
						Link  
						CreatedDate 
						ScheduledDate 
						SendDate 
						FilterAll 
						FilterInvestmentStatus 
						FilterProject 
						FilterRecipients
					}
				}
			}`,
        variables: { notification },
    });
}

export function deleteNotificationAPI(notification) {
    return api.post("/query", {
        query: `
            mutation($notification: DeleteNotificationInput){
				deleteNotification(input:$notification) {
					empty
				}
			}`,
        variables: { notification },
    });
}

export function setNotificationAPI(notification) {
    return api.post("/query", {
        query: `mutation($notification : SetNotificationInput){
				setNotification(input: $notification){
					notification {
						uid  
						TypeStatus 
						Title 
						Body 
						Link  
						CreatedDate 
						ScheduledDate 
						SendDate 
						FilterAll 
						FilterInvestmentStatus 
						FilterProject 
						FilterRecipients
					}
				}
			}`,
        variables: { notification },
    });
}

export function sendTipsterApproval(email) {
    return api.post("/query", {
        query: `
            mutation {
                SendTipsterApproval(input: {email: "${email}"}) {
                    empty
                }
            }
        `,
    });
}

export function resetPassword(variables) {
    return api.post("/query", {
        query: `
            mutation ($variables: ResetPasswordInput){
                resetPassword(input: $variables) {
                    empty
                }
            }
        `,
        variables,
    });
}

export const fetchSearchUsers = (variables, result) => {
    return api.post("/query", {
        query: `
            query ( 
				$first: Int 
				$offset: Int
				$products: [String!]
				$query: String
				$status: [String!]
				$tenants: [String!]
                $showUmbrellaUsers: Boolean
			) {
				searchUsers(
					input: {
						first: $first
						offset: $offset
						query: $query
						products: $products
						status: $status
						tenants: $tenants
                        showUmbrellaUsers: $showUmbrellaUsers
					}
				) {
					totalUsers
					users {${result || USER_DATA}}
				}
			}
        `,
        variables,
    });
};

export const fetchSearchUsersTileData = (variables, result) => {
    return api.post("/query", {
        query: `
            query ( 
				$first: Int 
				$offset: Int
				$products: [String!]
				$query: String
				$status: [String!]
				$tenants: [String!]
                $showUmbrellaUsers: Boolean
			) {
				searchUsers(
					input: {
						first: $first
						offset: $offset
						query: $query
						products: $products
						status: $status
						tenants: $tenants
                        showUmbrellaUsers: $showUmbrellaUsers
					}
				) {
					totalUsers
					${result}
				}
			}
        `,
        variables,
    });
};

export const fetchSearchInvestments = (variables, result) => {
    return api.post("/query", {
        query: `
            query (
                $first: Int
                $offset: Int
                $investmentStatus: [String!]
                $productID: [String!]
                $tenant: [String!]
                $textSearch: String
			) {
				searchInvestments(
					input: {
						first: $first
						offset: $offset
                        investmentStatus: $investmentStatus
                        productID: $productID
                        tenant: $tenant
                        textSearch: $textSearch
					}
				) {
					totalInvestments
                    projectsInvestmentInfo{
                        ProjectId
                        ProjectTitle
                        SumAmount
                        Count
                    }
					investments {${result}}
				}
			}
        `,
        variables,
    });
};

export const fetchTotalInvestmentAmount = (variables) => {
    return api.post("/query", {
        query: `
            query (
                $projectID: String!
            ) {
                totalInvestmentAmount(
                    input: {
                        projectID: $projectID
                    }
                ) {
                    totalInvestmentAmount
                }
            }
        `,
        variables,
    });
};

export const fetchSearchUmbrellaInvestments = (variables) => {
    return api.post("/query", {
        query: `
            query (
                $first: Int
                $offset: Int
                $StatusUmbrellaClearance: [String!]
                $textSearch: String
			) {
				searchUmbrellaInvestments(
					input: {
						first: $first
						offset: $offset
                        statusUmbrellaClearance: $StatusUmbrellaClearance
                        textSearch: $textSearch
					}
				) {
					totalInvestments
					investments {
                        uid
                        Amount
                        LinkProduct {
                            ProductID
                        }
                        UserEmail
                        ProjectID
                        StatusInvestment
                        StatusUmbrellaClearance
                        StatusInvestmentLastModifiedOn
                        User{
                            uid
                            StatusUmbrellaClearance
                            FirstName
                            LastName
                        }
                    }
				}
			}
        `,
        variables,
    });
};

export function fetchCustomProjects() {
    return api.post("/query", {
        query: `
            query {
                projects {
                    projects {
                        title
                        projectID
                        images {
                            teaserImage{
                                path
                            }
                        }
                        address{
                            city
                            country
                        }
                    }
                }
            }
        `,
    });
}

export function setTenantHowItWorkTile(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: SetTenantHowItWorkTileInput ){
                setTenantHowItWorkTile(input: $variables) {
                    tenant {
                        ${TENANT_DATA}
                    }
                }  
            }`,
        variables: { variables },
    });
}

export function fetchDashboardOrders() {
    return api.post("/query", {
        query: `
            query {
                dashboardOrders {
                    orders {
                        ${ORDER}
                    }
                }
            }
        `,
    });
}

export function fetchDashboardInvestments() {
    return api.post("/query", {
        query: `
            query {
                dashboardInvestments {
                    investments {
                        ${SINGLE_INVESTMENT_DATA}
                    }
                }
            }
        `,
    });
}

export function initiateProductPayout(variables, cancelToken) {
    return api.post(
        "/query",
        {
            query: `
            mutation ( $productID: String!,$payoutType: String! ){
                initiateProductPayout(input: { productID: $productID, payoutType: $payoutType }) {
                    product {
                        ${PRODUCT}
                    }
                }  
            }`,
            variables,
        },
        { cancelToken }
    );
}

export function cancelProductPayout(variables) {
    return api.post("/query", {
        query: `
            mutation ( $productID: String!, $payoutType: String!, $payoutID: String! ){
                cancelProductPayout(input: { productID: $productID, payoutType: $payoutType, payoutID: $payoutID }) {
                    product {
                        ${PRODUCT}
                    }
                }  
            }`,
        variables,
    });
}

export function setProductPayoutStatus(variables) {
    return api.post("/query", {
        query: `
            mutation ( $productID: String!, $payoutType: String!, $payoutID: String! ){
                setProductPayoutStatus(input: { productID: $productID, payoutType: $payoutType, payoutID: $payoutID }) {
                    product {
                        ${PRODUCT}
                    }
                }  
            }`,
        variables,
    });
}

export function setProductPayoutTaxInfoReceive(variables) {
    return api.post("/query", {
        query: `
            mutation ( $productID: String!, $payoutType: String!, $payoutID: String! ){
                setProductPayoutTaxInfoReceive(input: { productID: $productID, payoutType: $payoutType, payoutID: $payoutID }) {
                    product {
                        ${PRODUCT}
                    }
                }  
            }`,
        variables,
    });
}

export function setProductPayoutAmount(variables) {
    return api.post("/query", {
        query: `
            mutation ( $productID: String!, $payoutType: String!, $payoutID: String!, $amount: String! ){
                setProductPayoutAmount(input: { productID: $productID, payoutType: $payoutType, payoutID: $payoutID, amount: $amount }) {
                    product {
                        ${PRODUCT}
                    }
                }  
            }`,
        variables,
    });
}

export function setProductPayoutComplete(variables) {
    return api.post("/query", {
        query: `
            mutation ( $productID: String!, $payoutType: String!, $payoutID: String!, $isPaidAuthorized: Boolean!){
                setProductPayoutComplete(input: { productID: $productID, payoutType: $payoutType, payoutID: $payoutID, isPaidAuthorized: $isPaidAuthorized }) {
                    product {
                        ${PRODUCT}
                    }
                }  
            }`,
        variables,
    });
}

export function deleteProductPayout(variables) {
    return api.post("/query", {
        query: `
            mutation ( $productID: String!, $payoutType: String!, $payoutID: String! ){
                deleteProductPayout(input: { productID: $productID, payoutType: $payoutType, payoutID: $payoutID }) {
                    product {
                        uid
                    }
                }  
            }`,
        variables,
    });
}

export function primaryMarketInvestmentTan(investmentID) {
    return api.post("query", {
        query: `
            query {
                primaryMarketInvestmentTan(input: {investmentID: "${investmentID}"}) {
                  empty
                }
            }
        `,
    });
}

export function settlePrimaryMarketInvestment(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: SettlePrimaryMarketInvestmentInput ) {
                settlePrimaryMarketInvestment(input: $variables) {
                    investment {
                        uid
                    }
                }
            }`,
        variables: { variables: variables },
        operationName: null,
    });
}

export function getProduct(projectID) {
    return api.post("/query", {
        query: `query{
            product(input: {projectID: "${projectID}"}) {
                product {
                    IsAvailableForEwalletFunding
                }
            }
        }`,
    });
}

export const fetchSearchUsersForIssuerManager = (variables) => {
    return api.post("/query", {
        query: `
            query (
                $first: Int
                $offset: Int
                $issuers: [String!]
                $query: String
                $products: [String!]
			) {
				searchUsersForIssuerManager(
					input: {
						first: $first
						offset: $offset
                        issuers: $issuers
                        query: $query
                        products: $products
					}
				) {
					totalUsers
                    users {
                        ${USER_DATA}
                    }
				}
			}
        `,
        variables,
    });
};

export const fetchSearchInvestmentsForIssuerManager = (variables) => {
    return api.post("/query", {
        query: `
            query($variables: SearchInvestmentsForIssuerManagerInput) {
				searchInvestmentsForIssuerManager(input: $variables) {
					totalInvestments
                    projectsInvestmentInfo{
                        ProjectId
                        ProjectTitle
                        SumAmount
                        Count
                    }
					investments {${SINGLE_INVESTMENT_DATA}}
				}
			}
        `,
        variables: { variables: variables },
    });
};

export function addIssuerManagers(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: AddIssuerManagerLinkingInput ){
                addIssuerManagerLinking(input: $variables) {
                    empty
                }  
            }`,
        variables: { variables },
    });
}

export function deleteIssuerManagers(variables) {
    return api.post("/query", {
        query: `
            mutation ( $variables: DeleteIssuerManagerLinkingInput ){
                deleteIssuerManagerLinking(input: $variables) {
                    empty
                }  
            }`,
        variables: { variables },
    });
}

export const fetchProductPayouts = (variables) => {
    return api.post("/query", {
        query: `
            query($variables: ProductsPayoutsInput) {
				productsPayouts(input: $variables) {
					totalPayouts
					payouts {
                        uid
                        State
                        CalculatedPayoutAmount
                        CreatedDate
                        DatePayoutCompleted
                        EnteredPayoutAmount
                        RequestBy
                        CancelledBy
                        Type
                        Product {
                            uid
                            ProductID
                            Title
                            TitleProductTile
                            Issuer {
                                uid
                                LegalName
                                IssuerEmail
                            }
                        }
                    }
				}
			}
        `,
        variables: { variables: variables },
    });
};

export const getIssuerPayInWalletForTaxReport = (productID) => {
    return api.post("/query", {
        query: `
            query() {
				getIssuerPayInWalletForTaxReport(input: {productID: "${productID}"}) {
					email
                    payInAmount
                    productID
				}
			}
        `,
    });
};

export function setClubDealUser(email) {
    return api.post("/query", {
        query: `
            mutation {
                setClubDealUser(input: {email: "${email}"}) {
                    empty
                }
            }
        `,
    });
}

export function commonTestingSuit(URL) {
    const accessToken = getAccessToken();
    return fetch(URL, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
}

export function runTests(URL, tag) {
    const accessToken = getAccessToken();
    return fetch(URL, {
        method: "POST",
        body: JSON.stringify({
            tag,
        }),
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        },
    });
}

export const getUserSubscriptions = (UserId) => {
    return api.post("/query", {
        query: `
            query {
				getUserSubscriptions(input: {UserId: "${UserId}"}) {
                    subscriptions{
                        ProjectID
                        StatusTag
                        Tag
                        UserUID
                        UUID
                    }
				}
			}
        `,
    });
};

// APIs for Tenant KPI

export function tenantOverviewKPI(dateRange = "today") {
    return api.post("/api/tenant/overview/kpi", { dateRange });
}

export function tenantProductKPI() {
    return api.get("/api/tenant/product/kpi");
}

export function tenantUserKPI(dateRange = "today") {
    return api.post("/api/tenant/user/kpi", { dateRange });
}

export function tenantInvestmentKPI(dateRange = "today") {
    return api.post("/api/tenant/investment/kpi", { dateRange });
}

export function issuerProductKPI(payload) {
    return api.post("/api/issuer/product/kpi", payload);
}

export function issuerInvestmentKPI(payload) {
    return api.post("/api/issuer/investment/kpi", payload);
}

export function issuerUserKPI(payload) {
    return api.post("/api/issuer/user/kpi", payload);
}

export function issuerOverviewKPI(payload) {
    return api.post("/api/issuer/overview/kpi", payload);
}

export function getIssuerTransactions(payload) {
    return api.post("/api/issuer/wallet/transactions", payload);
}

export function issuerWalletKPI(payload) {
    return api.post("/api/issuer/wallet/kpi", payload);
}

export function addMangopayObj(payload) {
    return api.post("/api/user/addmangopayobj", payload);
}
