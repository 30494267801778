/* eslint-disable react-hooks/rules-of-hooks */
import IntlFormattedMessage from "components/IntlFormattedMessage";
import { useFormattedMessage } from "hooks";
import { web_flow_routes } from "./routes_config";
import {
    INVESTMENT_STATUS,
    PROJECT_STATUS,
    TILE_STATUS,
    PAYMENT_STATUS,
    TRANSACTION_STATUS,
    ORDER_STATUS,
    PROVISION_STATUS,
    PAYMENT_TYPE,
} from "./status";
import CancelTag from "assets/images/svg/cancel-tag.svg";
import ExpiredTag from "assets/images/svg/expired-tag.svg";
import FailedTag from "assets/images/svg/failed-tag.svg";
import ProgressTag from "assets/images/svg/progress-tag.svg";
import DeactivatedTag from "assets/images/svg/deactivated-tag.svg";
import Estate from "assets/images/svg/estate.svg";
import Instruments from "assets/images/svg/instruments.svg";
import Diamond from "assets/images/svg/diamond.svg";
import ClassicCars from "assets/images/svg/classic-cars.svg";
import Watch from "assets/images/svg/watch.svg";
import Renewable from "assets/images/svg/renewable.svg";
import Art from "assets/images/svg/art.svg";
import PrivateEquity from "assets/images/svg/private-equity.svg";
import FineWine from "assets/images/svg/fine-wine.svg";
import Sync from "assets/images/svg/sync.svg";
import Plus from "assets/images/svg/plus.svg";
import SachwertInvest from "assets/images/svg/sachwert-invest.svg";
import Assetta from "assets/images/svg/assetta.svg";
import GoldenCircle from "assets/images/svg/golden-circle.svg";
import Demo from "assets/images/svg/demo.svg";
import Fairzinsung from "assets/images/svg/fairzinsung.svg";
import FinexityHealthcare from "assets/images/svg/finexity-healthcare.svg";
import Revnex from "assets/images/svg/revnex.svg";
import Watchvest from "assets/images/svg/watchvest.svg";
import Winvesta from "assets/images/svg/winvesta.svg";
import Finexity from "assets/images/svg/finexity.svg";
import Token1 from "assets/images/svg/token1.svg";
import AnlagenwertHamburg from "assets/images/svg/anlagenwert-hamburg.svg";
import { adminInvestmentMessages } from "i18n/messages";
import countries from "i18n-iso-countries";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

const messages = {
    jobDataAssetManager: "asset_manager",
    jobDataFinancialAdvisor: "financial_advisor_data",
    jobDataFinancialBroker: "financial_broker",
    jobDataFinancialIntermediary: "financial_intermediary",
    jobDataInvestmentBanker: "investment_banker_data",
    jobDataAuditors: "auditors",
    jobDataBankClerk: "bank_clerk",
    jobDataManagerialFunctionControlling: "managerial_function_controlling",
    jobDataManagerialFunctionFinanceDepartment: "managerial_function_finance_department",
    jobDataTaxConsultant: "tax_consultant_data",
    jobDataEmployee: "employee",
    jobDataArtisan: "artisan",
    selfEmployed: "self_employed",
    jobDataOtherProfession: "other_profession_outside_of_finance",
    educationDataDoctorateHabilitation: "doctorate_habilitation",
    educationDataAcademicDegree: "academic_degree",
    educationDataCommercialTraining: "commercial_training",
    educationDataTechnicalCollege: "technical_college",
    educationDataSecondaryElementary: "secondary_elementary",
    educationDataVocationalTraining: "other_vocational_training",
    educationDataNone: "none_of_degrees_mentioned",
    upto3Years: "upto3_years",
    threeto5Years: "threeto5_years",
    over5Years: "over5_years",
    none: "none",
    until5: "up_to_5",
    until10: "up_to_10",
    moreThan10: "more_than10",
    upto3000Euros: "upto3000_euros",
    threeTo5000Euros: "three_to5000_euros",
    fiveTo5000Euros: "five_to5000_euros",
    upto10000Euros: "upto_10k_euros",
    noText: "no_text",
    yesText: "yes_text",
    oftenUsed: "often_used",
    allCountries: "all_countries",
    person: "person",
    expertise: "expertise",
    costs: "costs",
    overview: "overview",
    msSingle: "single",
    msLivingTogether: "living_together",
    msMaried: "maried",
    msPartnership: "civil_partnership",
    msSeparatedAlive: "living_seperately",
    msDivorced: "divorced",
    msWidowed: "widowed",
    msPartnerDecease: "life_partner_died",
    msInDivorce: "in_divorce_process",
    germany: "country_germany",
    austria: "country_austria",
    switzerland: "country_switzerland",
    unitedKingdom: "country_uk",
    luxembourg: "country_luxembourg",
    paymentStatusPending: "refunded",
    paymentStatusCompleted: "order_status_completed",
    paymentStatusCreated: "created",
    paymentStatusFailed: "payment_status_failed",
    paymentStatusTransferFailed: "payment_status_failed",
    paymentStatusSucceeded: "succeeded",
    paymentStatusPayoutNormalSucceeded: "payout_normal_succeeded",
    paymentStatusRefunded: "refunded",
    paymentStatusWaiting: "waiting",
    paymentTypeTransfer: "transfer",
    paymentTypePayIn: "pay_in",
    paymentTypePayOut: "pay_out",
    paymentTypeUserInvestEwalletTransfer: "user_invest_ewallet_transfer",
    paymentTypeUserTransfer: "transfer",
    paymentTypeUserTransferExternal: "transfer",
    paymentTypeBuy: "to_buy",
    paymentTypeSell: "to_sell",
    paymentTypeDividendPayOut: "dividend_payout",
    paymentTypeExitPayOut: "exit_payout",

    typeBankAccountUserPrimary: "primary_bank_account",
    otherSavedBankAccounts: "other_saved_bank_accounts",

    orderStatusActive: "order_status_active",
    orderStatusDeactivate: "order_status_deactivate",
    orderStatusInitiated: "order_status_initiated",
    orderStatusExpired: "order_status_expired",
    orderStatusFailed: "order_status_failed",
    orderStatusSuccess: "confirmation_success",
    orderStatusCompleted: "order_status_completed",
    orderStatusProgress: "order_status_progress",

    buy: "to_buy",
    toSell: "to_sell",
    toSplit: "to_split",
    toSplitSell: "to_split_sell",
    toSplitBuy: "to_split_buy",
    statusCompleted: "order_status_completed",
    statusRepaid: "paid_out",
    statusCanceled: "status_canceled",
    statusBought: "status_bought",
    statusSold: "status_sold",
    marketOrientedPerformace: "market_oriented_performace",
    newIndicativeTokenValueITV: "new_indicative_token_value_itv",
    tokenMarketPriceTMP: "token_market_price_tmp",

    projectTypeFineWine: "fine_wine",
    projectTypeDiamonds: "diamonds",
    projectTypeArt: "art",
    projectTypeResidential: "residential",
    projectTypeCommercial: "commercial",
    projectTypeClassicCars: "classic_cars",
    projectTypeVacationProperty: "project_type_vacation_property",
    projectTypeServicedApartment: "serviced_apartment",
    projectTypeTokenizedAIF: "tokenized_aif",
    projectTypeVariousTypesOfUse: "various_types_of_use",
    projectTypeWatches: "project_type_watches",
    projectTypePrivateEquity: "private_equity",
    projectTypeVentureCapital: "venture_capital",
    projectTypeRenewables: "renewables",
    projectTypeSustainability: "sustainability",
    projectTypeStringInstruments: "string_instruments",
    projectTypeHotel: "project_type_hotel",
    projectTypeHealthcare: "project_type_healthcare",
    projectTypeClubDealPublicPlacement: "project_type_clubdeal_public_placement",
    projectTypeClubDealPublicPlacement5k: "project_type_clubdeal_public_placement_5k",
    projectTypeClubDealPublicPlacement15k: "project_type_clubdeal_public_placement_15k",
    projectTypeClubDealPublicPlacement25k: "project_type_clubdeal_public_placement_25k",
    projectTypeClubDealPublicPlacement50k: "project_type_clubdeal_public_placement_50k",
    projectTypeClubDealPublicPlacement100k: "project_type_clubdeal_public_placement_100k",
    projectTypeClubDealPrivatePlacement: "project_type_clubdeal_private_placement",
    projectTypeClubDealDirectSelect: "project_type_clubdeal_direct_select",

    rolesVip: "roles_vip",
    rolesRetail: "roles_retail",
    rolesBroker: "roles_broker",
    rolesClub: "roles_club",
    rolesAdmin: "roles_admin",
    rolesTenant: "roles_tenant",
    rolesIssuer: "roles_issuer",
    rolesTester: "roles_tester",
    rolesUmbrella: "roles_umbrella",

    legalFormOption1: "one_man_business",
    legalFormOption2: "gbr",
    legalFormOption3: "ohg",
    legalFormOption4: "kg",
    legalFormOption5: "gmbh_and_co_kg",
    legalFormOption6: "ag",
    legalFormOption7: "gmbh",
    legalFormOption8: "kgaa",
    legalFormOption9: "cooperative",
    legalFormOption10: "others",
    //Info block messages
    ClubdealsInfoBlockText: "meta_description_clubdeals_page",
    ArtInfoBlockText: "marketplace_art_info_block_text",
    FineWineInfoBlockText: "marketplace_fine_wine_info_block_text",
    DiamondInfoBlockText: "marketplace_diamond_info_block_text",
    ClassicCarsInfoBlockText: "marketplace_classicCars_info_block_text",
    WatchesInfoBlockText: "marketplace_watches_info_block_text",
    PrivateEquityInfoBlockText: "marketplace_private_equity_info_block_text",
    VacationPropertyInfoBlockText: "marketplace_vacation_property_info_block_text",
    InfoBlockText: "marketplace_info_block_text",
    today: "today",
    "7Days": "7_days",
    "14Days": "14_days",
    "30Days": "30_days",
    allTime: "all_time",
};

export {
    prevent_routes_with_token as PREVENT_ROUTE_WITH_TOKEN,
    deleted_paths as DELETED_PATHS,
    blocked_prod_routes as BLOCKED_PATHS_FOR_PROD,
    private_routes as PRIVATE_ROUTE,
    allowed_mobile_routes as ALLOWED_PATHS_FOR_MOBILE,
    admin_routes as ADMIN_ROUTE_WITH_TOKEN,
    broker_routes as BROKER_ROUTE_WITH_TOKEN,
    clubdeals_route as CLUBDEALS_ROUTE_WITH_TOKEN,
    tenant_routes as TENANT_ROUTE_WITH_TOKEN,
    issuers_routes as ISSUER_ROUTE_WITH_TOKEN,
    tenant_allowed_routes as TENANT_ALLOWED_ROUTE,
    umbrella_routes as UMBRELLA_ROUTE_WITH_TOKEN,
    web_flow_routes as WEB_FLOW_ROUTES,
    public_navigation_routes as PUBLIC_NAVIGATION_ROUTES,
    paths_with_dashboard_header as PATHS_WITH_DASHBOARD_VISIBLE,
    paths_without_footer as PATHS_WITH_FOOTER_HIDDEN,
    paths_without_default_og_image as PATHS_WITH_NOT_DEFAULT_OG_IMAGE,
} from "./routes_config";

export { default as USER_ROLES } from "./roles";

export { default as GENERAL_CONSTANTS } from "./general_constants";

export const SOCIAL_LOGIN_LEAD_SOURCE = ["Google", "LinkedIn", "Apple", "Facebook"];

export function PERFORMANCE_TYPE() {
    const { formatMessage } = useFormattedMessage();
    return [
        { value: "IndicativeTokenValue", label: formatMessage(messages.marketOrientedPerformace) },
        { value: "NewIndicativeTokenValue", label: formatMessage(messages.newIndicativeTokenValueITV) },
        { value: "TokenMarketPrice", label: formatMessage(messages.tokenMarketPriceTMP) },
    ];
}

export const PERFORMANCE_TYPE_CONSTANT = {
    ITV: "indicativeTokenValue",
    TW_ITV: "NewIndicativeTokenValue",
    TMP: "TokenMarketPrice",
};

export const PERFORMANCE_TYPE_SHORT = {
    IndicativeTokenValue: "ITV",
    NewIndicativeTokenValue: "TW-ITV",
    TokenMarketPrice: "TMP",
};

export const TOKEN_ADDRESS = {
    JAUTokenContract: "0x452FC6e1e931377940431e2907E32b7E2d184435",
    MBOTokenContract: "",
    TNTTokenContract: "0x98E577b3421F3065DF42E73577BC31B97A9a4367",
    JAULocalTokenContract: "0xE02Ab39BA7B2d9Cb34484b43042C64F62Cdb77A3",
};

const optionsProfitabilitySliderSettings = {
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    arrows: true,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 1301,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 3,
                arrows: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 3,
                arrows: true,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 4.5,
                slidesToScroll: 3,
                arrows: true,
            },
        },
    ],
};

export const adminInvestmentsStatusFilter = {
    [INVESTMENT_STATUS.RESERVED]: "Reserved",
    [INVESTMENT_STATUS.INITIALIZED]: "Initialized",
    [INVESTMENT_STATUS.CREATED_PENDING_KYC]: "KYC",
    [INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE]: "Effecta",
    [INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE]: "Issuer",
    [INVESTMENT_STATUS.CREATED_PENDING_PAYMENT]: "Payment",
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING]: "Project Financing",
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE]: "Project Go-Live",
    [INVESTMENT_STATUS.ACTIVE]: "Active",
    [INVESTMENT_STATUS.CANCELED]: "Canceled",
    [INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT]: "Payed Out",
    [INVESTMENT_STATUS.SECONDARY_MARKET_BUY]: "Bought",
    [INVESTMENT_STATUS.SECONDARY_MARKET_SALE]: "Sold",
};

export const investmentStatusDropDown = [
    { value: INVESTMENT_STATUS.RESERVED, label: "Reserved" },
    { value: INVESTMENT_STATUS.INITIALIZED, label: "Initialized" },
    { value: INVESTMENT_STATUS.CREATED_PENDING_KYC, label: "KYC" },
    { value: INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE, label: "Effecta" },
    { value: INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE, label: "Issuer" },
    { value: INVESTMENT_STATUS.CREATED_PENDING_PAYMENT, label: "Payment" },
    { value: INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING, label: "Project Financing" },
    { value: INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE, label: "Project Go-Live" },
    { value: INVESTMENT_STATUS.ACTIVE, label: "Investment Active" },
    { value: INVESTMENT_STATUS.CANCELED, label: "Investment Canceled" },
    { value: INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT, label: "Investment Payed Out" },
];

export const investmentStatusMapping = {
    [INVESTMENT_STATUS.RESERVED]: { label: adminInvestmentMessages[INVESTMENT_STATUS.RESERVED], className: "green-indicator" },
    [INVESTMENT_STATUS.INITIALIZED]: {
        label: adminInvestmentMessages[INVESTMENT_STATUS.INITIALIZED],
        className: "orange-indicator",
    },
    [INVESTMENT_STATUS.CREATED_PENDING_KYC]: {
        label: adminInvestmentMessages[INVESTMENT_STATUS.CREATED_PENDING_KYC],
        className: "orange-indicator",
    },
    [INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE]: {
        label: adminInvestmentMessages[INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE],
        className: "orange-indicator",
    },
    [INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE]: {
        label: adminInvestmentMessages[INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE],
        className: "orange-indicator",
    },
    [INVESTMENT_STATUS.CREATED_PENDING_PAYMENT]: {
        label: adminInvestmentMessages[INVESTMENT_STATUS.CREATED_PENDING_PAYMENT],
        className: "orange-indicator",
    },
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING]: {
        label: adminInvestmentMessages[INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING],
        className: "orange-indicator",
    },
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE]: {
        label: adminInvestmentMessages[INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE],
        className: "orange-indicator",
    },
    [INVESTMENT_STATUS.ACTIVE]: { label: adminInvestmentMessages[INVESTMENT_STATUS.ACTIVE], className: "green-indicator" },
    [INVESTMENT_STATUS.CANCELED]: { label: adminInvestmentMessages[INVESTMENT_STATUS.CANCELED], className: "grey-indicator" },
    [INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT]: {
        label: adminInvestmentMessages[INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT],
        className: "grey-indicator",
    },
    [INVESTMENT_STATUS.SECONDARY_MARKET_BUY]: {
        label: adminInvestmentMessages[INVESTMENT_STATUS.SECONDARY_MARKET_BUY],
        className: "green-indicator",
    },
    [INVESTMENT_STATUS.SECONDARY_MARKET_SALE]: {
        label: adminInvestmentMessages[INVESTMENT_STATUS.SECONDARY_MARKET_SALE],
        className: "green-indicator",
    },
};

export const investmentStatuses = [
    INVESTMENT_STATUS.RESERVED,
    INVESTMENT_STATUS.INITIALIZED,
    INVESTMENT_STATUS.CREATED_PENDING_KYC,
    INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE,
    INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE,
    INVESTMENT_STATUS.CREATED_PENDING_PAYMENT,
    INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING,
    INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE,
    INVESTMENT_STATUS.ACTIVE,
    INVESTMENT_STATUS.CANCELED,
    INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT,
];

export const buyInvestmentStatuses = [
    INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT,
    INVESTMENT_STATUS.ACTIVE,
    INVESTMENT_STATUS.SECONDARY_MARKET_BUY,
];

export const INVESTMENT_COMPLETED_STATUSES = [
    INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT,
    INVESTMENT_STATUS.ACTIVE,
    INVESTMENT_STATUS.SECONDARY_MARKET_BUY,
    INVESTMENT_STATUS.SECONDARY_MARKET_SALE,
];

export const PENDING_INVESTMENT_STATUS = {
    [INVESTMENT_STATUS.RESERVED]: true,
    [INVESTMENT_STATUS.INITIALIZED]: true,
    [INVESTMENT_STATUS.CREATED_PENDING_KYC]: true,
    [INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE]: true,
    [INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE]: true,
    [INVESTMENT_STATUS.CREATED_PENDING_PAYMENT]: true,
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING]: true,
    [INVESTMENT_STATUS.CANCELED]: true,
};

export const PENDING_INVESTMENT_SUM_STATUS = {
    [INVESTMENT_STATUS.CREATED_PENDING_KYC]: true,
    [INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE]: true,
    [INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE]: true,
    [INVESTMENT_STATUS.CREATED_PENDING_PAYMENT]: true,
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING]: true,
};

export const secondaryMarketInvestmentStatuses = [
    INVESTMENT_STATUS.SECONDARY_MARKET_BUY,
    INVESTMENT_STATUS.SECONDARY_MARKET_SALE,
];

export const createAssetInvestmentStatus = [
    INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE,
    INVESTMENT_STATUS.ACTIVE,
    "Active",
    "Project Go-Live",
    "Payed Out",
];

export const QUERY_TRANSACTION_STATUS = [TRANSACTION_STATUS.PENDING, TRANSACTION_STATUS.VERIFIED];

export const adminPaymentsStatusFilter = {
    [PAYMENT_STATUS.CREATED]: "Created",
    [PAYMENT_STATUS.FAILED]: "Failed",
    [PAYMENT_STATUS.SUCCEEDED]: "Succeeded",
    [PAYMENT_STATUS.REFUNDED]: "Refunded",
    [PAYMENT_STATUS.WAITING]: "Waiting",
};

export const paymentStatusDropDown = [
    { value: PAYMENT_STATUS.CREATED, label: "Created" },
    { value: PAYMENT_STATUS.FAILED, label: "Failed" },
    { value: PAYMENT_STATUS.SUCCEEDED, label: "Succeeded" },
    { value: PAYMENT_STATUS.REFUNDED, label: "Refunded" },
    { value: PAYMENT_STATUS.WAITING, label: "Waiting" },
];

export const adminPaymentsTypesFilter = {
    [PAYMENT_TYPE.PAYIN]: "PayIn",
    [PAYMENT_TYPE.PAYOUT]: "PayOut",
    [PAYMENT_TYPE.USER_PAYIN]: "User PayIn",
    [PAYMENT_TYPE.USER_PAYOUT]: "User PayOut",
    [PAYMENT_TYPE.USER_TRANSFER_EXTERNAL]: "Transfer External",
    [PAYMENT_TYPE.USER_SECONDARY_MARKET_BUY]: "Buy",
    [PAYMENT_TYPE.USER_SECONDARY_MARKET_SALE]: "Sale",
    [PAYMENT_TYPE.USER_INVEST_EWALLET_TRANSFER]: "Invest E-Wallet Transfer",
    [PAYMENT_TYPE.USER_DIVIDEND_PAYOUT]: "Dividend PayOut",
    [PAYMENT_TYPE.USER_EXIT_PAYOUT]: "Exit PayOut",
    [PAYMENT_TYPE.USER_INVEST_WIRE_TRANSFER]: "Invest Wire Transfer",
    [PAYMENT_TYPE.USER_INVEST]: "User Invest",
    [PAYMENT_TYPE.ISSUER_INTEREST]: "Issuer Interest",
    [PAYMENT_TYPE.ISSUER_PAYOUT]: "Issuer Payout",
    [PAYMENT_TYPE.USER_TRANSFER]: "User Transfer",
    [PAYMENT_TYPE.USER_PURCHASE]: "User Purchase",
};

export const paymentTypesDropDown = [
    { value: PAYMENT_TYPE.PAYIN, label: "PayIn" },
    { value: PAYMENT_TYPE.PAYOUT, label: "PayOut" },
    { value: PAYMENT_TYPE.USER_PAYIN, label: "User PayIn" },
    { value: PAYMENT_TYPE.USER_PAYOUT, label: "User PayOut" },
    { value: PAYMENT_TYPE.USER_TRANSFER_EXTERNAL, label: "Transfer External" },
    { value: PAYMENT_TYPE.USER_SECONDARY_MARKET_BUY, label: "Buy" },
    { value: PAYMENT_TYPE.USER_SECONDARY_MARKET_SALE, label: "Sale" },
    { value: PAYMENT_TYPE.USER_INVEST_EWALLET_TRANSFER, label: "Invest E-Wallet Transfer" },
    { value: PAYMENT_TYPE.USER_DIVIDEND_PAYOUT, label: "Dividend PayOut" },
    { value: PAYMENT_TYPE.USER_EXIT_PAYOUT, label: "Exit PayOut" },
    { value: PAYMENT_TYPE.USER_INVEST_WIRE_TRANSFER, label: "Invest Wire Transfer" },
    { value: [PAYMENT_TYPE.USER_INVEST], label: "User Invest" },
    { value: [PAYMENT_TYPE.ISSUER_INTEREST], label: "Issuer Interest" },
    { value: [PAYMENT_TYPE.ISSUER_PAYOUT], label: "Issuer Payout" },
    { value: [PAYMENT_TYPE.USER_TRANSFER], label: "User Transfer" },
    { value: [PAYMENT_TYPE.USER_PURCHASE], label: "User Purchase" },
];

const stepperSliderSettings = {
    infinite: false,
    speed: 400,
    dots: false,
    fade: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    swipe: false,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                draggable: false,
            },
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 2,
                draggable: false,
            },
        },
        {
            breakpoint: 374,
            settings: {
                slidesToShow: 1,
                draggable: false,
            },
        },
    ],
};

const reviewSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    dots: true,
    fade: false,
    arrows: false,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                arrows: false,
            },
        },
        {
            breakpoint: 640,
            settings: {
                arrows: false,
            },
        },
    ],
};

const propertyGallerySliderSetting = {
    infinite: true,
    speed: 1000,
    slidesToShow: 2.5,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                arrows: false,
                slidesToShow: 1,
                autoplay: false,
                speed: 300,
            },
        },
    ],
};

const advertiseProjectSliderSetting = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    dots: true,
};

export const brokerProvisioningTypes = [
    { value: "tokenized assets", label: "Tokenized assets" },
    { value: "club deal direct sale", label: "Club deal direct sale" },
    { value: "club deal private sale", label: "Club deal private sale" },
    { value: "club deal professionals", label: "Club deal professionals" },
];

const calculatorYearsOptions = [
    { value: 1, label: "1 Jahr" },
    { value: 2, label: "2 Jahre" },
    { value: 3, label: "3 Jahre" },
    { value: 4, label: "4 Jahre" },
    { value: 5, label: "5 Jahre" },
    { value: 6, label: "6 Jahre" },
    { value: 7, label: "7 Jahre" },
    { value: 8, label: "8 Jahre" },
    { value: 9, label: "9 Jahre" },
    { value: 10, label: "10 Jahre" },
    { value: 11, label: "11 Jahre" },
    { value: 12, label: "12 Jahre" },
    { value: 13, label: "13 Jahre" },
    { value: 14, label: "14 Jahre" },
    { value: 15, label: "15 Jahre" },
    { value: 16, label: "16 Jahre" },
    { value: 17, label: "17 Jahre" },
    { value: 18, label: "18 Jahre" },
    { value: 19, label: "19 Jahre" },
    { value: 20, label: "20 Jahre" },
];

export default {
    reviewSliderSettings,
    optionsProfitabilitySliderSettings,
    propertyGallerySliderSetting,
    advertiseProjectSliderSetting,
    // allowedBlogCategories,
    calculatorYearsOptions,
    stepperSliderSettings,
};

export function CURRENT_JOB_DATA() {
    const { formatMessage } = useFormattedMessage();
    return [
        { value: "assetManager", label: formatMessage(messages.jobDataAssetManager) },
        { value: "financialAdvisor", label: formatMessage(messages.jobDataFinancialAdvisor) },
        { value: "loanBroker", label: formatMessage(messages.jobDataFinancialBroker) },
        { value: "financialIntermediary", label: formatMessage(messages.jobDataFinancialIntermediary) },
        { value: "investmentBanker", label: formatMessage(messages.jobDataInvestmentBanker) },
        { value: "auditor", label: formatMessage(messages.jobDataAuditors) },
        { value: "bankTeller", label: formatMessage(messages.jobDataBankClerk) },
        { value: "leadingFunctionControlling", label: formatMessage(messages.jobDataManagerialFunctionControlling) },
        {
            value: "leadingFinanceDepartment",
            label: formatMessage(messages.jobDataManagerialFunctionFinanceDepartment),
        },
        { value: "taxConsultant", label: formatMessage(messages.jobDataTaxConsultant) },
        { value: "commercialClerk", label: formatMessage(messages.jobDataEmployee) },
        { value: "craftsman", label: formatMessage(messages.jobDataArtisan) },
        { value: "selfEmployed", label: formatMessage(messages.selfEmployed) },
        { value: "other", label: formatMessage(messages.jobDataOtherProfession) },
    ];
}

export function HIGHEST_EDUCATION_DATA() {
    const { formatMessage } = useFormattedMessage();
    return [
        { value: "doctorateHabilitation", label: formatMessage(messages.educationDataDoctorateHabilitation) },
        { value: "academicDegree", label: formatMessage(messages.educationDataAcademicDegree) },
        { value: "commercialTraining", label: formatMessage(messages.educationDataCommercialTraining) },
        { value: "fachhochschule", label: formatMessage(messages.educationDataTechnicalCollege) },
        { value: "hauptrealvolksschule", label: formatMessage(messages.educationDataSecondaryElementary) },
        { value: "vocationalTraining", label: formatMessage(messages.educationDataVocationalTraining) },
        { value: "none", label: formatMessage(messages.educationDataNone) },
    ];
}

export function yearsOptions() {
    const { formatMessage } = useFormattedMessage();
    return [
        { value: "3", label: formatMessage(messages.upto3Years) },
        { value: "3-5", label: formatMessage(messages.threeto5Years) },
        { value: "5+", label: formatMessage(messages.over5Years) },
    ];
}
export const yearsOptionsMapping = [
    { value: "3", label: <IntlFormattedMessage id={messages.upto3Years} /> },
    { value: "3-5", label: <IntlFormattedMessage id={messages.threeto5Years} /> },
    { value: "5+", label: <IntlFormattedMessage id={messages.over5Years} /> },
];

export function investmentServicesYearsOptions() {
    const { formatMessage } = useFormattedMessage();
    return [
        { value: "none", label: formatMessage(messages.none) },
        { value: "3", label: formatMessage(messages.upto3Years) },
        { value: "3-5", label: formatMessage(messages.threeto5Years) },
        { value: "5+", label: formatMessage(messages.over5Years) },
    ];
}

export const investmentServicesYearsOptionsMapping = [
    { value: "none", label: <IntlFormattedMessage id={messages.none} /> },
    { value: "3", label: <IntlFormattedMessage id={messages.upto3Years} /> },
    { value: "3-5", label: <IntlFormattedMessage id={messages.threeto5Years} /> },
    { value: "5+", label: <IntlFormattedMessage id={messages.over5Years} /> },
];

export function transactionYearsOptions() {
    const { formatMessage } = useFormattedMessage();
    return [
        { value: "none", label: formatMessage(messages.none) },
        { value: "5", label: formatMessage(messages.until5) },
        { value: "10", label: formatMessage(messages.until10) },
        { value: "10+", label: formatMessage(messages.moreThan10) },
    ];
}

export const transactionYearsOptionsMapping = [
    { value: "none", label: <IntlFormattedMessage id={messages.none} /> },
    { value: "5", label: <IntlFormattedMessage id={messages.until5} /> },
    { value: "10", label: <IntlFormattedMessage id={messages.until10} /> },
    { value: "10+", label: <IntlFormattedMessage id={messages.moreThan10} /> },
];

export function transactionSizesOptions() {
    const { formatMessage } = useFormattedMessage();
    return [
        { value: "3.000", label: formatMessage(messages.upto3000Euros) },
        { value: "3.001-5.000", label: formatMessage(messages.threeTo5000Euros) },
        { value: "5.000-10.000", label: formatMessage(messages.fiveTo5000Euros) },
        { value: "10.000+", label: formatMessage(messages.upto10000Euros) },
    ];
}

export const transactionSizesOptionsMapping = [
    { value: "3.000", label: <IntlFormattedMessage id={messages.upto3000Euros} /> },
    { value: "3.001-5.000", label: <IntlFormattedMessage id={messages.threeTo5000Euros} /> },
    { value: "5.000-10.000", label: <IntlFormattedMessage id={messages.fiveTo5000Euros} /> },
    { value: "10.000+", label: <IntlFormattedMessage id={messages.upto10000Euros} /> },
];

export function investmentProcessSteps() {
    const { formatMessage } = useFormattedMessage();
    return [
        { step: 1, title: formatMessage(messages.person), className: "active" },
        { step: 2, title: formatMessage(messages.expertise) },
        { step: 3, title: formatMessage(messages.costs) },
        { step: 4, title: formatMessage(messages.overview) },
    ];
}

export function preferredCountries() {
    const { formatMessage } = useFormattedMessage();
    return [
        { label: formatMessage(messages.germany), value: "DE" },
        { label: formatMessage(messages.austria), value: "AT" },
        { label: formatMessage(messages.switzerland), value: "CH" },
        { label: formatMessage(messages.unitedKingdom), value: "GB" },
        { label: formatMessage(messages.luxembourg), value: "LU" },
    ];
}

export const licenseValuesToNameMapping = {
    typeBrokerLicense32KWG: "§ 32 KWG",
    typeBrokerLicense34GewO: "§ 34 GewO",
    typeBrokerLicenseNolicense: "Keine Erlaubnis",
};

export const genderMapping = {
    male: "Herr",
    female: "Frau",
    divers: "Divers",
};

export const titles = [
    {
        value: "",
        label: "Bitte wählen",
    },
    {
        value: "dr",
        label: "Dr.",
    },
    {
        value: "prof",
        label: "Prof.",
    },
    {
        value: "prof-dr",
        label: "Prof. Dr.",
    },
];

export const titlesLabelMapping = {
    dr: "Dr.",
    prof: "Prof.",
    "prof-dr": "Prof. Dr.",
};

export const genderLabelMapping = {
    male: "Herr",
    female: "Frau",
    divers: "Divers",
};

export const familyStatusesMapping = [
    {
        value: "single",
        label: <IntlFormattedMessage id={messages.msSingle} />,
    },
    {
        value: "living-together",
        label: <IntlFormattedMessage id={messages.msLivingTogether} />,
    },
    {
        value: "maried",
        label: <IntlFormattedMessage id={messages.msMaried} />,
    },
    {
        value: "partnership",
        label: <IntlFormattedMessage id={messages.msPartnership} />,
    },
    {
        value: "separated-alive",
        label: <IntlFormattedMessage id={messages.msSeparatedAlive} />,
    },
    {
        value: "divorced",
        label: <IntlFormattedMessage id={messages.msDivorced} />,
    },
    {
        value: "widowed",
        label: <IntlFormattedMessage id={messages.msWidowed} />,
    },
    {
        value: "partner-decease",
        label: <IntlFormattedMessage id={messages.msPartnerDecease} />,
    },
    {
        value: "in-divorce",
        label: <IntlFormattedMessage id={messages.msInDivorce} />,
    },
];

export const getInfoBlockData = (projectType) => {
    const customType = projectType?.includes("Club") ? "Club" : projectType;
    const infoBlockItems = {
        Club: {
            text: messages.ClubdealsInfoBlockText,
            link: web_flow_routes.CLUB_DEAL,
            isWebFlowLink: true,
        },
        [PROJECT_TYPE.ART]: {
            text: messages.ArtInfoBlockText,
            link: web_flow_routes.ART,
            isWebFlowLink: true,
        },
        [PROJECT_TYPE.FINE_WINE]: {
            text: messages.FineWineInfoBlockText,
            link: web_flow_routes.FINE_WINE,
            isWebFlowLink: true,
        },
        [PROJECT_TYPE.DIAMOND]: {
            text: messages.DiamondInfoBlockText,
            link: web_flow_routes.DIAMONDS,
            isWebFlowLink: true,
        },
        [PROJECT_TYPE.CLASSIC_CARS]: {
            text: messages.ClassicCarsInfoBlockText,
            link: web_flow_routes.CLASSIC_CARS,
            isWebFlowLink: true,
        },
        [PROJECT_TYPE.WATCHES]: {
            text: messages.WatchesInfoBlockText,
            link: web_flow_routes.WATCHES,
            isWebFlowLink: true,
        },
        [PROJECT_TYPE.PRIVATE_EQUITY]: {
            text: messages.PrivateEquityInfoBlockText,
            link: web_flow_routes.PRIVATE_EQUITY,
            isWebFlowLink: true,
        },
        [PROJECT_TYPE.VACATION_PROPERTY]: {
            text: messages.VacationPropertyInfoBlockText,
            link: web_flow_routes.PRIVATE_EQUITY,
            isWebFlowLink: true,
        },
        other: {
            text: messages.InfoBlockText,
            link: web_flow_routes.REAL_ESTATE,
            isWebFlowLink: true,
        },
    };
    return infoBlockItems[customType] || infoBlockItems["other"];
};

export function familyStatuses() {
    const { formatMessage } = useFormattedMessage();
    return [
        { value: "single", label: formatMessage(messages.msSingle) },
        { value: "living-together", label: formatMessage(messages.msLivingTogether) },
        { value: "maried", label: formatMessage(messages.msMaried) },
        { value: "partnership", label: formatMessage(messages.msPartnership) },
        { value: "separated-alive", label: formatMessage(messages.msSeparatedAlive) },
        { value: "divorced", label: formatMessage(messages.msDivorced) },
        { value: "widowed", label: formatMessage(messages.msWidowed) },
        { value: "partner-decease", label: formatMessage(messages.msPartnerDecease) },
        { value: "in-divorce", label: formatMessage(messages.msInDivorce) },
    ];
}

export const STATUS_ENUM = {
    SUCCESS: "success",
    KYC_COMPLETED_IDNOW: "StatusKYCCompletedIDnow",
    KYC_COMPLETED_POSTIDENT: "StatusKYCCompletedPostident",
    KYC_SUCCESS_IDNOW: "success (IDnow)",
    KYC_SUCCESS_POSTIDENT: "success (Postident)",
    KYC_SUCCESS_MANUAL: "success (Manual)",
    HIDE: "hide",
    SHOW: "show",
    DEACTIVATED: "deactivate",
    INITIATED: "initiated",
    PENDING: "pending",
    ERROR: "error",
    NONE: "none",
    BUY: "buy",
    SELL: "sell",
    ACCEPT_BUY: "accept-buy",
    ACCEPT_SPLIT_BUY: "accept-split-buy",
    ACCEPT_SELL: "accept-sell",
    ACCEPT_SPLIT_SELL: "accept-split-sell",
    SPLIT_BUY: "split-buy",
    SPLIT_ACCEPT_SPLIT_BUY: "split-accept-split-buy",
    SPLIT_SELL: "split-sell",
    SPLIT_ACCEPT_SPLIT_SELL: "split-sell",
    BUY_SUCCESS: "buy-success",
    SELL_SUCCESS: "sell-success",
};

export const statusLabelMapper = {
    [STATUS_ENUM.SUCCESS]: { label: "Success", value: STATUS_ENUM.SUCCESS },
    [STATUS_ENUM.PENDING]: { label: "Pending", value: STATUS_ENUM.PENDING },
    [STATUS_ENUM.INITIATED]: { label: "Initiated", value: STATUS_ENUM.INITIATED },
    [STATUS_ENUM.HIDE]: { label: "Hide", value: STATUS_ENUM.HIDE },
    [STATUS_ENUM.SHOW]: { label: "Show", value: STATUS_ENUM.SHOW },
    [STATUS_ENUM.ERROR]: { label: "Error", value: STATUS_ENUM.ERROR },
    [STATUS_ENUM.DEACTIVATED]: { label: "Deactivated", value: STATUS_ENUM.DEACTIVATED },
    [STATUS_ENUM.NONE]: { label: "None", value: STATUS_ENUM.NONE },
};

export const KYC_STATUS = [
    STATUS_ENUM.SHOW,
    STATUS_ENUM.SUCCESS,
    STATUS_ENUM.PENDING,
    STATUS_ENUM.INITIATED,
    STATUS_ENUM.HIDE,
    STATUS_ENUM.ERROR,
];

export const ADEQUACY_STATUS = [STATUS_ENUM.SHOW, STATUS_ENUM.SUCCESS, STATUS_ENUM.HIDE, STATUS_ENUM.ERROR];

export const TIPSTER_STATUS = [STATUS_ENUM.SHOW, STATUS_ENUM.SUCCESS, STATUS_ENUM.HIDE, STATUS_ENUM.ERROR];

export const MOBILE_PHONE_STATUS = [STATUS_ENUM.SHOW, STATUS_ENUM.HIDE];

export const EMAIL_STATUS = [STATUS_ENUM.SHOW, STATUS_ENUM.PENDING, STATUS_ENUM.HIDE, STATUS_ENUM.SUCCESS];

export const VIP_STATUS = [STATUS_ENUM.SHOW, STATUS_ENUM.HIDE];

export const HIDE_KYC_TILE_STATUS_STEP = [
    STATUS_ENUM.SUCCESS,
    STATUS_ENUM.PENDING,
    STATUS_ENUM.INITIATED,
    STATUS_ENUM.HIDE,
    STATUS_ENUM.KYC_COMPLETED_IDNOW,
    STATUS_ENUM.KYC_COMPLETED_POSTIDENT,
];

export const KYC_FULL_COMPLETED = [
    STATUS_ENUM.SUCCESS,
    STATUS_ENUM.HIDE,
    STATUS_ENUM.KYC_COMPLETED_IDNOW,
    STATUS_ENUM.KYC_COMPLETED_POSTIDENT,
];

export const KYC_FULL_APPROVED = [
    STATUS_ENUM.SUCCESS,
    STATUS_ENUM.KYC_COMPLETED_IDNOW,
    STATUS_ENUM.KYC_COMPLETED_POSTIDENT,
    STATUS_ENUM.KYC_SUCCESS_IDNOW,
    STATUS_ENUM.KYC_SUCCESS_POSTIDENT,
    STATUS_ENUM.KYC_SUCCESS_MANUAL,
];

export const HIDE_ADEQUACY_TILE_STATUS_STEP = [STATUS_ENUM.SUCCESS, STATUS_ENUM.HIDE];

export const ACCOUNT_PROFILE_KYC_SHOW = [STATUS_ENUM.SUCCESS, STATUS_ENUM.PENDING, STATUS_ENUM.INITIATED, STATUS_ENUM.HIDE];

export const REKYC_STATUS = {
    PENDING: "pending",
    SUCCESS: "success",
};

export const CREATED_INVESTMENT_STATUS = [
    INVESTMENT_STATUS.CREATED_PENDING_KYC,
    INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE,
    INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE,
    INVESTMENT_STATUS.CREATED_PENDING_PAYMENT,
    INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING,
    INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE,
    INVESTMENT_STATUS.ACTIVE,
    INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT,
];

export const CAPITAL_INVESTED_STATUS = [
    // INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT,
    INVESTMENT_STATUS.ACTIVE,
    INVESTMENT_STATUS.SECONDARY_MARKET_BUY,
];

export const IDENTIFICATION_FORM_INPUTS = [
    "Birthday",
    "IsUSTaxation",
    "IsEffectaCustomerInformationConfirmed",
    "Nationality",
    "BirthCountry",
    "Birthplace",
    "IsPEP",
];

export const PERSONAL_FORM_INPUTS = [
    "Email",
    "TypeGender",
    "TypeTitle",
    "FirstName",
    "LastName",
    "Number",
    "DialCode",
    "CountryCode",
    "IBAN",
    "BIC",
    "Owner",
    "Street",
    "Streetnumber",
    "Zipcode",
    "StreetAddition",
    "City",
    "Country",
    "MaritalStatus",
    "TaxID",
];

export const USER_KYC_DETAILS_INPUT = [...IDENTIFICATION_FORM_INPUTS, ...PERSONAL_FORM_INPUTS];

export const provisionStatusDropDown = [
    { value: "", label: "Bitte wählen" },
    { value: PROVISION_STATUS.INITIALIZED, label: "Initialized" },
    { value: PROVISION_STATUS.PENDING, label: "Pending" },
    { value: PROVISION_STATUS.CLEARED, label: "Cleared" },
    { value: PROVISION_STATUS.CANCELED, label: "Canceled" },
    { value: PROVISION_STATUS.PAYED, label: "Payed" },
];

export const provisioningStatusLabelMapping = {
    [PROVISION_STATUS.INITIALIZED]: "Initialized",
    [PROVISION_STATUS.PENDING]: "Pending",
    [PROVISION_STATUS.CLEARED]: "Cleared",
    [PROVISION_STATUS.CANCELED]: "Canceled",
    [PROVISION_STATUS.PAYED]: "Payed",
};

export const BROKER_ONBOARDING_STATUS = {
    REGISTRATION_COMPLETED: "brokerOnboardingRegistrationCompleted",
    DATA_INPUT_PENDING: "brokerOnboardingDataInputPending",
    DOCUMENT_UPLOAD_PENDING: "brokerOnboardingDocumentUploadPending",
    VERIFICATION_PENDING: "brokerOnboardingVerificationPending",
    VERIFICATION_SUCCESS: "brokerOnboardingVerificationSuccess",
    VERIFICATION_FAILURE: "brokerOnboardingVerificationFailure",
    COOPERATION_AGREEMENT: "brokerOnboardingCooperationAgreementPending",
    TIPSTER_INFORMATION_CONFIRMATION_PENDING: "brokerOnboardingTipsterInformationConfirmationPending",
    FINAL_APPROVAL_PENDING: "brokerOnboardingFinalApprovalPending",
    FINAL_APPROVAL_FAILURE: "brokerOnboardingFinalApprovalFailure",
    FINAL_APPROVAL_SUCCESS: "brokerOnboardingFinalApprovalSuccess",
    SUCCESS: "brokerOnboardingSuccess",
};

export const SHOW_BACK_BROKER_ONBOARDING_STATUS = [
    "brokerOnboardingDataInputPending",
    "brokerOnboardingDocumentUploadPending",
    // "brokerOnboardingVerificationPending",
    // "brokerOnboardingVerificationSuccess",
    "brokerOnboardingVerificationFailure",
    "brokerOnboardingCooperationAgreementPending",
    "brokerOnboardingTipsterInformationConfirmationPending",
    // "brokerOnboardingFinalApprovalPending",
    "brokerOnboardingFinalApprovalFailure",
];

export const BROKER_ONBOARDING_STATUS_FANCY_NAMES = {
    brokerOnboardingRegistrationCompleted: "Registration Completed",
    brokerOnboardingDataInputPending: "Data Input Pending",
    brokerOnboardingDocumentUploadPending: "Document Upload Pending",
    brokerOnboardingVerificationPending: "Verification Pending",
    brokerOnboardingVerificationSuccess: "Verification Success",
    brokerOnboardingVerificationFailure: "Verification Failure",
    brokerOnboardingCooperationAgreementPending: "Cooperation Agreement Pending",
    brokerOnboardingTipsterInformationConfirmationPending: "Tipster Information Confirmation Pending",
    brokerOnboardingFinalApprovalPending: "Final Approval Pending",
    brokerOnboardingFinalApprovalFailure: "Final Approval Failure",
    brokerOnboardingFinalApprovalSuccess: "Final Approval Success",
    brokerOnboardingSuccess: "Success",
};

export const BROKER_ONBOARDING_ACCOUNT = {
    ACTIVE: "brokerAccountActive",
    PENDING: "brokerAccountOnboardingPending",
    BLOCKED: "brokerAccountBlocked",
};

export const SECONDARY_MARKET_PROJECT_STATUS = [
    PROJECT_STATUS.MARKETPLACE,
    PROJECT_STATUS.MARKETPLACE_PAUSED,
    PROJECT_STATUS.MARKETPLACE_REVIEW,
];

export const CLUB_DEAL_STATUS = {
    REGISTRATION_COMPLETED: "clubDealOnboardingRegistrationCompleted",
    PERSONAL_INFORMATION: "clubDealPersonalInformationPending",
    WEALTH_PORTFOLIO: "clubDealWealthPortfolioPending",
    ALTERNATIVE_INVESTMENTS: "clubDealAlternativeInvestmentsPending",
    VERIFICATION_PENDING: "clubDealOnboardingVerificationPending",
    VERIFICATION_SUCCESS: "clubDealOnboardingVerificationSuccess",
    VERIFICATION_FAILURE: "clubDealOnboardingVerificationFailure",
    COMPLETED: "clubDealOnboardingCompleted",
};

export const SHOW_BACK_CLUB_DEAL_STATUS = [
    "clubDealPersonalInformationPending",
    "clubDealWealthPortfolioPending",
    "clubDealAlternativeInvestmentsPending",
    // "clubDealOnboardingVerificationPending",
    // "clubDealOnboardingVerificationSuccess",
    "clubDealOnboardingVerificationFailure",
];

export const CLUB_DEAL_STATUS_FANCY_NAME = {
    [CLUB_DEAL_STATUS.REGISTRATION_COMPLETED]: "Registration Completed",
    [CLUB_DEAL_STATUS.PERSONAL_INFORMATION]: "Personal Information Pending",
    [CLUB_DEAL_STATUS.WEALTH_PORTFOLIO]: "Wealth Portfolio Pending",
    [CLUB_DEAL_STATUS.ALTERNATIVE_INVESTMENTS]: "Alternative Investments Pending",
    [CLUB_DEAL_STATUS.VERIFICATION_PENDING]: "Verification Pending",
    [CLUB_DEAL_STATUS.VERIFICATION_SUCCESS]: "Verification Success",
    [CLUB_DEAL_STATUS.VERIFICATION_FAILURE]: "Verification Failure",
    [CLUB_DEAL_STATUS.COMPLETED]: "Completed",
};

export const BROKER_DOCUMENTS_NAME = {
    identification: "LinkBrokerDocumentIdentification",
    businessLicenses: "LinkBrokerDocumentBusinessLicense",
    cooperationAgreements: "LinkBrokerDocumentCooperationAgreement",
    commercialExtract: "LinkBrokerDocumentCommercialRegisterExtract",
};

export const preferredLanguageOptions = [
    {
        value: "de",
        label: "DE",
    },
    {
        value: "en",
        label: "EN",
    },
];

export const deleteUserStatus = {
    NOT_INVESTED: "notInvested",
    INVESTED: "invested",
    USER_JUST_INVESTED: "userJustInvested",
    USER_HAS_ACTIVE_ORDERS: "userHasActiveOrders",
    USER_HAS_MANGOPAY_BALANCE: "userHasMangoPayBalance",
};

export const PHONE_TYPES = {
    TypePhoneMobile: "typePhoneMobile",
    TypePhoneLandline: "typePhoneLandline",
    TypePhoneTwilio: "typePhoneTwilio",
};

export const ADDRESS_TYPES = {
    TypeAddressPrimary: "typeAddressPrimary",
};

export const BANK_ACCOUNT_TYPES = {
    TypeBankAccountUserPrimary: "typeBankAccountUserPrimary",
    TypeBankAccountBusinessPrimary: "typeBankAccountBusinessPrimary",
};

export const paymentStatusMapping = () => {
    const { formatMessage } = useFormattedMessage();
    return {
        pending: formatMessage(messages.paymentStatusPending),
        completed: formatMessage(messages.paymentStatusCompleted),
        created: formatMessage(messages.paymentStatusCreated),
        failed: formatMessage(messages.paymentStatusFailed),
        succeeded: formatMessage(messages.paymentStatusSucceeded),
        refunded: formatMessage(messages.paymentStatusRefunded),
        waiting: formatMessage(messages.paymentStatusWaiting),
        TRANSFER_NORMAL_FAILED: formatMessage(messages.paymentStatusTransferFailed),
        PAYOUT_NORMAL_SUCCEEDED: formatMessage(messages.paymentStatusPayoutNormalSucceeded),
    };
};

export const filterPaymentStatusMapping = {
    created: false,
    failed: false,
    succeeded: false,
    refunded: false,
};
export const filterPaymentTypesMapping = {
    userPayIn: false,
    userPayOut: false,
    // userTransfer: false,
    userInvestEwalletTransfer: false,
    userTransferExternal: false,
    userSecondaryMarketBuy: false,
    userSecondaryMarketSale: false,
    userDividendPayOut: false,
    userExitPayOut: false,
};

export const paymentTypeMapping = () => {
    const { formatMessage } = useFormattedMessage();
    return {
        transfer: formatMessage(messages.paymentTypeTransfer),
        payIn: formatMessage(messages.paymentTypePayIn),
        payOut: formatMessage(messages.paymentTypePayOut),
        userTransferExternal: formatMessage(messages.paymentTypeUserTransferExternal),
        userTransfer: formatMessage(messages.paymentTypeUserTransfer),
        userPayIn: formatMessage(messages.paymentTypePayIn),
        userPayOut: formatMessage(messages.paymentTypePayOut),
        userInvestEwalletTransfer: formatMessage(messages.paymentTypeUserInvestEwalletTransfer),
        userSecondaryMarketSale: formatMessage(messages.paymentTypeSell),
        userSecondaryMarketBuy: formatMessage(messages.paymentTypeBuy),
        userDividendPayOut: formatMessage(messages.paymentTypeDividendPayOut),
        userExitPayOut: formatMessage(messages.paymentTypeExitPayOut),
    };
};

export const EMAIL_CONFIRMED_PROVIDER_STATUS = {
    FACEBOOK: "registeredWithFacebook",
    LINKEDIN: "registeredWithLinkedIn",
    APPLE: "registeredWithApple",
    GOOGLE: "registeredWithGoogle",
};

export const USER_TYPES = {
    UserTypeLead: "Lead",
    UserTypePerson: "Person",
    UserTypeInstitution: "institutional",
    UserTypeContact: "Contact",
    UserTypeBusiness: "Business",
};

export const iconClassMapping = {
    callNote: "fas fa-edit",
    sms: "far fa-comment-alt",
    magicWand: "fas fa-magic",
    workflow: "work-flow",
    callLogs: "fas fa-phone",
    mail: "far fa-envelope",
    email: "fas fa-envelope-square",
};

export const ORDER_TYPE = {
    BUY: "buy",
    SELL: "sell",
    ACCEPT_BUY: "accept-buy",
    ACCEPT_SPLIT_BUY: "accept-split-buy",
    ACCEPT_SELL: "accept-sell",
    ACCEPT_SPLIT_SELL: "accept-split-sell",
};

export const BUY_ORDER_TYPE = [STATUS_ENUM.BUY, STATUS_ENUM.ACCEPT_BUY, STATUS_ENUM.ACCEPT_SPLIT_BUY];

export const SPLIT_BUY_ORDER_TYPE = [STATUS_ENUM.SPLIT_BUY, STATUS_ENUM.SPLIT_ACCEPT_SPLIT_BUY];

export const ACCEPT_ORDER_TYPE = [
    STATUS_ENUM.ACCEPT_BUY,
    STATUS_ENUM.ACCEPT_SPLIT_BUY,
    STATUS_ENUM.ACCEPT_SELL,
    STATUS_ENUM.ACCEPT_SPLIT_SELL,
];

export const ACCEPT_SPLIT_ORDER_TYPE = [STATUS_ENUM.ACCEPT_SPLIT_BUY, STATUS_ENUM.ACCEPT_SPLIT_SELL];

export const EDIT_ORDER_STATUS = [
    // ORDER_STATUS.EXPIRED,
    ORDER_STATUS.DEACTIVATE,
];

export const NOT_DELETE_USER_ACCOUNT_ORDER_STATUS = {
    [ORDER_STATUS.ACTIVE]: true,
    [ORDER_STATUS.CONFIRMED]: true,
};

export const ALL_BUY_ORDER_STATUS = [
    STATUS_ENUM.BUY,
    STATUS_ENUM.ACCEPT_BUY,
    STATUS_ENUM.ACCEPT_SPLIT_BUY,
    STATUS_ENUM.SPLIT_BUY,
    STATUS_ENUM.SPLIT_ACCEPT_SPLIT_BUY,
];

export const ALL_SELL_ORDER_STATUS = [
    STATUS_ENUM.SELL,
    STATUS_ENUM.ACCEPT_SELL,
    STATUS_ENUM.ACCEPT_SPLIT_SELL,
    STATUS_ENUM.SPLIT_SELL,
    STATUS_ENUM.SPLIT_ACCEPT_SPLIT_SELL,
];

export const DELETE_ORDER_STATUS = [
    ORDER_STATUS.EXPIRED,
    ORDER_STATUS.FAILED,
    ORDER_STATUS.DEACTIVATE,
    ORDER_STATUS.CANCELED,
    ORDER_STATUS.SUCCESS,
];

export const DEACTIVATE_ORDER_STATUS = [
    ORDER_STATUS.EXPIRED,
    ORDER_STATUS.FAILED,
    ORDER_STATUS.DEACTIVATE,
    ORDER_STATUS.CANCELED,
];

export const ACTIVE_ORDER_STATUS = [
    ORDER_STATUS.ACTIVE,
    // ORDER_STATUS.SUCCESS, //hide success order
    ORDER_STATUS.PROGRESS,
];

export const SHOW_MESSAGE_ORDER_STATUS = [ORDER_STATUS.FAILED, ORDER_STATUS.CANCELED, ORDER_STATUS.PROGRESS];

export const ORDER_STATUS_TAGS = [
    ORDER_STATUS.FAILED,
    ORDER_STATUS.CANCELED,
    ORDER_STATUS.PROGRESS,
    ORDER_STATUS.EXPIRED,
    ORDER_STATUS.DEACTIVATE,
];

export const tagText = {
    [ORDER_STATUS.DEACTIVATE]: (
        <>
            <DeactivatedTag className="mr-2" style={{ width: "14px", height: "10px" }} />{" "}
            <IntlFormattedMessage id={messages.orderStatusDeactivate} />
        </>
    ),
    [ORDER_STATUS.CANCELED]: (
        <>
            <CancelTag className="mr-2" /> <IntlFormattedMessage id={messages.statusCanceled} />
        </>
    ),
    [ORDER_STATUS.EXPIRED]: (
        <>
            <ExpiredTag className="mr-2" /> <IntlFormattedMessage id={messages.orderStatusExpired} />
        </>
    ),
    [ORDER_STATUS.FAILED]: (
        <>
            <FailedTag className="mr-2" /> <IntlFormattedMessage id={messages.orderStatusFailed} />
        </>
    ),
    [ORDER_STATUS.PROGRESS]: (
        <>
            <ProgressTag className="mr-2" /> <IntlFormattedMessage id={messages.orderStatusProgress} />
        </>
    ),
    [ORDER_TYPE.BUY]: <IntlFormattedMessage id={messages.buy} />,
    [ORDER_TYPE.ACCEPT_BUY]: <IntlFormattedMessage id={messages.buy} />,
    [ORDER_TYPE.ACCEPT_SPLIT_BUY]: <IntlFormattedMessage id={messages.toSplitBuy} />,
    [ORDER_TYPE.SELL]: <IntlFormattedMessage id={messages.toSell} />,
    [ORDER_TYPE.ACCEPT_SELL]: <IntlFormattedMessage id={messages.toSell} />,
    [ORDER_TYPE.ACCEPT_SPLIT_SELL]: <IntlFormattedMessage id={messages.toSplitSell} />,
    [STATUS_ENUM.SPLIT_BUY]: <IntlFormattedMessage id={messages.toSplitBuy} />,
    [STATUS_ENUM.SPLIT_ACCEPT_SPLIT_BUY]: <IntlFormattedMessage id={messages.toSplitBuy} />,
    [STATUS_ENUM.SPLIT_SELL]: <IntlFormattedMessage id={messages.toSplitSell} />,
    [STATUS_ENUM.SPLIT_ACCEPT_SPLIT_SELL]: <IntlFormattedMessage id={messages.toSplitSell} />,
    [INVESTMENT_STATUS.SECONDARY_MARKET_BUY]: (
        <>
            <i className="text-10 fa fa-check mr-2" aria-hidden="true" /> <IntlFormattedMessage id={messages.statusBought} />
        </>
    ),
    [INVESTMENT_STATUS.SECONDARY_MARKET_SALE]: (
        <>
            <i className="text-10 fa fa-check mr-2" aria-hidden="true" /> <IntlFormattedMessage id={messages.statusSold} />
        </>
    ),
    [INVESTMENT_STATUS.ACTIVE]: (
        <>
            <i className="text-10 fa fa-check mr-2" aria-hidden="true" />
            <IntlFormattedMessage id={messages.statusCompleted} />
        </>
    ),
    [INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT]: (
        <>
            <i className="text-10 fa fa-check mr-2" aria-hidden="true" />
            <IntlFormattedMessage id={messages.statusRepaid} />
        </>
    ),
    [INVESTMENT_STATUS.CANCELED]: (
        <>
            <CancelTag className="mr-2" />
            <IntlFormattedMessage id={messages.statusCanceled} />
        </>
    ),
    [STATUS_ENUM.SELL_SUCCESS]: (
        <>
            <i className="text-10 fa fa-check mr-2" aria-hidden="true" /> <IntlFormattedMessage id={messages.statusSold} />
        </>
    ),
    [STATUS_ENUM.BUY_SUCCESS]: (
        <>
            <i className="text-10 fa fa-check mr-2" aria-hidden="true" /> <IntlFormattedMessage id={messages.statusBought} />
        </>
    ),
};

export const btnClassName = {
    [ORDER_STATUS.DEACTIVATE]: "deactivated-tag",
    [ORDER_STATUS.EXPIRED]: "deactivated-tag",
    [ORDER_STATUS.FAILED]: "deactivated-tag",
    [ORDER_STATUS.PROGRESS]: "deactivated-tag",
    [ORDER_STATUS.CANCELED]: "deactivated-tag",
    [ORDER_TYPE.BUY]: "buy-tag",
    [STATUS_ENUM.SPLIT_BUY]: "buy-tag",
    [STATUS_ENUM.SPLIT_ACCEPT_SPLIT_BUY]: "buy-tag",
    [ORDER_TYPE.ACCEPT_BUY]: "buy-tag",
    [ORDER_TYPE.ACCEPT_SPLIT_BUY]: "buy-tag",
    [STATUS_ENUM.SPLIT_SELL]: "sell-tag",
    [STATUS_ENUM.SPLIT_ACCEPT_SPLIT_SELL]: "sell-tag",
    [ORDER_TYPE.SELL]: "sell-tag",
    [ORDER_TYPE.ACCEPT_SELL]: "sell-tag",
    [ORDER_TYPE.ACCEPT_SPLIT_SELL]: "sell-tag",
    [INVESTMENT_STATUS.SECONDARY_MARKET_BUY]: "buy-tag",
    [INVESTMENT_STATUS.SECONDARY_MARKET_SALE]: "sell-tag",
    [INVESTMENT_STATUS.ACTIVE]: "deactivated-tag",
    [INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT]: "buy-tag",
    [INVESTMENT_STATUS.CANCELED]: "deactivated-tag",
    [TILE_STATUS.INITIATED]: "investment-tag-danger",
    [TILE_STATUS.PENDING]: "investment-tag-danger",
    [INVESTMENT_STATUS.RESERVED]: "investment-tag-danger",
    [INVESTMENT_STATUS.INITIALIZED]: "investment-tag-danger",
    [INVESTMENT_STATUS.CREATED_PENDING_KYC]: "investment-tag-danger",
    [INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE]: "investment-tag-danger",
    [INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE]: "investment-tag-danger",
    [INVESTMENT_STATUS.CREATED_PENDING_PAYMENT]: "investment-tag-danger",
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING]: "investment-tag-primary",
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE]: "investment-tag-primary",
    [STATUS_ENUM.BUY_SUCCESS]: "buy-tag",
    [STATUS_ENUM.SELL_SUCCESS]: "sell-tag",
};

export const orderStatusFancyNames = () => {
    const { formatMessage } = useFormattedMessage();
    return {
        canceled: formatMessage(messages.statusCanceled),
        failed: formatMessage(messages.orderStatusFailed),
        progress: formatMessage(messages.orderStatusProgress),
        success: formatMessage(messages.orderStatusCompleted), //old orderStatusSuccess
        active: formatMessage(messages.orderStatusActive),
        deactivate: formatMessage(messages.orderStatusDeactivate),
        initiated: formatMessage(messages.orderStatusInitiated),
        expired: formatMessage(messages.orderStatusExpired),
    };
};

export const AUTH_USER_ERROR_MSG = {
    DENIED: "access_denied",
    BLOCKED: "blocked_user",
    BLOCKED_MSG: "user is blocked",
    WRONG_CRED: "Wrong email or password.",
    TO_MANY_ATTEMPT: "too_many_attempts",
};

export const API_RESPONSE_CODE = {
    UNAUTHORIZED: 401,
    SUCCESS: 200,
    FAILED: 500,
};

export const API_UNAUTHORIZED_MSG = {
    DGRAPH_UNAUTHORIZED: "twirp error internal: unauthorized",
    GRAPHQL_UNAUTHORIZED: "unauthorized",
    INVALID_TOKEN: "invalid_token",
    AUTH_LOGIN_REQUIRED: "login_required",
    JWT_EXPIRED: "Jwt is expired",
};

export const UploadPostidentMessages = {
    IdentificationAlreadyCompleted: "identification_already_completed",
    IdentificationPending: "identification_pending",
    IdentificationUpdated: "identification_updated",
    IdentificationCannotUpdate: "cannot_update_identification",
    IdentificationAddressChanged: "identification_address_changed",
    StatusReKYCPending: "pending",
};

export const UploadPostidentErrors = {
    IdentificationNotFoundUser: "Couldn't_find_any_user_for_reference_number",
    IdentificationNotFoundPDF: "Couldn't_find_pdf",
    IdentificationNotFoundUserData: "Couldn't_find_any_user_identification_data",
    IdentificationFoundMoreUser: "identification_found_more_users",
};

export const Twilio_Directions = {
    outboundDial: "outbound-dial",
};

export const Twilio_Directions_Mapping = {
    [Twilio_Directions.outboundDial]: "outGoing",
};

export const Twilio_Call_Statuses = {
    busy: "busy",
    canceled: "canceled",
    completed: "completed",
    failed: "failed",
    noAnswer: "no-answer",
    queued: "queued",
    initiated: "initiated",
    ringing: "ringing",
    inProgress: "in-progress",
};

export const CUSTOM_EVENTS = {
    TWILIO: {
        DIAL_CALL: "eventDialCall",
        DISCONNECT_DIALED_CALL: "eventDisconnectDialedCall",
        CALL_CANCELED: "callCanceled",
        CALL_CONNECTED: "callConnected",
        DIALED: "dialed",
    },
};

export const RESPONSE_ERRORS = {
    TOO_MANY_REQUEST: "You have reached maximum request limit.",
    EMAIL_NOT_FOUND_IN_TOKEN: "email is missing in jwt token",
    SELF_DECLARATION_ERROR: "error_self_declaration_insufficient",
};

export const ACCOUNT_HASH_VARIABLES = {
    ACCOUNT_MAIN: "account",
    ACCOUNT_KYC: "accountKYC",
    ACCOUNT_EWALLET: "accountEwallet",
    ACCOUNT_SM_ONBOARDING: "accountSmOnboarding",
    ACCOUNT_MESSAGE: "accountMessage",
    ACCOUNT_SETTING: "accountSetting",
    ACCOUNT_REFERRAL: "accountReferral",
    ACCOUNT_CONTACT: "accountContact",
    ACCOUNT_PROFILE: "accountProfile",
};

export const USER_ROLES_LIST = [
    "retail",
    "admin",
    "vip",
    "broker",
    "club",
    "tenant",
    "issuer",
    "tester",
    "umbrella",
    "cavalry",
    "steubing",
];

export const getTenantUserRoles = (tenantsList) => {
    const userTenantRoles = [];
    tenantsList.map((tenant) =>
        USER_ROLES_LIST.map((role) => {
            if (tenant !== TENANTS_LIST.FINEXITY && (role === "admin" || role === "umbrella")) return null;
            userTenantRoles.push(`${tenant}.${role}`);
        })
    );
    return userTenantRoles;
};

export const MANGOPAY_KYC_LEVEL = {
    LIGHT: "LIGHT",
    REGULAR: "REGULAR",
    RETAKE: "RETAKE",
};

export const ACTIVITIES_TYPE = {
    Call: "ActivityCall",
    Email: "ActivityEmail",
    Sms: "ActivitySMS",
};

export const WALLET_TYPE = {
    PAY_IN_WALLET: "PayInWallet",
    PAY_OUT_WALLET: "PayOutWallet",
    BLOCKED_WALLET: "BlockedWallet",
    ESCROW_WALLET: "EscrowWallet",
};

export const PROJECT_TYPE = {
    FINE_WINE: "Fine Wine",
    ART: "Kunst",
    CLASSIC_CARS: "Classic Cars",
    DIAMOND: "Diamanten",
    REAL_ESTATE: "Wohnen",
    WATCHES: "Watches",
    PRIVATE_EQUITY: "Private Equity",
    FERIENIMMOBILIE: "Ferienimmobilie",
    SERVICED_APARTMENT: "Serviced Apartment",
    CL_PUBLIC_PLACEMENT: "Club Deal - Public Placement",
    CL_DIRECT_SELECT: "Club Deal - Direct Select",
    VACATION_PROPERTY: "Vacation Property",
    STRING_INSTRUMENTS: "String Instruments",
    RENEWABLES: "Renewables",
    SUSTAINABILITY: "Sustainability",
    HOTEL: "Hotel",
    HEALTHCARE: "Healthcare",
    COMMERCIAL: "Commercial",
    CL_PRIVATE_PLACEMENT: "Club Deal - Private Placement",
    CL_PUBLIC_PLACEMENT_5k: "Club Deal - Public Placement - 5k",
    CL_PUBLIC_PLACEMENT_15k: "Club Deal - Public Placement - 15k",
    CL_PUBLIC_PLACEMENT_25k: "Club Deal - Public Placement - 25k",
    CL_PUBLIC_PLACEMENT_50k: "Club Deal - Public Placement - 50k",
    CL_PUBLIC_PLACEMENT_100k: "Club Deal - Public Placement - 100k",
};

export const PROJECT_CATEGORY = {
    WINE: "Wine",
    CARS: "Cars",
    APARTMENT: "Apartment",
};

export const FederalStatesGermanyOptions = [
    { label: "Baden-Württemberg", value: "FederalStateBaden" },
    { label: "Bayern", value: "FederalStateBayern" },
    { label: "Berlin", value: "FederalStateBerlin" },
    { label: "Brandenburg", value: "FederalStateBrandenburg" },
    { label: "Bremen", value: "FederalStateHansestadtBremen" },
    { label: "Hamburg", value: "FederalStateHansestadtHamburg" },
    { label: "Hessen", value: "FederalStateHessen" },
    { label: "Mecklenburg-Vorpommern", value: "FederalStateMecklenburgVorpommern" },
    { label: "Niedersachsen", value: "FederalStateNiedersachen" },
    { label: "Nordrhein-Westfalen", value: "FederalStateNordrheinWestfalen" },
    { label: "Rheinland-Pfalz", value: "FederalStateRheinlandPfalz" },
    { label: "Saarland", value: "FederalStateSaarland" },
    { label: "Sachsen", value: "FederalStateSachsen" },
    { label: "Sachsen-Anhalt", value: "FederalStateSachsenAnhalt" },
    { label: "Schleswig-Holstein", value: "FederalStateSchleswigHolstein" },
    { label: "Thüringen", value: "FederalStateThueringen" },
];

export const DOCS_PATH = {
    FINEXITY_AGB: "/docs/FINEXITY_AGB.pdf",
    FINEXITY_AGB_PARTNER_PROGRAM: "/docs/FINEXITY_AGB_Partnerprogramm.pdf",
    FINEXITY_EFFECTA_CUSTOMER_INFORMATION: "/docs/general/Effecta/Effecta_Kundeninformationen.pdf",
    FINEXITY_TIPSTER_INFORMATION: "/docs/FINEXITY_Tippgeberinformationen.pdf",
    FINEXITY_AG_DGD_TRANSPARENCY: "/docs/FINEXITY_AG_DGD_Transparency_Document.pdf",
    FINEXITY_SAFE_KEEPING_AGB: "/docs/FINEXITY_Verwahrung_AGB.pdf",
    FINEXITY_ART_GUIDE_2021: "/docs/general/FINEXITY/2022/guides/FINEXITY_Art-Guide_2022.pdf",
    FINEXITY_FINE_WINE_GUIDE_2021: "/docs/general/FINEXITY/2022/guides/FINEXITY_Fine-Wine-Guide_2022.pdf",
    FINEXITY_REAL_ESTATE_GUIDE_2021: "/docs/general/FINEXITY/2022/guides/FINEXITY_Real-Estate-Guide_2022.pdf",
    FINEXITY_BLOCKCHAIN_GUIDE: "/docs/FINEXITY_Blockchain_Guide.pdf",
    FINEXITY_DIGITAL_REVOLUTION_FINANCIAL_SERVICES: "/docs/FINEXITY_Digital_Revolution_of_Financial_Services.pdf",
    BROKER_FINEXITY_TIPSTER_INFORMATION: "/docs/broker/FINEXITY_Tippgeberinformationen.pdf",
    BROKER_FINEXITY_COMPANY_BROCHURE: "/docs/broker/advertising-material/FINEXITY_Unternehmensbroschuere.pdf",
    BROKER_FINEXITY_HIGH_RISE_OVRERVIEW: "/docs/broker/advertising-material/FINEXITY_High-Rise_Projektuebersicht.pdf",
    BROKER_FINEXITY_EXPOSE_FOUR_TREES: "/docs/broker/FINEXITY_Expose_FourTrees.pdf",
    FINEXITY_CLASSIC_CARS_GUIDE_2021: "/docs/general/FINEXITY/2022/guides/FINEXITY_Classic-Cars-Guide_2022.pdf",
    FINEXITY_DIAMANTEN_GUIDE_2021: "/docs/general/FINEXITY/2022/guides/FINEXITY_Diamond-Guide_2022.pdf",
    FINEXITY_WATCHES_GUIDE_2021: "/docs/general/FINEXITY/2022/guides/FINEXITY_Watches-Guide_2022.pdf",
    FINEXITY_VIOLINS_GUIDE_2022: "/docs/general/FINEXITY/2022/guides/FINEXITY_Violins-Guide_2022.pdf",
    FINEXITY_Private_Equity_GUIDE_2022: "/docs/general/FINEXITY/2022/guides/FINEXITY_Private-Equity-Guide_2022.pdf",
    FINEXITY_INFLATION_GUIDE_2022: "/docs/general/FINEXITY/2022/guides/FINEXITY_Inflation-Guide_2022.pdf",
};

export const Activity_API_Limit = 10;

export const TENANTS_LIST = {
    FINEXITY: "finexity",
    ASSETTA: "assetta",
    SPARKASSE: "sparkasse",
    VOLKSBANK: "volksbank",
    SACHWERTINVEST: "sachwertinvest",
    WINVESTA: "winvesta",
    WATCHVEST: "watchvest",
    TOKEN1: "token1",
    FAIRZINSUNG: "fairzinsung",
    DEMO: "demo",
    GOLDENCIRCLE: "golden-circle",
    ANLAGENWERT: "anlagenwert-hamburg",
    VISION: "vision",
    REVNEX: "revnex",
    FINEXITY_HEALTHCARE: "finexity-healthcare",
    IHRE_VB: "ihre-vb",
};

export const NOT_ALLOWED_TENANTS_FOR_SOCIAL_LOGIN = [TENANTS_LIST.SACHWERTINVEST];

export const getProjectTypeTranslatedLabels = () => {
    const { formatMessage } = useFormattedMessage();
    return {
        "Fine Wine": formatMessage(messages.projectTypeFineWine),
        Diamanten: formatMessage(messages.projectTypeDiamonds),
        Kunst: formatMessage(messages.projectTypeArt),
        Wohnen: formatMessage(messages.projectTypeResidential),
        Commercial: formatMessage(messages.projectTypeCommercial),
        "Classic Cars": formatMessage(messages.projectTypeClassicCars),
        Ferienimmobilie: formatMessage(messages.projectTypeVacationProperty),
        "Serviced Apartment": formatMessage(messages.projectTypeServicedApartment),
        "Tokenisierter AIF": formatMessage(messages.projectTypeTokenizedAIF),
        "Diverse Nutzungsarten": formatMessage(messages.projectTypeVariousTypesOfUse),
        Watches: formatMessage(messages.projectTypeWatches),
        "Private Equity": formatMessage(messages.projectTypePrivateEquity),
        "Venture Capital": formatMessage(messages.projectTypeVentureCapital),
        Renewables: formatMessage(messages.projectTypeRenewables),
        Sustainability: formatMessage(messages.projectTypeSustainability),
        "String Instruments": formatMessage(messages.projectTypeStringInstruments),
        Hotel: formatMessage(messages.projectTypeHotel),
        Healthcare: formatMessage(messages.projectTypeHealthcare),
        "Club Deal - Public Placement": formatMessage(messages.projectTypeClubDealPublicPlacement),
        "Club Deal - Public Placement - 5k": formatMessage(messages.projectTypeClubDealPublicPlacement5k),
        "Club Deal - Public Placement - 15k": formatMessage(messages.projectTypeClubDealPublicPlacement15k),
        "Club Deal - Public Placement - 25k": formatMessage(messages.projectTypeClubDealPublicPlacement25k),
        "Club Deal - Public Placement - 50k": formatMessage(messages.projectTypeClubDealPublicPlacement50k),
        "Club Deal - Public Placement - 100k": formatMessage(messages.projectTypeClubDealPublicPlacement100k),
        "Club Deal - Private Placement": formatMessage(messages.projectTypeClubDealPrivatePlacement),
        "Club Deal - Direct Select": formatMessage(messages.projectTypeClubDealDirectSelect),
    };
};

export const issuerCompanyLegalFormOptions = [
    { value: "Einzelunternehmen", label: <IntlFormattedMessage id={messages.legalFormOption1} /> },
    { value: "GbR", label: <IntlFormattedMessage id={messages.legalFormOption2} /> },
    { value: "OHG", label: <IntlFormattedMessage id={messages.legalFormOption3} /> },
    { value: "KG", label: <IntlFormattedMessage id={messages.legalFormOption4} /> },
    { value: "GmbH & Co.KG", label: <IntlFormattedMessage id={messages.legalFormOption5} /> },
    { value: "AG", label: <IntlFormattedMessage id={messages.legalFormOption6} /> },
    { value: "GmbH", label: <IntlFormattedMessage id={messages.legalFormOption7} /> },
    { value: "KGaA", label: <IntlFormattedMessage id={messages.legalFormOption8} /> },
    { value: "Genossenschaft", label: <IntlFormattedMessage id={messages.legalFormOption9} /> },
    { value: "Others", label: <IntlFormattedMessage id={messages.legalFormOption10} /> },
];

export const getI18nRoles = () => {
    const { formatMessage } = useFormattedMessage();
    return {
        club: formatMessage(messages.rolesClub),
        admin: formatMessage(messages.rolesAdmin),
        vip: formatMessage(messages.rolesVip),
        broker: formatMessage(messages.rolesBroker),
        tenant: formatMessage(messages.rolesTenant),
        retail: formatMessage(messages.rolesRetail),
        issuer: formatMessage(messages.rolesIssuer),
        tester: formatMessage(messages.rolesTester),
        umbrella: formatMessage(messages.rolesUmbrella),
    };
};

export const DOCUMENTS_TYPE = {
    MARKETPLACE_TC: "termsAndConditionsMarketplace",
    FINEXITY_TC: "termsAndConditionsFinexityPlatform",
    EFFECTA_TC: "termsAndConditionsEffecta",
};

export const issuerCompanyTypes = [
    { value: "BUSINESS", label: "BUSINESS" },
    { value: "ORGANIZATION", label: "ORGANIZATION" },
    { value: "SOLETRADER", label: "SOLETRADER" },
];

export function formatMobileNumber(userPhone) {
    return userPhone?.DialCode && userPhone?.Number ? `+${userPhone.DialCode || ""} ${userPhone.Number || ""}` : "";
}

export const tenantFancyNames = {
    [TENANTS_LIST.FINEXITY]: "FINEXITY",
    [TENANTS_LIST.ASSETTA]: "ASSETTA",
    [TENANTS_LIST.SPARKASSE]: "SPARKASSE",
    [TENANTS_LIST.VOLKSBANK]: "VOLKSBANK",
    [TENANTS_LIST.SACHWERTINVEST]: "SACHWERT INVEST",
    [TENANTS_LIST.WINVESTA]: "WINVESTA",
    [TENANTS_LIST.WATCHVEST]: "WATCHVEST",
    [TENANTS_LIST.TOKEN1]: "TOKEN1",
    [TENANTS_LIST.FAIRZINSUNG]: "FAIRZINSUNG",
    [TENANTS_LIST.GOLDENCIRCLE]: "GOLDEN CIRCLE",
    [TENANTS_LIST.DEMO]: "DEMO",
    [TENANTS_LIST.ANLAGENWERT]: "ANLAGENWERT",
    [TENANTS_LIST.VISION]: "VISION",
    [TENANTS_LIST.REVNEX]: "REVNEX",
    [TENANTS_LIST.IHRE_VB]: "IHRE VB",
};

export const tenantPrimaryColors = {
    [TENANTS_LIST.FINEXITY]: {
        primary: "#2F6D7B",
        primaryDark: "#16b6bf",
        primaryLight: "#a2e2e5",
        secondaryColor: "#006f7d",
        secondaryLight: "#74757d",
    },
    [TENANTS_LIST.ASSETTA]: {
        primary: "#ae907c",
        primaryDark: "#363031",
        primaryLight: "#d3c9c0",
        secondaryColor: "#444340",
        secondaryLight: "#ADA48E",
    },
    [TENANTS_LIST.SPARKASSE]: {
        primary: "#ff0000",
        primaryDark: "#E60000",
        primaryLight: "#FFA4A4",
        secondaryColor: "#800000",
        secondaryLight: "#737373",
    },
    [TENANTS_LIST.VOLKSBANK]: {
        primary: "#ff6600",
        primaryDark: "#E65C00",
        primaryLight: "#FFC09C",
        secondaryColor: "#803300",
        secondaryLight: "#5F6673",
    },
    [TENANTS_LIST.SACHWERTINVEST]: {
        primary: "#5EA628",
        primaryDark: "#508C22",
        primaryLight: "#EEFFE1",
        secondaryColor: "#2E590C",
        secondaryLight: "#8C8C8C",
    },
    [TENANTS_LIST.WINVESTA]: {
        primary: "#859DFF",
        primaryDark: "#212740",
        primaryLight: "#D1DAFF",
        secondaryColor: "#FF824D",
        secondaryLight: "#FFB899",
    },
    [TENANTS_LIST.WATCHVEST]: {
        primary: "#8C7A66",
        primaryDark: "#8C7A66",
        primaryLight: "#FFEFE1",
        secondaryColor: "#8C7A66",
        secondaryLight: "#BDAAA1",
    },
    [TENANTS_LIST.TOKEN1]: {
        primary: "#0F4172",
        primaryDark: "#082440",
        primaryLight: "#9CB9D6",
        secondaryColor: "#88BF51",
        secondaryLight: "#FFB899",
    },
    [TENANTS_LIST.FAIRZINSUNG]: {
        primary: "#8CB63D",
        primaryDark: "#2F3622",
        primaryLight: "#A9C17C",
        secondaryColor: "#689fa9",
        secondaryLight: "#4f4f4e",
    },
    [TENANTS_LIST.GOLDENCIRCLE]: {
        primary: "#af873c",
        primaryDark: "#eb8163",
        primaryLight: "#f0b478",
        secondaryColor: "#1E1E1E",
        secondaryLight: "#8C8C8C",
    },
    [TENANTS_LIST.DEMO]: {
        primary: "#00bfca",
        primaryDark: "#eb8163",
        primaryLight: "#f6d775",
        secondaryColor: "#ee9f8e",
        secondaryLight: "#eb8163",
    },
    [TENANTS_LIST.ANLAGENWERT]: {
        primary: "#00bfca",
        primaryDark: "#eb8163",
        primaryLight: "#f6d775",
        secondaryColor: "#ee9f8e",
        secondaryLight: "#eb8163",
    },
    [TENANTS_LIST.VISION]: {
        primary: "#00bfca",
        primaryDark: "#eb8163",
        primaryLight: "#f6d775",
        secondaryColor: "#ee9f8e",
        secondaryLight: "#eb8163",
    },
    [TENANTS_LIST.REVNEX]: {
        primary: "#00bfca",
        primaryDark: "#eb8163",
        primaryLight: "#f6d775",
        secondaryColor: "#ee9f8e",
        secondaryLight: "#eb8163",
    },
    [TENANTS_LIST.IHRE_VB]: {
        primary: "#00bfca",
        primaryDark: "#eb8163",
        primaryLight: "#f6d775",
        secondaryColor: "#ee9f8e",
        secondaryLight: "#eb8163",
    },
};

export const typeInvestmentProductValues = {
    "500-upto3y-1mto8m-max25k": {
        minAmount: 500,
        maxAmount: 25000,
        dividableBy: null,
    },
    "500-morethan3y-1mto8m-max25k": {
        minAmount: 500,
        maxAmount: 25000,
        dividableBy: null,
    },
    "25000-morethan3y-upto1m-unlimited": {
        minAmount: 25000,
        maxAmount: null,
        dividableBy: null,
    },
    "100000-morethan3y-unlimited-unlimited-100000steps": {
        minAmount: 100000,
        maxAmount: null,
        dividableBy: 100000,
    },
};

export const staticProjectRunningTimeInYears = {
    "ASSETTA-01": 2.5,
    "WIN-01": 1.83,
    "WIN-02": 1.83,
    "WIN-03": 0.75,
};

export const reserveSharesError = {
    INVESTMENT_SUM_STEPPER_RANGE_REQUIREMENT_ERROR: "investment_sum_stepper_range_requirement_error",
    LOWER_THAN_500: "lowerThan500",
    LOWER_THAN_25000: "lowerThan25000",
    MORE_THAN_25000: "moreThan25000",
    INVALID_PRODUCT_STATE: "invalid_product_state",
};

export const clubDealCavalryProjectIDs = ["CD-PP-PE-LB-01", "CD-PP-PE-LB-02"];
export const clubDealCavalryProjectIDsWithNormalPerformance = [
    "CD-PP-PE-SD-02",
    "Offmarket-Private-Equity-01",
    "Offmarket-Private-Equity-02",
];

export const payoutTypes = {
    DIVIDEND_PAYOUT: "dividend_payout",
    EXIT_PAYOUT: "exit_payout",
};

export const payoutStates = {
    PRODUCT_PAYOUT_REQUESTED: "PayoutRequested",
    PRODUCT_PAYOUT_TAX_INFO_RECEIVED: "TaxInfoReceived",
    PRODUCT_PAYOUT_CALCULATED: "PayoutCalculated",
    PRODUCT_PAYOUT_DONE: "PayoutDone",
    PRODUCT_PAYOUT_COMPLETED: "PayoutCompleted",
    PRODUCT_PAYOUT_CANCELLED: "PayoutCancelled",
    PRODUCT_PAYOUT_PENDING: "ProductPayoutPending",
    PRODUCT_PAYOUT_ERROR: "ProductPayoutError",
    PRODUCT_PAYOUT_ISSUER_REQUESTED: "PayoutIssuerRequested",
};

export const completedPayoutStates = [payoutStates.PRODUCT_PAYOUT_CANCELLED, payoutStates.PRODUCT_PAYOUT_COMPLETED];

export const payoutRequestBy = {
    ProductPayoutRequestByIssuer: "PayoutRequestByIssuer",
    ProductPayoutRequestByAdmin: "PayoutRequestByAdmin",
};

export const payoutCancelledBy = {
    ProductPayoutCancelledByIssuer: "PayoutCancelledByIssuer",
    ProductPayoutCancelledByAdmin: "PayoutCancelledByAdmin",
};

export const payoutIssuerAdminPendingStates = [
    payoutStates.PRODUCT_PAYOUT_REQUESTED,
    payoutStates.PRODUCT_PAYOUT_ISSUER_REQUESTED,
];

export const cancelablePayoutStatuses = [
    payoutStates.PRODUCT_PAYOUT_REQUESTED,
    payoutStates.PRODUCT_PAYOUT_TAX_INFO_RECEIVED,
    payoutStates.PRODUCT_PAYOUT_CALCULATED,
    payoutStates.PRODUCT_PAYOUT_ISSUER_REQUESTED,
];

export const DEVICE_OS = {
    WINDOWS: "Windows Phone",
    ANDROID: "Android",
    iOS: "iOS",
    UNKNOWN: "unknown",
};

export const USER_REGISTRATION_ONBOARDING = {
    registrationPendingWelcome: 1,
    registrationPendingDetails: 2,
    registrationPendingConfirmationMobile: 3,
    registrationPendingConfirmationMobileOTP: 3,
    registrationPendingConfirmationMobileSuccess: 3,
    registrationPendingTermsAndConditions: 4,
    registrationPendingRegistrationSuccess: 7,
};

export const PAYMENT_CHANNEL = ["wire transfer", "mangopay"];

export const supportedLanguages = [
    { value: "EN", label: "EN" },
    { value: "DE", label: "DE" },
];

export const deviceTypes = {
    ANDROID: "android",
    IOS: "ios",
};
export const supportedDevice = [
    { value: deviceTypes.ANDROID, label: DEVICE_OS.ANDROID },
    { value: deviceTypes.IOS, label: DEVICE_OS.iOS },
];

//ANCHOR: Investment amount 1 to 8 million
export const investment1to8MLimit = {
    AMOUNT_LIMIT_FRIST: 1000,
    AMOUNT_LIMIT_SECOND: 10000,
    MAXIMUM_AMOUNT_LIMIT: 25000,
};

//ANCHOR: User mail types
export const USER_MAIL_TYPES = {
    CONFIRMATION_MAIL: "confirmationMail",
    PAYMENT_REMINDER: "paymentReminder",
    IDENTIFICATION_HELPER: "identificationHelper",
    BROKER_CONFIRMATION_MAIL: "brokerConfirmationMail",
};

export const subscriptionSupportedTenants = [TENANTS_LIST.FINEXITY, TENANTS_LIST.GOLDENCIRCLE];
export const emailSubscriptionSupportedTenants = [TENANTS_LIST.FINEXITY];

export const TABS = {
    DASHBOARD: "retail",
    MARKETPLACE: "retail",
    CLUBDEAL: "club",
    ADMIN: "admin",
    UMBRELLA: "umbrella",
    TENANT: "tenant",
    TESTING: "testing",
    TESTER: "tester",
    ISSUER: "issuer",
    BROKER: "broker",
    BROKER_ONBOARDING: "brokerOnboarding",
    INBOX: "inbox",
    MANGOPAY: "mangopay",
    CONTACT: "retail",
    MY_SETTINGS: "retail",
};

export const SUBPAGES = {
    PROFILE: "profile",
    INVITE_FRIENDS: "inviteFriends",
    EMAIL_SUBS: "emailSubscriptions",
    TAX: "tax",
    CHANGE_PASS: "change_password",
    LOGOUT: "logout",
    BROKER_DASHBOARD: "brokerdashboard",
    PERSONAL_DATA: "personaldata",
    PROVISION: "provision",
    INFORMATION: "information",
    MARKETING_MATERIALS: "marketingMaterials",
    INVITE_CUSTOMER: "inviteCustomers",
    PROJECTS: "projects",
    USERS: "users",
    INVESTMENTS: "investments",
    TENANT: "tenants",
    RIGHTS: "rights",
    ISSUER: "issuers",
    TESTING: "testing",
    ORDERS: "orders",
    CONTENT: "content",
    PAYMENTS: "payments",
    SECONDARY_MARKET: "secondaryMarket",
    BROKER: "broker",
    PERFORMANCE: "performance",
    BLOCKCHAIN: "blockchain",
    MISCELLANEOUS: "miscellaneous",
    SUBTENANT: "subtenant",
    SETTING: "setting",
    PRODUCT_CATALOGUE: "product-catalog",
    PRODUCT: "product",
    TAB: "tab",
    CONTENT: "content",
    TESTUSERS: "testusers",
    IOS: "ios",
    ADNROID: "adnroid",
    WEB: "web",
    SETTINGS: "settings",
    DOCUMENTS: "documents",
    TESTING_SUITE: "testing-suite",
    NOTIFICATION: "notification",
    PAYOUTS: "payouts",
    OVERVIEW: "overview",
    MANAGING_DIRECTOR: "Managing Director",
    PLATFORM_SETTINGS: "Platform Settings",
    ACCOUNT: "account",
    EWALLET: "E-Wallet",
};

export const TENANT_SETTINGS_RENDER_STEPS = {
    general: 1,
    theme: 2,
    links: 3,
    mail: 4,
    marketplace_tile: 5,
    social: 6,
    updateTC: 7,
};

export const PRODUCT_TYPE_ICON = {
    [PROJECT_TYPE.FINE_WINE]: <FineWine />,
    [PROJECT_TYPE.ART]: <Art />,
    [PROJECT_TYPE.CLASSIC_CARS]: <ClassicCars />,
    [PROJECT_TYPE.DIAMOND]: <Diamond />,
    [PROJECT_TYPE.REAL_ESTATE]: <Estate />,
    [PROJECT_TYPE.WATCHES]: <Watch />,
    [PROJECT_TYPE.PRIVATE_EQUITY]: <PrivateEquity />,
    [PROJECT_TYPE.FERIENIMMOBILIE]: <Estate />,
    [PROJECT_TYPE.SERVICED_APARTMENT]: <Estate />,
    [PROJECT_TYPE.CL_PUBLIC_PLACEMENT]: <Estate />,
    // [PROJECT_TYPE.CL_DIRECT_SELECT]: <Estate />,
    // [PROJECT_TYPE.VACATION_PROPERTY]: <Estate />,
    [PROJECT_TYPE.STRING_INSTRUMENTS]: <Instruments />,
    [PROJECT_TYPE.RENEWABLES]: <Renewable />,
    // [PROJECT_TYPE.SUSTAINABILITY]: <Estate />,
    [PROJECT_TYPE.HOTEL]: <Estate />,
    [PROJECT_TYPE.HEALTHCARE]: <Plus width="17" height="17" />,
    default: <Sync width="17" height="17" />,
};

export const kpiFilterOptions = [
    { label: <IntlFormattedMessage id={messages.allTime} />, value: "all" },
    { label: <IntlFormattedMessage id={messages.today} />, value: "today" },
    { label: <IntlFormattedMessage id={messages["7Days"]} />, value: "7days" },
    { label: <IntlFormattedMessage id={messages["14Days"]} />, value: "14days" },
    { label: <IntlFormattedMessage id={messages["30Days"]} />, value: "30days" },
];

export const SETTINGS_RENDER_STEPS = {
    updateTC: 7,
};

export const tenants_logo = {
    [TENANTS_LIST.ASSETTA]: <Assetta />,
    [TENANTS_LIST.SACHWERTINVEST]: <SachwertInvest />,
    [TENANTS_LIST.GOLDENCIRCLE]: <GoldenCircle />,
    [TENANTS_LIST.DEMO]: <Demo />,
    [TENANTS_LIST.FAIRZINSUNG]: <Fairzinsung />,
    [TENANTS_LIST.FINEXITY_HEALTHCARE]: <FinexityHealthcare />,
    [TENANTS_LIST.REVNEX]: <Revnex />,
    [TENANTS_LIST.WATCHVEST]: <Watchvest />,
    [TENANTS_LIST.WINVESTA]: <Winvesta />,
    [TENANTS_LIST.FINEXITY]: <Finexity />,
    [TENANTS_LIST.TOKEN1]: <Token1 />,
    [TENANTS_LIST.ANLAGENWERT]: <AnlagenwertHamburg />,
};

export const ACCOUNT_SETTINGS_RENDER_STEPS = {
    general: 1,
    publicInfo: 2,
    productPdf: 3,
};

export const PROJECT_STATUS_DROPDOWN_LIST = [
    {
        value: PROJECT_STATUS.COMING_SOON,
        label: PROJECT_STATUS.COMING_SOON,
    },
    {
        value: PROJECT_STATUS.SILENT_SALE,
        label: PROJECT_STATUS.SILENT_SALE,
    },
    {
        value: PROJECT_STATUS.ADVERTISING,
        label: PROJECT_STATUS.ADVERTISING,
    },
    {
        value: PROJECT_STATUS.VIP_FUNDING,
        label: PROJECT_STATUS.VIP_FUNDING,
    },
    {
        value: PROJECT_STATUS.FUNDING_ACTIVE,
        label: PROJECT_STATUS.FUNDING_ACTIVE,
    },
    {
        value: PROJECT_STATUS.FUNDING_CLOSED,
        label: PROJECT_STATUS.FUNDING_CLOSED,
    },
    {
        value: PROJECT_STATUS.FINANCING_COMPLETED,
        label: PROJECT_STATUS.FINANCING_COMPLETED,
    },
    {
        value: PROJECT_STATUS.MARKETPLACE,
        label: PROJECT_STATUS.MARKETPLACE,
    },
    {
        value: PROJECT_STATUS.MARKETPLACE_PAUSED,
        label: PROJECT_STATUS.MARKETPLACE_PAUSED,
    },
    {
        value: PROJECT_STATUS.MARKETPLACE_REVIEW,
        label: PROJECT_STATUS.MARKETPLACE_REVIEW,
    },
    {
        value: PROJECT_STATUS.SUCCESSFULLY_PAID_BACK,
        label: PROJECT_STATUS.SUCCESSFULLY_PAID_BACK,
    },
    {
        value: PROJECT_STATUS.CLUB_DEAL,
        label: PROJECT_STATUS.CLUB_DEAL,
    },
    {
        value: PROJECT_STATUS.CLUB_DEAL_DIRECT_SELECT,
        label: PROJECT_STATUS.CLUB_DEAL_DIRECT_SELECT,
    },
    {
        value: PROJECT_STATUS.HYBRID,
        label: PROJECT_STATUS.HYBRID,
    },
];

export const PROJECT_TYPES_DROPDOWN_LIST = [
    { value: PROJECT_TYPE.FINE_WINE, label: PROJECT_TYPE.FINE_WINE },
    { value: PROJECT_TYPE.ART, label: PROJECT_TYPE.ART },
    { value: PROJECT_TYPE.CLASSIC_CARS, label: PROJECT_TYPE.CLASSIC_CARS },
    { value: PROJECT_TYPE.DIAMOND, label: PROJECT_TYPE.DIAMOND },
    { value: PROJECT_TYPE.REAL_ESTATE, label: PROJECT_TYPE.REAL_ESTATE },
    { value: PROJECT_TYPE.WATCHES, label: PROJECT_TYPE.WATCHES },
    { value: PROJECT_TYPE.PRIVATE_EQUITY, label: PROJECT_TYPE.PRIVATE_EQUITY },
    { value: PROJECT_TYPE.FERIENIMMOBILIE, label: PROJECT_TYPE.FERIENIMMOBILIE },
    { value: PROJECT_TYPE.SERVICED_APARTMENT, label: PROJECT_TYPE.SERVICED_APARTMENT },
    { value: PROJECT_TYPE.COMMERCIAL, label: PROJECT_TYPE.COMMERCIAL },
    { value: PROJECT_TYPE.CL_DIRECT_SELECT, label: PROJECT_TYPE.CL_DIRECT_SELECT },
    { value: PROJECT_TYPE.CL_PRIVATE_PLACEMENT, label: PROJECT_TYPE.CL_PRIVATE_PLACEMENT },
    { value: PROJECT_TYPE.CL_PUBLIC_PLACEMENT, label: PROJECT_TYPE.CL_PUBLIC_PLACEMENT },
    { value: PROJECT_TYPE.CL_PUBLIC_PLACEMENT_25k, label: PROJECT_TYPE.CL_PUBLIC_PLACEMENT_25k },
    { value: PROJECT_TYPE.CL_PUBLIC_PLACEMENT_5k, label: PROJECT_TYPE.CL_PUBLIC_PLACEMENT_5k },
    { value: PROJECT_TYPE.VACATION_PROPERTY, label: PROJECT_TYPE.VACATION_PROPERTY },
    { value: PROJECT_TYPE.STRING_INSTRUMENTS, label: PROJECT_TYPE.STRING_INSTRUMENTS },
    { value: PROJECT_TYPE.RENEWABLES, label: PROJECT_TYPE.RENEWABLES },
    { value: PROJECT_TYPE.SUSTAINABILITY, label: PROJECT_TYPE.SUSTAINABILITY },
    { value: PROJECT_TYPE.HOTEL, label: PROJECT_TYPE.HOTEL },
    { value: PROJECT_TYPE.HEALTHCARE, label: PROJECT_TYPE.HEALTHCARE },
];

// To disable the whole registration flow for a tenant, add tenant in the list
export const TENANTS_WITH_REGISTRATION_DISABLED = [TENANTS_LIST.WATCHVEST, TENANTS_LIST.FAIRZINSUNG];

export const getTranslatedCountriesList = (locale) => {
    const countriesData = countries.getNames(locale || "de", { select: "official" });
    return Object.entries(countriesData)?.map(([key, value]) => {
        return {
            label: value,
            value: key,
        };
    });
};

export function groupedCountries(locale) {
    const countriesList = getTranslatedCountriesList(locale);
    const { formatMessage } = useFormattedMessage();
    return [
        { label: formatMessage(messages.oftenUsed), options: preferredCountries() },
        { label: formatMessage(messages.allCountries), options: countriesList },
    ];
}
