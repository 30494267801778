export const PROJECT_TYPE = `
    type {
        type
    }
`;

export const PROJECT_STATUS = `
    status {
        status
    }
`;

export const PROJECT_HIGHLIGHTS = `
    highlights {
        highlight
    }
`;

export const ASSET = `
    title
    description
    caption
    credit
    path
    sourceUrl
    filename
    mimeType
`;

export const TEASER_IMAGE = `
    teaserImage{
        ${ASSET}
    }
`;

export const ASSET_LIST = `
    list {
        ${ASSET}
    }
`;

export const SLIDER = `
    slider {
        ${ASSET_LIST}
    }
`;

export const PROJECT_IMAGES = `
    images {
        ${TEASER_IMAGE}
        ${SLIDER}
    }
`;

export const PROJECT_ADDRESS = `
    address{
        locationHeader
        city
        country
        street
        zipCode
        latitude
        longitude
    }
`;

export const PROJECT_SECTIONS = `
    sections {
        privateTab
        sectionTitle
        sectionContent
        subSections {
            sectionTitle
            sectionContent
        }
    }
`;

export const DOCUMENTS = `
    documents{
        ${ASSET_LIST}
    }
`;

export const PROJECT_DETAILS = `
    details{
        detailsTitle
        ${PROJECT_SECTIONS}
        ${DOCUMENTS}
    }
`;

export const PROJECT_VALUATION = `
    valuation {
        date
        assetValue
    }
`;

export const PROJECT_COSTS_AND_BENEFITS = `
    costsAndBenefits {
        monetaryBenefitsToBroker
        monetaryBenefitsToInstitute
        oneTimeCostsFi
        oneTimeGrants
        oneTimePlacementFee
        runningCostsFiPa
        runningGrantsPa
        runningPlacementFeePa
        runningTimeInYears
    }
`;

export const PROJECT_BANK_ACCOUNT = `
    bankAccount{
        accountOwner
        IBAN
        BIC
        creditInstitution
    }
`;

export const PROJECT_FINANCIALS = `
    financials {
        numberOfShares
        totalReturnEstimate
        fiveYearsReturnEstimate
        endOfFunding
        ${PROJECT_VALUATION}
        finalizationDate
        objectType
        fundingNews
        issuerName
        issuerWebsite
        ${PROJECT_COSTS_AND_BENEFITS}
        ${PROJECT_BANK_ACCOUNT}
        perAnnoReturnEstimate
        perAnnoMortgageRepaymentEstimate
        perAnnoPropertyValueGrowthEstimate
        minimumFundingPercentage
        issuerStreet
        issuerCity
        issuerZipcode
        issuerEmail
    }
`;

export const PROJECT_NEWS = `
    news {
        newsTitle
        newsDate
        newsContentHtml
    }
`;

export const LINE = `
    line {
        left
        right
        explanation
    }
`;

export const PROJECT_ACTION_BOX = `
    actionBox {
        ${LINE}
        legalDisclaimer
    }
`;

export const PROJECT_INFO_BOX = `
    infoBox {
        infoBoxTitleHtml
        infoBoxContentHtml
        infoBoxUrlHtml
    }
`;
export const PROJECT_DATA = `
    live
    title
    projectID
    sorting
    ${PROJECT_TYPE}
    ${PROJECT_STATUS}
    ${PROJECT_HIGHLIGHTS}
    ${PROJECT_IMAGES}
    ${PROJECT_ADDRESS}
    ${PROJECT_DETAILS}
    ${PROJECT_FINANCIALS}
    xid
    fundingPercentage
    ${PROJECT_NEWS}
    ${PROJECT_ACTION_BOX}
    disclaimerHtml
    ${PROJECT_INFO_BOX}
    isSubscribed
`;

export const PHONE = `
    uid
    TypePhone
    CountryCode
    DialCode
    Number
    StatusConfirmed
    IsPreferredPrimaryContact
`;

export const SINGLE_INVESTMENT_DATA = `
    uid
    Tenant
    StatusInvestment
    CreatedDate
    ExpirationDate
    ProjectID
    LinkUser
    LinkProduct {
        ProductID
    }
    User {
        uid
        CreatedDate
        TypePerson
        FirstName
        LastName
        TypeGender
        Email
        BankAccount {
            Owner
            IBAN
            BIC
        }
        Phone {
            ${PHONE}
        }
        Broker {
            BrokerAccountTipsterOwnID
            StatusBrokerAccount
        }
        Tipster {
            WasReferredByTheTipster
            UserTipsterOwnID
        }
    }
    Amount
    Shares
    SharePrice
    IsXPConfirmed
    IsMissingXPConfirmed
    CouponCode
    LinkTipster
    IsRisksConfirmed
    IsBondTermsConfirmed
    IsPersonalInformationConfirmed
    IsBIBConfirmed
    IsBrokerageTermsConfirmed
    IsConsumerInformationConfirmed
    IsCostConfirmed
    Document {
        uid
        Path
        Title
        Filename
        CreatedDate
        TypeDocument
    }
    InvestorIP
    ReferenceNumber
    Tx
    Token
    IsIssuerAcceptedDate
    IsPaymentReceivedDate
    FirstTimeInvestment
    StatusInvestmentLastModifiedOn
    StatusProvisioning
    ProvisioningAmount
    LinkTransaction
    ProvisionPercentage
    IsCustodyDataForwardingConfirmed
    CopperOpportunityId
    IsProvisioningPendingDate
    IsProvisioningClearedDate
    IsProvisioningPayedDate
    UserEmail
    Payments
    CapitalGain
    TokenRemaining
    SalesRepresentative{
        uid
        FirstName
        LastName
        Email
    }
    TipsterFullName
`;

export const INVESTMENT = `
    investment {
        ${SINGLE_INVESTMENT_DATA}
    }
`;

export const INVESTMENTS = `
    investments {
        ${SINGLE_INVESTMENT_DATA}
    }
`;

export const DOCUMENT = `
    Author
    Caption
    CreatedDate
    DataType
    Description
    ExpiryDate
    Filename
    Filesize
    Language
    Path
    ResourceID
    Tenant
    Title
    TypeDocument
    EffectiveDate
    StandDate
    uid
`;

export const TENANT_DATA = `
    uid
    URLImprint
    URLTransparency
    LegalName
    SMTPUser
    SMTPPort
    Name
    SMTPPassword
    SMTPServer
    Domain
    TechnicalName
    URLDataPrivacy
    URLSCCGDPR_EN
    URLSCCGDPR_DE
    MarketplaceTermsAndConditions {
        ${DOCUMENT}
    }
    ConsentManagerHead
    ConsentManagerBody
    HowItWorkTileContent{
        DescriptionDE
        DescriptionEN
        ReadMoreEnable
        ReadMoreURL
        TitleDE
        TitleEN
        uid
    }
    RootDomainURLEnabled
    MailTemplate{
        ${DOCUMENT}
    }
    LinkProductCatalog {
        uid
        ProductCatalogName
        StatusProductCatalog
    }
    SenderEmail
    BCCs
    XpectoAffiliate
    OGTitle_EN
    OGDescription_EN
    OGTitle_DE
    OGDescription_DE
    OGImage {
        ${DOCUMENT}
    }
    FCMAuthKey
    AppConfig {
        AndroidVersion
        ForceUpdate
        IOSVersion
        MaintenanceEnabled
        Maintenances {
            Description
            Title
            TypeLanguage
            uid
        }
        NotificationInterval
        uid
        VersionAlerts {
            Deutsch
            DeviceType
            English
            uid
        }
    }
    WPALink
    CodeOfConduct_DE
    CodeOfConduct_EN
    CodeOfConductUrl
`;

export const ORDER = `
    uid
    TypeOrder
    StatusOrder
    CreatedDate
    ExpirationDate
    IsSplitAllowed
    ProjectID
    LinkSeller
    LinkBuyer
    Amount
    Shares
    SharePrice
    LinkProduct {
        ProductID
    }
    UserEmail
    Document {
        uid
        Path
        Title
        Filename
        CreatedDate
        TypeDocument
    }
    Payments
    OrderTimeout
    IsPurchaseDate
    AvailableShares
    SharesInProgress
    SoldShares
    UserCountry
    LinkTransaction
    IsTimeoutExtended
`;

export const ORDERS = `
    orders {
        ${ORDER}
    }
`;

export const ORDERS_TABLE_FIELDS = `
    orders {
        uid
        ProjectID
        TypeOrder
        LinkBuyer
        LinkSeller
        Amount
        Shares
        SharePrice
        StatusOrder
        CreatedDate
        ExpirationDate
    }
`;

export const PAYMENT_DATA = `
    uid
    ResourceID
    TypePayment
    StatusPayment
    CreatedDate
    UserIDPayment
    LinkBankAccount {
        uid
        TypeBankAccount
        StatusBankAccount
        Owner
        IBAN
        BIC
    }
    Fee
    Creditor
    Beneficiary
    Channel
    Amount
    ReferenceNumber
    ReferenceProjectID
    ReferenceInvestmentID
`;

export const PAYMENT = `
    payment {
        ${PAYMENT_DATA}
    }
`;

export const PAYMENTS = `
    payments {
        ${PAYMENT_DATA}
    }
`;

export const LINK_TOKEN = `
    ContractAddress
    ContractDeployedDate
    CreatedDate
    Decimals
    InitialSupply
    LinkIssuer
    LinkOwner
    LinkProject
    Name
    Symbol
    uid
`;

export const PRODUCT_ADDRESS = `
    uid
    City
    Country
    Street
    StreetAddition
    Streetnumber
    TypeAddress
    Zipcode
`;

export const ADDRESS = `
    uid
    TypeAddress
    Street
    Streetnumber
    StreetAddition
    City
    Country
    Zipcode
`;

export const BANK_ACCOUNT = `
    uid
    TypeBankAccount
    StatusBankAccount
    IBAN
    BIC
    Owner
    BankAccountIDMangopay
    BankAccountStatusMangopay
`;

export const COMPANY = `
    uid
    LinkAddress {
        ${ADDRESS}
    }
    LinkPhone {
        ${PHONE}
    }
    Website
    LinkBankAccount {
        ${BANK_ACCOUNT}
    }
    TaxID
    CompanyName
    CompanyEmail
    CompanyNumber
    LegalForm
`;

export const ISSUER = `
    uid
    LegalName
    MainTenant
    IssuerEmail
    CreatedDate
    StatusIssuerAccount
    IssuerConfirmationDate
    ApprovalNumberBZST
    ApprovalIdentifierFSAK
    IssuerKey
    IssuerSlug
    CompanyGoal
    Description
    FooterNote
    IssuerLogo {${DOCUMENT}}
    Background {${DOCUMENT}}
    Document {${DOCUMENT}}
    LinkCompany {${COMPANY}}
    MetaDescription
    MetaTitle
    Managers {
        uid
        FirstName
        LastName
        Email
        Roles
    }
    LinkLegalRepresentatives {
        LegalRepresentativeAddress {
            ${ADDRESS}
        }
        LegalRepresentativeBirthday
        LegalRepresentativeEmail
        LegalRepresentativeCountryOfResidence
        LegalRepresentativeFirstName
        LegalRepresentativeLastName
        LegalRepresentativeNationality
        TypeLegalPerson
        uid
    }
    VirtualIssuer
`;
export const PRODUCT_WITHOUT_CONTENT = `
    uid
    Title
    ProductID
    CreatedDate
    CategoryProduct
    FinancingAmount
    IsLive
    Issuer {${ISSUER}}
    Document {${DOCUMENT}}
    LinkToken {${LINK_TOKEN}}
    LinkAddress {${PRODUCT_ADDRESS}}
    ProductContent { DisclaimerHtml Highlights { highlight } TeaserImage {title description caption credit path sourceUrl filename mimeType xid} Slider {list {title description caption credit path sourceUrl filename mimeType xid}} ActionBox {line {left right explanation} legalDisclaimer} InfoBox {infoBoxContentHtml infoBoxTitleHtml infoBoxUrlHtml} Details { detailsTitle sections { privateTab sectionTitle sectionContent subSections { sectionTitle sectionContent}} documents {list {title description caption credit path sourceUrl filename mimeType xid}} contractDocument {title description caption credit path sourceUrl filename mimeType xid}}}
    MinimumFundingPercentage
    MonetaryBenefitsToBroker
    MonetaryBenefitsToInstitute
    NumberOfShares
    OGDescription
    OGImagePath
    OGTitle
    OGURL
    OneTimeCostsFi
    OneTimeGrants
    OneTimePlacementFee
    ProductKey
    RunningCostsFiPa
    RunningGrantsPa
    RunningPlacementFeePa
    RunningTimeInYears
    Slug
    Sorting
    StatusProduct
    StatusTestInvestmentCompliance
    TenantIDs
    TestInvestmentSentDate
    TitleProductTile
    TotalReturnEstimated
    TypeProduct
    Marketplaces
    FundingPercentage
`;

export const ISSUER_MANAGER_PRODUCT_DATA = `
    uid
    Title
    ProductID
    CreatedDate
    CategoryProduct
    Issuer {${ISSUER}}
    NumberOfShares
    TitleProductTile
    TypeProduct
    Marketplaces
    FundingPercentage
    FinancingAmount
`;

export const ISSUER_WITHOUT_PRODUCT_CONTENT = `
    LinkProducts {${PRODUCT_WITHOUT_CONTENT}}
    uid
    Name
    IssuerEmail
    CreatedDate
    StatusIssuerAccount
    IssuerConfirmationDate
    ApprovalNumberBZST
    ApprovalIdentifierFSAK
    IssuerKey
    IssuerSlug
    CompanyGoal
    Description
    FooterNote
    MetaTitle
    MetaDescription
`;

export const DOCUMENT_DATA = `
    uid
    Path
    Title
    Filename
    CreatedDate
    TypeDocument
`;

export const IDENTIFICATION = `
    Identification {
        uid
        Birthplace
        BirthCountry
        Birthname
        Nationality
        MaritalStatus
    }
`;

export const KYC_POSTIDENT = `
    Postident {
        uid
        ID
        Email
        Lastname
        Firstname
        StreetAndNo
        Zipcode
        City
        IDDocument
        IDNumber
        DateOfBirth
        PlaceOfBirth
        Nationality
        IssuingAuthority
        IssuingDate
        ProcessingTimestamp
    }
`;

export const USER_KYC = `
    KYC {
        uid
        IDnowSnapshot
        ${KYC_POSTIDENT}
        ID
        Gender
        Title
        Firstname
        Lastname
        Street
        StreetNumber
        Zipcode
        City
        Country
        BirthDay
        BirthPlace
        BirthName
        Nationality
        IDDocumentType
        IDDocumentNumber
        IdentificationTime
        IDDocumentIssuingAuthority
        IDDocumentIssuingDate
        IDDocumentExpirationDate
        Transactionnumber
        IdentificationSummaryPDFPath
        IdentificationSummaryPDFFilename
        StatusKYC
        StatusReKYC
        IDnowRedirectURL
        XpectoCustomerDataSent
        XpectoCustomerCleared
        IDnowID
        PostidentID
        IssuingParty
        IssuingCountry
        IDnowTransactionnumber
    }
`;

export const ADEQUACY = `
    Adequacy {
        uid
        IsNotWantedConfirmed
        IsNotWantedConfirmedDate
        IsInvestFundsXPConfirmed
        IsInvestFundsXPConfirmedDate
        IsBondsXPConfirmed
        IsBondsXPConfirmedDate
        IsSharesXPConfirmed
        IsSharesXPConfirmedDate
        IsProfitPartXPConfirmed
        IsProfitPartXPConfirmedDate
        IsSubordLoanXpConfirmed
        IsSubordLoanXpConfirmedDate
        BondsXPIndependent
        BondsXPConsulted
        BondsXPAssisted
        BondsTransactions
        BondsAmount
        TypeJob
        TypeEducation
        StatusAdequacy
        DateTime
    }
`;

export const CAMPAIGN = `
    Campaign {
        Source
        Name
    }
`;

export const TAG_VALUE = `
    uid
    Tag
    StatusTag
`;

export const SUBSCRIPTION = `
    Subscription {
        uid
        LinksFinexity {
            ${TAG_VALUE}
        }
        LinksProjects {
            ${TAG_VALUE}
        }
    }
`;

export const PROVISION = `
    TypeProvisioning
    InitialInvestmentPercentage
    FollowupPercentage
    ProtectionPeriodInYears
`;

export const PROVISIONS = `
    LinkProvision {
        ${PROVISION}
    }
`;

export const LEGAL_USER = `
    uid
    LegalRepresentativeFirstName
    LegalRepresentativeLastName
    LegalRepresentativeEmail
    TypeLegalPerson
    LegalRepresentativeBirthday
    LegalRepresentativeCountryOfResidence
    LegalRepresentativeNationality
`;

export const BROKER = `
    Broker {
        uid
        StatusBrokerAccount
        LinkCompany {
            ${COMPANY}
        }
        LinkAddress {
            ${ADDRESS}
        }
        Website
        LinkBankAccount {
            ${BANK_ACCOUNT}
        }
        LinkPhone {
            ${PHONE}
        }
        StatusBrokerOnboarding
        IsTipsterConfirmed
        IsTipsterConfirmedDate
        BrokerAccountTipsterOwnID
        LinkBrokerDocumentIdentification {
            ${DOCUMENT_DATA}
        }
        LinkBrokerDocumentCooperationAgreement {
            ${DOCUMENT_DATA}
        }
        LinkBrokerDocumentBusinessLicense {
            ${DOCUMENT_DATA}
        }
        LinkBrokerDocumentCommercialRegisterExtract {
            ${DOCUMENT_DATA}
        }
        TaxID
        TypeLicense
        ${PROVISIONS}
        BrokerConfirmationDate
        IsLegalRepresentative
        LegalUser {
            ${LEGAL_USER}
        }
    }
`;

export const BLOCKCHAIN_DATA = `
    uid
    WalletID
`;

export const BLOCKCHAIN = `
    Blockchain {
        ${BLOCKCHAIN_DATA}
    }
`;

export const COOPER = `
    Copper {
        uid
        CopperLeadID
        CopperPersonID
    }
`;

export const TIPSTER = `
    Tipster {
        uid
        StatusTipster
        WasReferredByTheTipster
        WasReferredByTheTipsterDate
        UserTipsterOwnID
        LinkProvision {
            uid
            TypeProvisioning
            InitialInvestmentPercentage
            FollowupPercentage
            ProtectionPeriodInYears
        }
    }
`;

export const USER_SOCIAL = `
    Social {
        uid
        TypeSocial
        Url
    }
`;

export const MANGO_PAY = `
    Mangopay {
        uid
        UserIDMangopay
        RegistrationDateMangopay
        ProofOfIdentity
        ProofOfAddress
        PersonType
        KYCLevel
        KYCDocumentSentDate
        KYCDocumentID
        KYCDocumentFile
        EWallets {
            uid
            PayInWalletID
            PayOutWalletID
            BlockedWalletID
            EscrowWalletID
        }
        BankAccountIDMangopay
        BankAccountStatusMangopay
        LinkUserSelectedBankAccountPayout
        IsMangopayTermsConditionsConfirmed
        IsMangopayTermsConditionsConfirmedDate
        IsBlockedInFlowsMangopay
        IsBlockedOutFlowsMangopay
        BankingAlias {
            ${BANK_ACCOUNT}
        }
        StatusMangopay
    }
`;

export const USER_CLUB = `
    Club {
        uid
        TypeJob
        TypeJobOther
        SourceOfWealth
        SourceOfWealthOther
        ScopeOfPortfolio
        ScopeOfCash
        ShareOfAlternativeInvestments
        FutureAmountOfAlternativeInvestments
        InterestInAlternativeInvestments
        InterestInAlternativeInvestmentsOther
        ClubDealConfirmationDate
    }
`;

export const PORTFOLIO_DATA = `
    uid
    LinkToken
    LinkProject
    AvailableAmount
    BlockedAmount
    Investments
    Orders
`;

export const USER_DATA = `
    uid
    Tenant
    Email
    CreatedDate
    UpdatedDate
    LeadSource
    TypeGender
    TypeTitle
    TypePerson
    FirstName
    LastName
    Phone {${PHONE}}
    Birthday
    Address {${ADDRESS}}
    CountryCode
    ${BROKER}
    BankAccount {${BANK_ACCOUNT}}
    ${IDENTIFICATION}
    ${USER_KYC}
    ${CAMPAIGN}
    IsEffectaCustomerInformationConfirmed
    IsEffectaCustomerInformationConfirmedDate
    IsFinexityTermsConditionsConfirmed
    IsFinexityTermsConditionsConfirmedDate
    IsUserCallRecordingsConfirmed
    IsUserCallRecordingsConfirmedDate
    ${ADEQUACY}
    ${COOPER}
    Auth0UUID
    ${BLOCKCHAIN}
    ${TIPSTER}
    Investments
    Orders
    Roles
    Transactions
    ${SUBSCRIPTION}
    StatusUserAccount
    IsRegistered
    PreferredLanguage
    StatusKYC
    StatusAdequacy
    StatusTipster
    StatusMobilePhone
    StatusTwoFactor
    StatusMangopay
    StatusSecondaryMarket
    StatusVIP
    StatusEmail
    StatusReferral
    Website
    IsUserAlreadyInvested
    TaxID
    IsUSTaxation
    IsPEP
    ${USER_SOCIAL}
    IsTwoFactorExpired
    IsTwoFactorExpiredDate
    StatusClubDeal
    StatusBroker
    LastChangeUnixTime
    ${MANGO_PAY}
    Company {${COMPANY}}
    ${USER_CLUB}
    Payments
    IsEmailConfirmationDate
    IsTestTemplate
    IsReRegister
    UserRegistrationOnboardingStatus
    UserStateChangeDate
    StatusIssuer
    TypeProgramApplication
    IsUserSecondaryMarketPassDataConfirmed
    IsUserSecondaryMarketPassDataConfirmedDate
    Portfolio {${PORTFOLIO_DATA}}
    FederalState
    Religion
    StatusTax
    Documents {${DOCUMENT}}
    Issuer {${ISSUER_WITHOUT_PRODUCT_CONTENT}}
    DateOfFirstInvestment
    LinkParentUserEmail
`;

export const TENANT_USER_DATA = `
    uid
    Email
    ${IDENTIFICATION}
    Birthday
    PreferredLanguage
    BankAccount {${BANK_ACCOUNT}}
    Address {${ADDRESS}}
    FirstName
    LastName
    TypeGender
    TypePerson
    CreatedDate
    Phone {${PHONE}}
    TypeTitle
    StatusKYC
    StatusUserAccount
    UserRegistrationOnboardingStatus
    Roles
    StatusClubDeal
    ${ADEQUACY}
    ${USER_KYC}
    ${TIPSTER}
    Broker {
        uid
        StatusBrokerAccount
        StatusBrokerOnboarding
    }
`;

export const USER = `
    user {${USER_DATA}}
`;

export const ADMIN_SECONDARY_MARKET_USER_DATA = `
    uid
    Email
    StatusMangopay
    StatusSecondaryMarket
    KYC {
        XpectoCustomerCleared
    }
    Mangopay {
        KYCLevel
        EWallets {
            uid
            PayInWalletID
            PayOutWalletID
            BlockedWalletID
            EscrowWalletID
        }
        UserIDMangopay
        KYCDocumentID
        KYCDocumentFile
    }
`;

export const NOTIFICATIONS = `
    notifications {
        uid
        TypeStatus
        Title
        ScheduledDate
        SendDate
    }
`;

export const adminInvestmentUserList = `
    users {
        uid
        Email
        FirstName
        LastName
    }
`;

export const userBankList = `
    users {
        uid
        Email
        FirstName
        LastName
        BankAccount {${BANK_ACCOUNT}}
    }
`;

export const userList = `
    uid 
    Email
    FirstName
    LastName
`;

export const userListWithRoles = `
    uid 
    Email
    FirstName
    LastName
    Roles
`;

export const productTileData = `
    projectID
    title
    address {
        city
        country
    }
    status {
        status
    }
    financials {
        numberOfShares
    }
    images {
        teaserImage{
            path
        }
    }
    type {
        type
    }
`;

export const productData = `
    title
    projectID
    ${PROJECT_STATUS}
    ${PROJECT_FINANCIALS}
    ${PROJECT_IMAGES}
    ${PROJECT_ADDRESS}
    ${PROJECT_TYPE}
    fundingPercentage
`;

export const userEmails = `
    users {
        uid
        Email
    }
`;

export const investmentWithEmailProductId = `
    investments {
        UserEmail
        ProjectID
    }
`;

export const userInvestment = `
    uid    
    Shares
    SharePrice
    Amount
    CreatedDate
    StatusInvestment
    LinkProduct{
        ProductID
    }
`;
export const secondaryMarketOrder = `
    orders{
        uid
        TypeOrder
        ExpirationDate
        Amount
        Shares
        SharePrice
        LinkProduct {
            ProductID
        }
        UserEmail
    }
`;

export const tenantList = `
    uid
    Name
    TechnicalName
`;

export const productList = `
    uid
    Title
    ProductID
`;

export const productDataForIssuerInvestments = `
    uid
    Title
    ProductID
    TypeProduct
    LinkAddress {${PRODUCT_ADDRESS}}
    ProductContent {
        TeaserImage {
            path
        }
    }
`;

export const userTileData = `
    users {
        uid
        StatusUserAccount
        UserRegistrationOnboardingStatus
        UserStateChangeDate
        FirstName
        LastName
        Email
        TypePerson
        StatusUmbrellaClearance
        Phone{
            uid
            TypePhone
            CountryCode
            DialCode
            Number
            StatusConfirmed
            IsPreferredPrimaryContact
        }
    }
`;

export const investmentList = `
    uid	
    Amount
    Shares
    StatusInvestment
    StatusInvestmentLastModifiedOn
    LinkProduct {
        ProductID
    }
    UserEmail
    User{
        FirstName
        LastName
    }
    CreatedDate
    Tenant
`;

export const projectList = `
    projectID
    title
`;

export const documentList = `
    Filename
    Path
    Tenant
    Title
    uid
`;

export const userTileDataList = `
    uid
    Address {${ADDRESS}}
    StatusUserAccount
    UserRegistrationOnboardingStatus
    Roles
    StatusClubDeal
    FirstName
    LastName
    Email
    TypePerson
    StatusUmbrellaClearance
    UserStateChangeDate
    Phone {${PHONE}}
`;
